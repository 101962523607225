import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./DeviceLinkMainPage.css";
import { useHistory, useRouteMatch } from "react-router-dom";
import { Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import {
  Device,
  executeDeviceAction,
  refreshDeviceLinkDevices,
} from "../app/DeviceSlice";
import { DoorCard } from "../components/DoorCard";
import { BlindsCard } from "../components/BlindsCard";
import { refreshWebsocketToken } from "../app/AccountSlice";
import { LightCard } from "../components/LightCard";
import AlertDialog from "../components/AlertDialog";
import { getErrorMessage } from "../app/DefaultErrorHandler";
import { LoginApiErrorId, OtherApiErrorId, isApiError } from "../api/ApiError";
import { ActionResult } from "../api/DeviceApi";
import { DeviceLink } from "../api/DeviceLinkApi";

export const DeviceLinkMainPage = () => {
  const navigate = useHistory();
  const match = useRouteMatch();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const deviceList = useAppSelector((state) => state.device.devices);

  const blindsList: Device[] = [];
  const doorsList: Device[] = [];
  const lightsList: Device[] = [];

  console.log("Device list is:", deviceList);
  for (const device of Object.values(deviceList)) {
    switch (device.devicetype.toLowerCase()) {
      case "home_door":
        doorsList.push(device);
        break;
      case "light":
        lightsList.push(device);
        break;
      default:
        blindsList.push(device);
        break;
    }
  }

  const [deviceLinkToken, setDeviceLinkToken] = useState<string | null>(null);
  const [isDialogVisible, setIsDialogVisible] = useState<boolean>(false);
  const [dialogTitle, setDialogTitle] = useState<string>("");
  const [dialogContent, setDialogContent] = useState<React.ReactNode>(null);

  const deviceLink: DeviceLink | undefined = useAppSelector(
    (state) => state.device.deviceLinks[deviceLinkToken ?? ""],
  );

  const handleErrorDialogDismiss = () => {
    setIsDialogVisible(false);
  };

  const showError = (error: string) => {
    setDialogTitle(t("title_error"));
    setDialogContent(<p>{error}</p>);
    setIsDialogVisible(true);
  };

  useEffect(() => {
    let foundToken: string | null = null;
    if (navigate.location.state) {
      const navigationState = navigate.location.state as {
        token?: string;
      };

      foundToken = navigationState.token ?? null;
    }
    if (navigate.location.search) {
      const queryString = new URLSearchParams(navigate.location.search);
      foundToken = queryString.get("token") ?? null;
    }

    setDeviceLinkToken(foundToken);
  }, []);

  useEffect(() => {
    if (deviceLinkToken === null) {
      return;
    }

    dispatch(refreshDeviceLinkDevices({ deviceLinkToken }))
      .unwrap()
      .then(() => dispatch(refreshWebsocketToken({ deviceLinkToken })).unwrap())
      .catch((err) => {
        console.error(err);
        if (isApiError(err)) {
          if (
            err.error === LoginApiErrorId.API_TOKEN_INVALID ||
            err.error === LoginApiErrorId.API_TOKEN_MISSING
          ) {
            showError(t("deviceLink_notRecognized"));
            return;
          }
        }

        showError(getErrorMessage(err));
      });
  }, [deviceLinkToken]);

  const handleQuickActionResults = (
    deviceId: string,
    results: ActionResult[],
  ) => {
    if (!results.some((result) => result.device_ids.includes(deviceId))) {
      return;
    }

    const result = results.find((result) =>
      result.device_ids.includes(deviceId),
    )!;

    if (result.status.toLowerCase() === "error") {
      showError(getErrorMessage(result));
    } else {
      console.log("Action succeeded:", result);
    }
  };

  const handleQuickActionError = (error: unknown) => {
    console.error(error);
    if (isApiError(error)) {
      if (error.error === OtherApiErrorId.INSUFFICIENT_PERMISSION) {
        showError(t("deviceLink_expired"));
        return;
      }
    }

    showError(getErrorMessage(error));
  };

  return (
    <div className="App">
      <div className="App-body">
        <AlertDialog
          open={isDialogVisible}
          title={dialogTitle}
          content={dialogContent}
          onDismiss={handleErrorDialogDismiss}
        />
        <div className="device-link-info">
          <span>
            {deviceLink?.multiUse
              ? t("option_doorLink_multiAccess")
              : t("option_doorLink_singleAccess")}
          </span>
          <div>
            <span>{t("label_linkBecomesValidAt")}</span>
            <span>{new Date(deviceLink?.startsAt).toLocaleString()}</span>
          </div>
          <div>
            <span>{t("label_linkBecomesInvalidAt")}</span>
            <span>{new Date(deviceLink?.expiresAt).toLocaleString()}</span>
          </div>
        </div>
        <div className="device-link-device-list-container">
          {blindsList.length > 0 ? (
            <>
              <div className="device-list-header">
                <Typography variant="h5" color="primary">
                  {t("tab_windowBlinds").toLocaleUpperCase()}
                </Typography>
              </div>
              <ul className="device">
                {blindsList.map((item) => (
                  <BlindsCard
                    className="device-card"
                    key={item.id}
                    device={item}
                    onCardClicked={(_event) => {
                      navigate.push(
                        {
                          pathname: `${match.url}/device`,
                          search: `token=${deviceLinkToken}&deviceId=${item.id}`,
                        },
                        {
                          id: item.id,
                          deviceLinkToken,
                        },
                      );
                    }}
                    onOpenClicked={(_event) => {
                      dispatch(
                        executeDeviceAction({
                          deviceIds: [item.id],
                          action: {
                            command: "move",
                            position: 0.0,
                            inclination: 0.0,
                          },
                          deviceLinkToken: deviceLinkToken ?? undefined,
                        }),
                      )
                        .unwrap()
                        .then((results) =>
                          handleQuickActionResults(item.id, results),
                        )
                        .catch((err) => handleQuickActionError(err));
                    }}
                    onCloseClicked={(_event) => {
                      dispatch(
                        executeDeviceAction({
                          deviceIds: [item.id],
                          action: {
                            command: "move",
                            position: 1.0,
                            inclination: 1.0,
                          },
                          deviceLinkToken: deviceLinkToken ?? undefined,
                        }),
                      )
                        .unwrap()
                        .then((results) =>
                          handleQuickActionResults(item.id, results),
                        )
                        .catch((err) => handleQuickActionError(err));
                    }}
                    onChangeRequested={(_event, value) => {
                      const sliderValue = Array.isArray(value)
                        ? value[0]
                        : value;

                      let newPosition: number | undefined = undefined;
                      let newInclination: number | undefined = undefined;
                      if (item.devicetype === "pergola") {
                        newInclination = sliderValue;
                      } else {
                        newPosition = sliderValue;
                      }

                      dispatch(
                        executeDeviceAction({
                          deviceIds: [item.id],
                          action: {
                            command: "move",
                            position: newPosition,
                            inclination: newInclination,
                          },
                          deviceLinkToken: deviceLinkToken ?? undefined,
                        }),
                      )
                        .unwrap()
                        .then((results) =>
                          handleQuickActionResults(item.id, results),
                        )
                        .catch((err) => handleQuickActionError(err));
                    }}
                  />
                ))}
              </ul>
            </>
          ) : null}

          {doorsList.length > 0 ? (
            <>
              <div className="device-list-header">
                <Typography variant="h5" color="primary">
                  {t("tab_doors").toLocaleUpperCase()}
                </Typography>
              </div>
              <ul className="device">
                {doorsList.map((item) => (
                  <DoorCard
                    key={item.id}
                    device={item}
                    className="device-card"
                    onCardClicked={(_event) => {
                      navigate.push(
                        {
                          pathname: `${match.url}/device`,
                          search: `token=${deviceLinkToken}&deviceId=${item.id}`,
                        },
                        {
                          id: item.id,
                          deviceLinkToken,
                        },
                      );
                    }}
                    onMotorLockClicked={(_event) => {
                      dispatch(
                        executeDeviceAction({
                          deviceIds: [item.id],
                          action: { command: "unlock", lockType: "motor" },
                          deviceLinkToken: deviceLinkToken ?? undefined,
                        }),
                      )
                        .unwrap()
                        .then((results) =>
                          handleQuickActionResults(item.id, results),
                        )
                        .catch((err) => handleQuickActionError(err));
                    }}
                    onMagnetLockClicked={(_event) => {
                      dispatch(
                        executeDeviceAction({
                          deviceIds: [item.id],
                          action: { command: "unlock", lockType: "magnet" },
                          deviceLinkToken: deviceLinkToken ?? undefined,
                        }),
                      )
                        .unwrap()
                        .then((results) =>
                          handleQuickActionResults(item.id, results),
                        )
                        .catch((err) => handleQuickActionError(err));
                    }}
                    onMagicFoilClicked={(_event) => {
                      dispatch(
                        executeDeviceAction({
                          deviceIds: [item.id],
                          action: {
                            command: "magic_foil_apply",
                            opacity: item.magicFoilOpacity ? 0 : 1,
                          },
                          deviceLinkToken: deviceLinkToken ?? undefined,
                        }),
                      )
                        .unwrap()
                        .then((results) =>
                          handleQuickActionResults(item.id, results),
                        )
                        .catch((err) => handleQuickActionError(err));
                    }}
                    onDoorLightClicked={(_event) => {
                      dispatch(
                        executeDeviceAction({
                          deviceIds: [item.id],
                          action: {
                            command: "light_apply",
                            lightType: "door",
                            brightness: item.doorLightBrightness ? 0 : 1,
                          },
                          deviceLinkToken: deviceLinkToken ?? undefined,
                        }),
                      )
                        .unwrap()
                        .then((results) =>
                          handleQuickActionResults(item.id, results),
                        )
                        .catch((err) => handleQuickActionError(err));
                    }}
                    onHandleLightClicked={(_event) => {
                      dispatch(
                        executeDeviceAction({
                          deviceIds: [item.id],
                          action: {
                            command: "light_apply",
                            lightType: "handle",
                            brightness: item.handleLightBrightness ? 0 : 1,
                          },
                          deviceLinkToken: deviceLinkToken ?? undefined,
                        }),
                      )
                        .unwrap()
                        .then((results) =>
                          handleQuickActionResults(item.id, results),
                        )
                        .catch((err) => handleQuickActionError(err));
                    }}
                  />
                ))}
              </ul>
            </>
          ) : null}

          {lightsList.length > 0 ? (
            <>
              <div className="device-list-header">
                <Typography variant="h5" color="primary">
                  {t("tab_lights").toLocaleUpperCase()}
                </Typography>
              </div>
              <ul className="device">
                <>
                  {
                    // Done like this because with using <></> we get complaints of using duplicate keys, despite that not being the case
                    lightsList.flatMap((item) => [
                      item?.deviceLinkSpecifier?.light1 && (
                        <LightCard
                          device={item}
                          className="device-card"
                          key={`${item.id}-light1`}
                          name={item.settings?.light1Name}
                          brightness={item.light1Brightness!}
                          onCardClicked={(_event) => {
                            navigate.push(
                              {
                                pathname: `${match.url}/device`,
                                search: `token=${deviceLinkToken}&deviceId=${item.id}&deviceSpecifier=light_1`,
                              },
                              {
                                id: item.id,
                                deviceLinkToken,
                                lightType: "light_1",
                              },
                            );
                          }}
                          onLightToggleRequest={(_event) => {
                            dispatch(
                              executeDeviceAction({
                                deviceIds: [item.id],
                                action: {
                                  command: "light_apply",
                                  lightType: "light_1",
                                  brightness:
                                    item.light1Brightness === 1 ? 0 : 1,
                                },
                                deviceLinkToken: deviceLinkToken ?? undefined,
                              }),
                            )
                              .unwrap()
                              .then((results) =>
                                handleQuickActionResults(item.id, results),
                              )
                              .catch((err) => handleQuickActionError(err));
                          }}
                        />
                      ),
                      item?.deviceLinkSpecifier?.light2 && (
                        <LightCard
                          device={item}
                          className="device-card"
                          key={`${item.id}-light2`}
                          name={item.settings?.light2Name}
                          brightness={item.light2Brightness!}
                          onCardClicked={(_event) => {
                            navigate.push(
                              {
                                pathname: `${match.url}/device`,
                                search: `token=${deviceLinkToken}&deviceId=${item.id}&deviceSpecifier=light_2`,
                              },
                              {
                                id: item.id,
                                deviceLinkToken,
                                lightType: "light_2",
                              },
                            );
                          }}
                          onLightToggleRequest={(_event) => {
                            dispatch(
                              executeDeviceAction({
                                deviceIds: [item.id],
                                action: {
                                  command: "light_apply",
                                  lightType: "light_2",
                                  brightness:
                                    item.light2Brightness === 1 ? 0 : 1,
                                },
                                deviceLinkToken: deviceLinkToken ?? undefined,
                              }),
                            )
                              .unwrap()
                              .then((results) =>
                                handleQuickActionResults(item.id, results),
                              )
                              .catch((err) => handleQuickActionError(err));
                          }}
                        />
                      ),
                    ])
                  }
                </>
              </ul>
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
};
