export class AwaitableCountdown {
  count: number = 0;
  resolver: ((arg: void) => void) | null = null;
  promise: Promise<void> = new Promise((res, _) => (this.resolver = res));

  constructor(initialCount: number) {
    this.count = initialCount;
    if (this.count == 0) {
      this.promise = Promise.resolve();
    }
  }

  waitForZero(): Promise<void> {
    return this.promise;
  }

  increment(amount: number = 1) {
    const startCount = this.count;
    this.count += amount;
    if (startCount == 0 && this.count > 0) {
      this.promise = new Promise((res, _) => (this.resolver = res));
    }
  }

  decrement(amount: number = 1) {
    this.count -= amount;
    if (this.count == 0) {
      if (this.resolver != null) {
        this.resolver();
        this.resolver = null;
      }
    }
  }
}
