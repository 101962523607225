import React, { useCallback, useEffect, useRef, useState } from "react";

interface ProgressBarCanvasProps {
  /**
   * @description The percentage that the progress bar should show (between 0.0 (0%) and 1.0 (100%), inclusive)
   */
  percentage: number;
}

type CanvasProps = React.DetailedHTMLProps<
  React.CanvasHTMLAttributes<HTMLCanvasElement>,
  HTMLCanvasElement
>;

export const DiamondProgressBarCanvas: React.FC<
  ProgressBarCanvasProps & CanvasProps
> = ({ percentage, ...props }) => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const [canvasArea, setCanvasArea] = useState({
    width: 1,
    height: 1,
    scale: 1,
  });

  const onResize = useCallback(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const parent = canvas.parentElement;
    if (!parent) return;

    const scale = window.devicePixelRatio;
    canvas.width = Math.floor(parent.clientWidth * scale);
    canvas.height = Math.floor(parent.clientHeight * scale);
    setCanvasArea({ width: canvas.width, height: canvas.height, scale });
  }, []);

  useEffect(() => {
    window.addEventListener("resize", onResize);
    onResize();
    return () => window.removeEventListener("resize", onResize);
  }, [onResize]);

  (() => {
    const canvas = canvasRef.current;
    if (!canvas) {
      return;
    }
    const ctx = canvas.getContext("2d");
    if (!ctx) {
      return;
    }
    const scale = window.devicePixelRatio;
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.save();
    ctx.scale(scale, scale);

    const cWidth = canvas.width / scale;
    const cHeight = canvas.height / scale;
    // Diamant
    const centerX = cWidth / 2;
    const centerY = cHeight / 2;
    const radius = 70;
    const sides = 5;

    ctx.save();
    ctx.translate(centerX, centerY);
    ctx.rotate(-Math.PI / 2);
    ctx.beginPath();
    ctx.moveTo(radius * Math.cos(0), radius * Math.sin(0));

    for (let i = 1; i <= sides; i++) {
      const angle = (i * (2 * Math.PI)) / sides;
      ctx.lineTo(radius * Math.cos(angle), radius * Math.sin(angle));
    }
    ctx.closePath();
    ctx.fillStyle = "rgba(45, 45, 45, 1)";
    ctx.fill();

    ctx.restore();
    ctx.save();

    //  Progress bar
    const progressRadius = radius;

    ctx.lineWidth = 3;
    ctx.strokeStyle = "rgba(149, 239, 247, 1)";
    ctx.translate(centerX, centerY);
    ctx.rotate(-Math.PI / 2);
    ctx.beginPath();
    ctx.moveTo(progressRadius * Math.cos(0), progressRadius * Math.sin(0));

    let progressRounded = Math.round(percentage * 100);
    let progressSides = Math.floor(progressRounded / 20);

    for (let i = 1; i <= progressSides; i++) {
      const angle = (i * (2 * Math.PI)) / sides;
      ctx.lineTo(
        progressRadius * Math.cos(angle),
        progressRadius * Math.sin(angle),
      );
    }

    if (progressSides < 5) {
      // .--------.
      // ^ end |  |
      //       |  ^ start
      //       ^ lineEndPoint
      // <--------- endVec
      //       <--- endVec * percentOfLineToDraw
      const startAngle = (progressSides * (2 * Math.PI)) / sides;
      const endAngle = ((progressSides + 1) * (2 * Math.PI)) / sides;
      const start = {
        x: progressRadius * Math.cos(startAngle),
        y: progressRadius * Math.sin(startAngle),
      };
      const end = {
        x: progressRadius * Math.cos(endAngle),
        y: progressRadius * Math.sin(endAngle),
      };
      const endVec = { x: end.x - start.x, y: end.y - start.y };
      const percentOfLineToDraw = (progressRounded - progressSides * 20) / 20;
      const lineEndPoint = {
        x: endVec.x * percentOfLineToDraw,
        y: endVec.y * percentOfLineToDraw,
      };
      ctx.lineTo(start.x + lineEndPoint.x, start.y + lineEndPoint.y);
    }

    ctx.stroke();

    ctx.restore();
    ctx.save();
    ctx.font = "1.5em Nunito";
    const percentageText = `${progressRounded}%`;
    const measured = ctx.measureText(percentageText);
    ctx.translate(
      centerX - measured.width / 2,
      centerY +
        (measured.actualBoundingBoxAscent + measured.actualBoundingBoxDescent) /
          2,
    );
    ctx.fillStyle = "white";
    ctx.fillText(percentageText, 0, 0);

    ctx.restore();
    ctx.restore();
  })();

  return (
    <canvas
      style={{
        width: "100%",
        height: "100%",
        touchAction: "none",
        ...props.style,
      }}
      ref={canvasRef}
    />
  );
};
