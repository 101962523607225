import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Button from "@mui/material/Button";
import { Alert, Snackbar, TextField } from "@mui/material";
import PasswordIcon from "@mui/icons-material/Password";
import BackIcon from "../assets/icon_back.svg";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../app/hooks";
import { addPasswordAsync } from "../app/AccountSlice";
import AlertDialog from "../components/AlertDialog";

export const AddPasswordPage = () => {
  const navigate = useHistory();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [passwordValid, setPasswordValid] = useState(false);
  const [addPasswordRequest, setAddPasswordRequest] = useState<string | null>(
    null,
  );
  const [isErrorSnackbarShown, setIsErrorSnackbarShown] = useState(false);
  const [errorSnackbarMessage, setErrorSnackbarMessage] = useState("");
  const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false);

  useEffect(() => {
    if (navigate.location.state) {
      const navigationState = navigate.location.state as {
        request?: string;
      };

      setAddPasswordRequest(navigationState.request ?? null);
    }
    if (navigate.location.search) {
      const queryString = new URLSearchParams(navigate.location.search);
      setAddPasswordRequest(queryString.get("request"));
    }
  }, [navigate.location.state, navigate.location.search]);

  useEffect(() => {
    setPasswordsMatch(newPassword === confirmPassword);
  }, [newPassword, confirmPassword]);

  useEffect(() => {
    setPasswordValid(
      newPassword.length > 0 && confirmPassword.length > 0 && passwordsMatch,
    );
  }, [newPassword, confirmPassword, passwordsMatch]);

  const showError = (error: string) => {
    setErrorSnackbarMessage(error);
    setIsErrorSnackbarShown(true);
  };

  const handleErrorDismiss = () => {
    setIsErrorSnackbarShown(false);
  };

  const handleAddPassword = () => {
    if (addPasswordRequest == null) {
      const err = "Add password request is null";
      showError(err);
      console.error(err);
      return;
    }

    dispatch(
      addPasswordAsync({ request: addPasswordRequest, password: newPassword }),
    )
      .unwrap()
      .then((result) => {
        setIsSuccessDialogOpen(true);
      })
      .catch((error) => {
        const allErrorParts = [error.error_description];
        if (error.error_detail) {
          allErrorParts.push(error.error_detail);
        }
        showError(allErrorParts.join(". "));
        console.error(error);
      });
  };

  const handleSuccessDialogDismiss = () => {
    navigate.replace("/");
    setIsSuccessDialogOpen(false);
  };

  return (
    <>
      <div className="form-background">
        <div className="form">
          <div className="back-button" onClick={() => navigate.replace("/")}>
            <img src={BackIcon} alt={t("button_back")} className="back-icon" />
            {t("button_back")}
          </div>
          {addPasswordRequest == null && (
            <p>{t("dialog_error_confirmationLinkExpired")}</p>
          )}
          {addPasswordRequest != null && (
            <>
              <TextField
                variant="outlined"
                label={t("label_newPassword")}
                className="form-input"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                type="password"
                name="name"
                margin="normal"
              />
              <TextField
                variant="outlined"
                label={t("label_confirmNewPassword")}
                className="form-input"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                type="password"
                name="name"
                margin="normal"
              />

              {!passwordsMatch && (
                <div className="invalid-alert">
                  {t("validation_passwordsNoMatch")}
                </div>
              )}

              <Button
                disabled={!passwordValid}
                className="form-button"
                type="submit"
                variant="contained"
                size="medium"
                startIcon={<PasswordIcon />}
                onClick={handleAddPassword}
              >
                {t("email_button_addPassword")}
              </Button>
            </>
          )}
          <AlertDialog
            open={isSuccessDialogOpen}
            title={t("title_success")}
            content={<p>{t("dialog_alert_passwordUpdated")}</p>}
            onDismiss={handleSuccessDialogDismiss}
          />
          {isErrorSnackbarShown && (
            <Snackbar
              open={isErrorSnackbarShown}
              onClose={handleErrorDismiss}
              message={errorSnackbarMessage}
            >
              <Alert
                elevation={6}
                variant="filled"
                severity="error"
                onClose={handleErrorDismiss}
              >
                {errorSnackbarMessage}
              </Alert>
            </Snackbar>
          )}
        </div>
      </div>
    </>
  );
};
