import React, { useEffect, useState } from "react";
import { getGroups } from "../adcApi";
import Layout from "../components/Layout";
import { Button, Paper, Slider } from "@mui/material";
import AirOutlinedIcon from "@mui/icons-material/AirOutlined";
import ThunderstormOutlinedIcon from "@mui/icons-material/ThunderstormOutlined";
import AcUnitOutlinedIcon from "@mui/icons-material/AcUnitOutlined";
import WaterOutlinedIcon from "@mui/icons-material/WaterOutlined";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { getToken } from "../services/AccountService";
import WeatherComponent from "../components/WeatherComponent";

export const GroupPage = () => {
  return (
    <>
      <Layout>
        <DeviceGroup />
      </Layout>
    </>
  );
};
function DeviceGroup() {
  const [group, setGroup] = useState<
    Array<{
      activeDeviceTokens: string[];
      name: string;
      id: number;
    }>
  >();
  const [expandGroup, setExpandGroup] = useState(null);

  useEffect(() => {
    getToken()
      .then((apiToken) => getGroups(apiToken))
      .then((data) => {
        setGroup(data);
        console.log(data);
      });
  }, []);

  const handleExpandGroup = (groupId: any) => {
    if (expandGroup === groupId) {
      setExpandGroup(null);
    } else {
      setExpandGroup(groupId);
    }
  };

  return (
    <div className="device-list-container">
      <div className="App">
        <header className="App-body">
          <WeatherComponent />

          <div className="scrollable-content">
            <ul className="device-container">
              {group &&
                group.map((item) => (
                  <Paper
                    variant="outlined"
                    square={false}
                    className="list-item"
                    key={item.id}
                  >
                    <div className="group-info-container">
                      <div
                        className="grid-option group-name"
                        // onClick={() => navigate("/devicepage")}
                      >
                        {item.name}
                      </div>
                      <div className="grid-option group-buttons">
                        <div>
                          <Button variant="outlined" color="info">
                            <KeyboardArrowUpOutlinedIcon />
                          </Button>
                          <Button variant="outlined" color="info">
                            <KeyboardArrowDownOutlinedIcon />
                          </Button>
                          <Button
                            variant="outlined"
                            color="info"
                            onClick={() => handleExpandGroup(item.id)}
                          >
                            <MoreHorizIcon />
                          </Button>
                        </div>
                      </div>
                      <div className="grid-option slider-container-group">
                        <Slider
                          min={0}
                          max={1}
                          step={0.01}
                          //value={item.position}
                        />
                      </div>
                    </div>
                    {expandGroup === item.id && (
                      <Paper
                        variant="outlined"
                        elevation={12}
                        square={false}
                        className="nested-paper"
                      >
                        <ul className="device-container">
                          {item.activeDeviceTokens.map(
                            (token: string, index: number) => (
                              <li key={index}>{token}</li>
                            ),
                          )}
                        </ul>
                      </Paper>
                    )}
                  </Paper>
                ))}
            </ul>
          </div>
        </header>
      </div>
    </div>
  );
}

export default DeviceGroup;
