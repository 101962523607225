import React, { useState } from "react";
import Layout from "../components/Layout";
import MenuItem from "../components/MenuItemComponent";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Snackbar,
} from "@mui/material";
import { LogoutOutlined } from "@mui/icons-material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import LogoutIcon from "@mui/icons-material/Logout";
import SubscriptionsOutlinedIcon from "@mui/icons-material/SubscriptionsOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";
import IntegrationInstructionsOutlinedIcon from "@mui/icons-material/IntegrationInstructionsOutlined";
import ChecklistOutlinedIcon from "@mui/icons-material/ChecklistOutlined";
import PasswordOutlinedIcon from "@mui/icons-material/PasswordOutlined";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import LibraryBooksOutlinedIcon from "@mui/icons-material/LibraryBooksOutlined";
import EnhancedEncryptionOutlinedIcon from "@mui/icons-material/EnhancedEncryptionOutlined";
import { useHistory } from "react-router-dom";
import WeatherComponent from "../components/WeatherComponent";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { logoutAsync, logoutEverywhereAsync } from "../app/AccountSlice";
import { useTranslation } from "react-i18next";
import { requestAccountDeletion, requestAddPassword } from "../api/AccountApi";
import { useSelector } from "react-redux";
import { RootState } from "../app/store";

interface DialogProps {
  open: boolean;
  onClose: () => void;
}

export const MenuPage = () => {
  return (
    <Layout>
      <MenuList />
    </Layout>
  );
};
//navodila pa support
function MenuList() {
  const navigate = useHistory();
  const { t } = useTranslation();
  const [isLanguageDialogOpen, setIsLanguageDialogOpen] = useState(false);
  const [isLogoutDialogOpen, setIsLogoutDialogOpen] = useState(false);
  const [isLogoutEverywhereDialogOpen, setIsLogoutEverywhereDialogOpen] =
    useState(false);
  const [isDeleteAccountDialogOpen, setIsDeleteAccountDialogOpen] =
    useState(false);
  const [isAddPasswordDialogOpen, setIsAppPasswordDialogOpen] = useState(false);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [snackbarText, setSnackbarText] = useState("Hello");
  const isPasswordSet = useAppSelector((state) => state.account.isPasswordSet);

  const handleSnackbarClose = () => {
    setIsSnackbarOpen(false);
  };

  const openLanguageDialog = () => {
    setIsLanguageDialogOpen(true);
  };

  const openLogoutDialog = () => {
    setIsLogoutDialogOpen(true);
  };

  const closeLanguageDialog = () => {
    setIsLanguageDialogOpen(false);
  };

  const closeLogoutDialog = () => {
    setIsLogoutDialogOpen(false);
  };

  const openLogoutEverywhereDialog = () => {
    setIsLogoutEverywhereDialogOpen(true);
  };

  const closeLogoutEverywhereDialog = () => {
    setIsLogoutEverywhereDialogOpen(false);
  };

  const openAddPasswordDialog = () => {
    setIsAppPasswordDialogOpen(true);
  };

  const closeAddPasswordDialog = () => {
    setIsAppPasswordDialogOpen(false);
  };

  const openSupportURL = () => {
    window.open("https://adcsistemi.si/kontakt/", "_blank");
  };

  const openDeleteAccountDialog = () => {
    setIsDeleteAccountDialogOpen(true);
  };

  const closeDeleteAccountDialog = () => {
    setIsDeleteAccountDialogOpen(false);
  };

  return (
    <div className="device-list-container">
      <div className="App">
        <header className="App-body">
          <WeatherComponent />
          <div className="scrollable-content">
            <ul className="device-container">
              <List style={{ width: "80%" }}>
                <MenuItem
                  icon={<LanguageOutlinedIcon />}
                  primaryText={t("button_language")}
                  onClick={openLanguageDialog}
                />
                {/*
                <MenuItem
                  icon={<LocationOnOutlinedIcon />}
                  primaryText={t("button_homeLocation")}
                />

                <MenuItem
                  icon={<IntegrationInstructionsOutlinedIcon />}
                  primaryText={t("button_integrations")}
                />

                <MenuItem
                  icon={<SubscriptionsOutlinedIcon />}
                  primaryText={t("title_subscription")}
                />

                <MenuItem
                  icon={<ChecklistOutlinedIcon />}
                  primaryText={t("title_roles")}
                />
                */}

                <MenuItem
                  icon={<SupportAgentIcon />}
                  onClick={openSupportURL}
                  primaryText={t("button_support")}
                />

                <MenuItem
                  icon={<PasswordOutlinedIcon />}
                  onClick={() => navigate.push("changepassword")}
                  primaryText={t("button_changePassword")}
                />

                {!isPasswordSet && (
                  <MenuItem
                    icon={<EnhancedEncryptionOutlinedIcon />}
                    onClick={openAddPasswordDialog}
                    primaryText={t("email_button_addPassword")}
                  />
                )}

                <MenuItem
                  icon={<DeleteOutlineOutlinedIcon style={{ color: "red" }} />}
                  primaryText={t("button_deleteAccount")}
                  primaryTextStyle={{ color: "red" }}
                  onClick={openDeleteAccountDialog}
                />

                <MenuItem
                  icon={<LogoutIcon style={{ color: "red" }} />}
                  primaryText={t("button_logOutEverywhere")}
                  primaryTextStyle={{ color: "red" }}
                  onClick={openLogoutEverywhereDialog}
                />

                <MenuItem
                  icon={<LogoutOutlined />}
                  primaryText={t("button_logOut")}
                  onClick={openLogoutDialog}
                />
              </List>
            </ul>
            <Snackbar
              open={isSnackbarOpen}
              autoHideDuration={6000}
              message={snackbarText}
            >
              <Alert
                elevation={6}
                variant="filled"
                severity="success"
                onClose={handleSnackbarClose}
              >
                {snackbarText}
              </Alert>
            </Snackbar>
          </div>
        </header>
      </div>

      {isLanguageDialogOpen && (
        <LanguageDialog
          open={isLanguageDialogOpen}
          onClose={closeLanguageDialog}
        />
      )}

      {isLogoutDialogOpen && (
        <AlertDialog open={isLogoutDialogOpen} onClose={closeLogoutDialog} />
      )}

      {isLogoutEverywhereDialogOpen && (
        <LogoutEverywhereDialog
          open={isLogoutEverywhereDialogOpen}
          onClose={closeLogoutEverywhereDialog}
        />
      )}
      {isDeleteAccountDialogOpen && (
        <DeleteAccountDialog
          open={isDeleteAccountDialogOpen}
          onClose={closeDeleteAccountDialog}
          onEmailSent={() => {
            setSnackbarText(t("title_dialog_emailSent"));
            setIsSnackbarOpen(true);
          }}
        />
      )}
      {isAddPasswordDialogOpen && (
        <AddPasswordDialog
          open={isAddPasswordDialogOpen}
          onClose={closeAddPasswordDialog}
          onEmailSent={() => {
            setSnackbarText(t("title_dialog_emailSent"));
            setIsSnackbarOpen(true);
          }}
        />
      )}
    </div>
  );
}

function LanguageDialog({ open, onClose }: DialogProps) {
  const { t } = useTranslation();
  const { i18n } = useTranslation();

  const [selectedLanguage, setSelectedLanguage] = useState("");
  const handleLanguageSelect = (language: any) => {
    setSelectedLanguage(language);
  };

  const handleSave = () => {
    console.log("Selected language:", selectedLanguage);
    onClose();
  };

  const changeLanguage = (language: string) => {
    i18n.changeLanguage(language);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle style={{ textTransform: "uppercase" }}>
        {t("title_languageSelection")}
      </DialogTitle>
      <DialogContent>
        <p
          className={`dialog-content-option ${
            selectedLanguage === "en" ? "selected" : ""
          }`}
          onClick={() => handleLanguageSelect("en")}
        >
          English
        </p>

        <p
          className={`dialog-content-option ${
            selectedLanguage === "sl" ? "selected" : ""
          }`}
          onClick={() => handleLanguageSelect("sl")}
        >
          Slovenščina
        </p>

        <p
          className={`dialog-content-option ${
            selectedLanguage === "hr" ? "selected" : ""
          }`}
          onClick={() => handleLanguageSelect("hr")}
        >
          Hrvatski
        </p>

        <p
          className={`dialog-content-option ${
            selectedLanguage === "bs" ? "selected" : ""
          }`}
          onClick={() => handleLanguageSelect("bs")}
        >
          Bosanski
        </p>

        <p
          className={`dialog-content-option ${
            selectedLanguage === "rs_latn" ? "selected" : ""
          }`}
          onClick={() => handleLanguageSelect("rs_latn")}
        >
          Srpski
        </p>

        <p
          className={`dialog-content-option ${
            selectedLanguage === "rs_cyrl" ? "selected" : ""
          }`}
          onClick={() => handleLanguageSelect("rs_cyrl")}
        >
          Српски
        </p>

        <p
          className={`dialog-content-option ${
            selectedLanguage === "de" ? "selected" : ""
          }`}
          onClick={() => handleLanguageSelect("de")}
        >
          Deutsch
        </p>

        <p
          className={`dialog-content-option ${
            selectedLanguage === "it" ? "selected" : ""
          }`}
          onClick={() => handleLanguageSelect("it")}
        >
          Italiano
        </p>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" variant="outlined">
          {t("button_cancel")}
        </Button>
        <Button
          onClick={() => {
            changeLanguage(selectedLanguage);
            onClose();
          }}
          color="primary"
          variant="outlined"
        >
          {t("button_confirm")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function AlertDialog({ open, onClose }: DialogProps) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  let navigate = useHistory();
  const handleLogout = () => {
    dispatch(logoutAsync())
      .unwrap()
      .then((result) => {
        navigate.push("../");
      })
      .catch((error) => {
        console.error(error);
      });
  };
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle style={{ textTransform: "uppercase" }}>
        {t("title_dialog_confirm")}
      </DialogTitle>
      <DialogContent>
        <p>{t("dialog_logout")}</p>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" variant="outlined">
          {t("button_cancel")}
        </Button>
        <Button onClick={handleLogout} color="primary" variant="outlined">
          {t("button_logOut")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function LogoutEverywhereDialog({ open, onClose }: DialogProps) {
  let navigate = useHistory();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const handleLogout = () => {
    dispatch(logoutEverywhereAsync())
      .unwrap()
      .then((result) => {
        navigate.push("../");
      })
      .catch((error) => {
        console.error(error);
      });
  };
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle style={{ textTransform: "uppercase" }}>
        {t("title_dialog_confirm")}
      </DialogTitle>
      <DialogContent>
        <p>{t("dialog_confirm_logOutEverywhere")}</p>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" variant="outlined">
          {t("button_cancel")}
        </Button>
        <Button onClick={handleLogout} color="error" variant="outlined">
          {t("button_logOutEverywhere")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

interface DeleteAccountDialogProps extends DialogProps {
  onEmailSent?: () => void;
}

function DeleteAccountDialog({
  open,
  onClose,
  onEmailSent,
}: DeleteAccountDialogProps) {
  const { t } = useTranslation();
  const locale = useSelector(
    (state: RootState) => state.account.profile?.locale,
  );
  const handleSendMail = () => {
    requestAccountDeletion("link", locale)
      .then(() => onEmailSent && onEmailSent())
      .catch((err) => {
        console.error(`Failed to start account deletion request: ${err}`);
      })
      .finally(() => {
        onClose();
      });
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle style={{ textTransform: "uppercase" }}>
        {t("title_deleteAccount")}
      </DialogTitle>
      <DialogContent>
        <p>{t("dialog_confirm_deleteAccountProcessBegin")}</p>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" variant="outlined">
          {t("button_no")}
        </Button>
        <Button onClick={handleSendMail} color="primary" variant="outlined">
          {t("button_yes")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function AddPasswordDialog({
  open,
  onClose,
  onEmailSent,
}: DeleteAccountDialogProps) {
  const { t } = useTranslation();
  const locale = useSelector(
    (state: RootState) => state.account.profile?.locale,
  );
  const handleSendMail = () => {
    requestAddPassword("link", locale)
      .then(() => onEmailSent && onEmailSent())
      .catch((err) => {
        console.error(
          `Failed to start account password adding request: ${err}`,
        );
      })
      .finally(() => {
        onClose();
      });
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle style={{ textTransform: "uppercase" }}>
        {t("title_addPassword")}
      </DialogTitle>
      <DialogContent>
        <p>{t("dialog_confirm_addPasswordProcessBegin")}</p>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" variant="outlined">
          {t("button_no")}
        </Button>
        <Button onClick={handleSendMail} color="primary" variant="outlined">
          {t("button_yes")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
