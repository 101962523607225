import React, { useEffect, useState, useTransition } from "react";
import Layout from "../components/Layout";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  List,
  ListSubheader,
  TextField,
} from "@mui/material";
import BackIcon from "../assets/icon_back.svg";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useHistory, useLocation } from "react-router-dom";
import SettingsItem from "../components/SettingsItemComponent";
import LoadingIndicator from "../components/LoadingIndicator";
import { useTranslation } from "react-i18next";

interface DialogProps {
  open: boolean;
  onClose: () => void;
}
interface RecalibrateDialogProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
}
type DeviceInfo = {
  name: string;
  token: string;
  devicetype: string;
  inclination: number;
  position: number;
};

function formatDeviceType(devicetype: string): string {
  return devicetype.replace(/_/g, " ").toUpperCase();
}

export const DeviceSettingsPage = () => {
  return (
    <Layout>
      <DeviceSettings />
    </Layout>
  );
};

function DeviceSettings() {
  const navigate = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  const state = location.state as DeviceInfo;
  const { devicetype } = state;

  const [isDeviceTypeDialogOpen, setIsDeviceTypeDialogOpen] = useState(false);
  const [isDeviceDirectionDialogOpen, setIsDeviceDirectionDialogOpen] =
    useState(false);
  const [isRecalibrateDialogOpen, setIsRecalibrateDialogOpen] = useState(false);
  const [isRestartDialogOpen, setIsRestartDialogOpen] = useState(false);
  const [isDeleteDeviceDialogOpen, setIsDeleteDevicetDialogOpen] =
    useState(false);
  const [isRenameDeviceDialogOpen, setIsRenameDeviceDialogOpen] =
    useState(false);
  const [isInclinationTimeDialogOpen, setIsInclinationTimeDialogOpen] =
    useState(false);
  const [isInclinationTypeDialogOpen, setIsInclinationTypeDialogOpen] =
    useState(false);
  const [selectedInclinationType, setSelectedInclinationType] = useState("");
  const [isSwitchTypeDialogOpen, setIsSwitchTypeDialogOpen] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const openDeviceTypeDialog = () => {
    setIsDeviceTypeDialogOpen(true);
  };
  const closeDeviceTypeDialog = () => {
    setIsDeviceTypeDialogOpen(false);
  };
  const openRenameDeviceDialog = () => {
    setIsRenameDeviceDialogOpen(true);
  };
  const closeRenameDeviceDialog = () => {
    setIsRenameDeviceDialogOpen(false);
  };

  const openDeviceDirectionDialog = () => {
    setIsDeviceDirectionDialogOpen(true);
  };
  const closeDeviceDirectionDialog = () => {
    setIsDeviceDirectionDialogOpen(false);
  };

  const openRecalibrateDialog = () => {
    setIsRecalibrateDialogOpen(true);
  };
  const closeRecalibrateDialog = () => {
    setIsRecalibrateDialogOpen(false);
  };

  const openRestartDialog = () => {
    setIsRestartDialogOpen(true);
  };
  const closeRestartDialog = () => {
    setIsRestartDialogOpen(false);
  };

  const openInclinationTimeDialog = () => {
    setIsInclinationTimeDialogOpen(true);
  };
  const closeInclinationTimeDialog = () => {
    setIsInclinationTimeDialogOpen(false);
  };
  const openInclinationTypeDialog = () => {
    setIsInclinationTypeDialogOpen(true);
  };
  const closeInclinationTypeDialog = () => {
    setIsInclinationTypeDialogOpen(false);
  };
  const handleInclinationTypeSelect = (inclinationType: string) => {
    setSelectedInclinationType(inclinationType);
  };
  const openSwitchTypeDialog = () => {
    setIsSwitchTypeDialogOpen(true);
  };
  const closeSwitchTypeDialog = () => {
    setIsSwitchTypeDialogOpen(false);
  };

  const openDeleteDeviceDialog = () => {
    setIsDeleteDevicetDialogOpen(true);
  };
  const closeDeleteDeviceDialog = () => {
    setIsDeleteDevicetDialogOpen(false);
  };

  const handleRecalibrateConfirm = () => {
    setIsLoading(true);

    // Simulating a 5-second delay
    setTimeout(() => {
      setIsLoading(false);
      closeRecalibrateDialog();
    }, 5000);
  };

  useEffect(() => {
    // Reset loading state when the component unmounts
    return () => {
      setIsLoading(false);
    };
  }, []);

  return (
    <div className="device-list-container">
      <div className="App">
        <header className="App-body">
          <div className="rectangles-container">
            <div className="device-header-grid">
              <div>
                <img
                  src={BackIcon}
                  className="back-icon"
                  onClick={() => navigate.goBack()}
                />
              </div>
              <div>{t("title_settings")}</div>
              <div></div>
            </div>
          </div>
          {isLoading && <LoadingIndicator style={{ width: "80%" }} />}
          <div className="scrollable-menu-content">
            <ul className="menu-container">
              <List style={{ width: "80%" }}>
                <ListSubheader
                  color="primary"
                  style={{
                    textAlign: "left",
                    backgroundColor: "transparent",
                    position: "static",
                    textTransform: "uppercase",
                  }}
                >
                  {t("sectionTitle_favorites")}
                </ListSubheader>
                <SettingsItem
                  primaryText={t("button_favorite")}
                  icon={<ChevronRightIcon />}
                  onClick={() => navigate.push("/devicefavouritepage")}
                />
                <Divider />
                <SettingsItem
                  primaryText={t("button_ventilation")}
                  icon={<ChevronRightIcon />}
                  onClick={() => navigate.push("/deviceventilationpage")}
                />

                <ListSubheader
                  color="primary"
                  style={{
                    textAlign: "left",
                    backgroundColor: "transparent",
                    position: "static",
                  }}
                >
                  {formatDeviceType(devicetype)}
                </ListSubheader>

                <SettingsItem
                  primaryText={t("button_rename")}
                  icon={<ChevronRightIcon />}
                  onClick={openRenameDeviceDialog}
                />

                <Divider />

                <SettingsItem
                  primaryText={t("button_recalibrate")}
                  icon={<ChevronRightIcon />}
                  onClick={openRecalibrateDialog}
                />

                <Divider />

                <SettingsItem
                  primaryText={t("button_windowShadeType")}
                  icon={<ChevronRightIcon />}
                  onClick={openDeviceTypeDialog}
                />

                <Divider />

                <SettingsItem
                  primaryText={t("button_changeDirections")}
                  icon={<ChevronRightIcon />}
                  onClick={openDeviceDirectionDialog}
                />

                <Divider />

                <SettingsItem
                  primaryText={t("button_typeOfSwitch")}
                  icon={<ChevronRightIcon />}
                  onClick={openSwitchTypeDialog}
                />

                {devicetype === "venetian_blind" && (
                  <>
                    <Divider />
                    <SettingsItem
                      primaryText={t("button_inclinationTime")}
                      icon={<ChevronRightIcon />}
                      onClick={openInclinationTimeDialog}
                    />

                    <Divider />
                    <SettingsItem
                      primaryText={t("button_inclinationType")}
                      icon={<ChevronRightIcon />}
                      onClick={openInclinationTypeDialog}
                    />
                  </>
                )}

                <ListSubheader
                  color="primary"
                  style={{
                    textAlign: "left",
                    backgroundColor: "transparent",
                    position: "static",
                    textTransform: "uppercase",
                  }}
                >
                  {t("sectionTitle_controller")}
                </ListSubheader>

                <SettingsItem
                  primaryText={t("button_restartDevice")}
                  icon={<ChevronRightIcon />}
                  onClick={openRestartDialog}
                />

                <Divider />
                <SettingsItem
                  primaryText={t("button_deleteDevice")}
                  icon={<ChevronRightIcon />}
                  onClick={openDeleteDeviceDialog}
                />
              </List>
            </ul>
          </div>
        </header>
      </div>
      {isDeviceTypeDialogOpen && (
        <DeviceTypeDialog
          open={isDeviceTypeDialogOpen}
          onClose={closeDeviceTypeDialog}
        />
      )}

      {isDeviceDirectionDialogOpen && (
        <DeviceDirectionDialog
          open={isDeviceDirectionDialogOpen}
          onClose={closeDeviceDirectionDialog}
        />
      )}

      {isRenameDeviceDialogOpen && (
        <RenameDeviceDialog
          open={isRenameDeviceDialogOpen}
          onClose={closeRenameDeviceDialog}
        />
      )}

      {isRecalibrateDialogOpen && (
        <RecalibrateAlertDialog
          open={isRecalibrateDialogOpen}
          onClose={closeRecalibrateDialog}
          onConfirm={handleRecalibrateConfirm}
        />
      )}

      {isRestartDialogOpen && (
        <RestartAlertDialog
          open={isRestartDialogOpen}
          onClose={closeRestartDialog}
        />
      )}

      {isSwitchTypeDialogOpen && (
        <SwitchTypeDialog
          open={isSwitchTypeDialogOpen}
          onClose={closeSwitchTypeDialog}
        />
      )}

      {isInclinationTimeDialogOpen && (
        <InclinationTimeDialog
          open={isInclinationTimeDialogOpen}
          onClose={closeInclinationTimeDialog}
        />
      )}

      {isInclinationTypeDialogOpen && (
        <InclinationTypeDialog
          open={isInclinationTypeDialogOpen}
          onClose={closeInclinationTypeDialog}
          onSelect={handleInclinationTypeSelect}
        />
      )}

      {isDeleteDeviceDialogOpen && (
        <DeleteDeviceAlertDialog
          open={isDeleteDeviceDialogOpen}
          onClose={closeDeleteDeviceDialog}
        />
      )}
    </div>
  );
}

function DeviceTypeDialog({ open, onClose }: DialogProps) {
  const { t } = useTranslation();
  const [selectedDeviceType, setSelectedDeviceType] = useState("");

  const handleDeviceTypeSelect = (deviceType: any) => {
    setSelectedDeviceType(deviceType);
  };

  const handleSave = () => {
    onClose();
  };
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle style={{ textTransform: "uppercase" }}>
        {t("dialog_prompt_selectNewDeviceType")}
      </DialogTitle>
      <DialogContent>
        <p
          className={`dialog-content-option ${
            selectedDeviceType === "Roller shutter" ? "selected" : ""
          }`}
          onClick={() => handleDeviceTypeSelect("Roller shutter")}
        >
          {t("deviceType_rollerShutter")}
        </p>
        <p
          className={`dialog-content-option ${
            selectedDeviceType === "Venetian blind" ? "selected" : ""
          }`}
          onClick={() => handleDeviceTypeSelect("Venetian blind")}
        >
          {t("deviceType_venetianBlind")}
        </p>
        <p
          className={`dialog-content-option ${
            selectedDeviceType === "Textile screen" ? "selected" : ""
          }`}
          onClick={() => handleDeviceTypeSelect("Textile screen")}
        >
          {t("deviceType_textileScreen")}
        </p>
        <p
          className={`dialog-content-option ${
            selectedDeviceType === "Awning" ? "selected" : ""
          }`}
          onClick={() => handleDeviceTypeSelect("Awning")}
        >
          {t("deviceType_awning")}
        </p>
        <p
          className={`dialog-content-option ${
            selectedDeviceType === "Insect screen" ? "selected" : ""
          }`}
          onClick={() => handleDeviceTypeSelect("Insect screen")}
        >
          {t("deviceType_insectScreen")}
        </p>
        <p
          className={`dialog-content-option ${
            selectedDeviceType === "Pergola" ? "selected" : ""
          }`}
          onClick={() => handleDeviceTypeSelect("Pergola")}
        >
          {t("deviceType_pergola")}
        </p>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" variant="outlined">
          {t("button_cancel")}
        </Button>
        <Button onClick={onClose} color="primary" variant="outlined">
          {t("button_confirm")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function DeviceDirectionDialog({ open, onClose }: DialogProps) {
  const { t } = useTranslation();
  const [selectedDeviceDirection, setSelectedDeviceDirection] = useState("");
  const handleDeviceDirectionSelect = (deviceDirection: any) => {
    setSelectedDeviceDirection(deviceDirection);
  };

  const handleSave = () => {
    onClose();
  };
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle style={{ textTransform: "uppercase" }}>
        {t("dialog_prompt_directionsInvertedWhere")}
      </DialogTitle>
      <DialogContent>
        <p
          className={`dialog-content-option ${
            selectedDeviceDirection === "Motor" ? "selected" : ""
          }`}
          onClick={() => handleDeviceDirectionSelect("Motor")}
        >
          {t("option_invertDirection_motor")}
        </p>
        <p
          className={`dialog-content-option ${
            selectedDeviceDirection === "Switch" ? "selected" : ""
          }`}
          onClick={() => handleDeviceDirectionSelect("Switch")}
        >
          {t("option_invertDirection_switch")}
        </p>
        <p
          className={`dialog-content-option ${
            selectedDeviceDirection === "Both" ? "selected" : ""
          }`}
          onClick={() => handleDeviceDirectionSelect("Both")}
        >
          {t("option_invertDirection_both")}
        </p>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" variant="outlined">
          {t("button_cancel")}
        </Button>
        <Button onClick={onClose} color="primary" variant="outlined">
          {t("button_confirm")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function RenameDeviceDialog({ open, onClose }: DialogProps) {
  const { t } = useTranslation();
  const [deviceName, setDeviceName] = useState("");
  const [isValidInput, setIsValidInput] = useState(true);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    const isValid = inputValue.length > 0;
    setDeviceName(inputValue);
    setIsValidInput(isValid);
  };
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle style={{ textTransform: "uppercase" }}>
        {t("title_dialog_rename")}
      </DialogTitle>
      <DialogContent>
        <p>{t("dialog_prompt_chooseDeviceName")}</p>
      </DialogContent>
      <TextField
        autoFocus
        label="New device name"
        margin="dense"
        color={isValidInput ? "primary" : "error"}
        value={deviceName}
        onChange={handleInputChange}
        style={{ marginRight: 10, marginLeft: 10 }}
      />
      {!isValidInput && (
        <p className="invalid-input">
          {t("dialog_error_deviceNameCannotBeEmpty")}
        </p>
      )}
      <DialogActions>
        <Button onClick={onClose} color="primary" variant="outlined">
          {t("button_cancel")}
        </Button>
        <Button onClick={onClose} color="primary" variant="outlined">
          {t("button_confirm")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const RecalibrateAlertDialog: React.FC<RecalibrateDialogProps> = ({
  open,
  onClose,
  onConfirm,
}) => {
  const { t } = useTranslation();
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle style={{ textTransform: "uppercase" }}>
        {t("title_dialog_confirm")}
      </DialogTitle>
      <DialogContent>
        <p>{t("dialog_confirm_recalibrate")}</p>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" variant="outlined">
          {t("button_no")}
        </Button>
        <Button
          onClick={() => {
            onConfirm();
            onClose();
          }}
          color="primary"
          variant="outlined"
        >
          {t("button_yes")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

function SwitchTypeDialog({ open, onClose }: DialogProps) {
  const { t } = useTranslation();
  const [selectedSwitchType, setSelectedSwitchType] = useState("");
  const handleSwitchTypeSelect = (switchType: any) => {
    setSelectedSwitchType(switchType);
  };

  const handleSave = () => {
    onClose();
  };
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle style={{ textTransform: "uppercase" }}>
        {t("dialog_prompt_switchTypeSelect")}
      </DialogTitle>
      <DialogContent>
        <p
          className={`dialog-content-option ${
            selectedSwitchType === "Regular" ? "selected" : ""
          }`}
          onClick={() => handleSwitchTypeSelect("Regular")}
        >
          {t("option_switchType_normalSwitch")}
        </p>
        <p
          className={`dialog-content-option ${
            selectedSwitchType === "Push button" ? "selected" : ""
          }`}
          onClick={() => handleSwitchTypeSelect("Push button")}
        >
          {t("option_switchType_pushButton")}
        </p>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" variant="outlined">
          {t("button_cancel")}
        </Button>
        <Button onClick={onClose} color="primary" variant="outlined">
          {t("button_confirm")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function InclinationTimeDialog({ open, onClose }: DialogProps) {
  const { t } = useTranslation();
  const [inclinationTime, setInclinationTime] = useState("");
  const [isValidInput, setIsValidInput] = useState(true);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    const isValid = /^\d+$/.test(inputValue);
    setInclinationTime(inputValue);
    setIsValidInput(isValid);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle style={{ textTransform: "uppercase" }}>
        {t("title_dialog_inclinationTime")}
      </DialogTitle>
      <DialogContent>
        <p>{t("dialog_prompt_inputInclinationTime")}</p>
      </DialogContent>
      <TextField
        autoFocus
        label="Inclination time"
        margin="dense"
        color={isValidInput ? "primary" : "error"}
        value={inclinationTime}
        onChange={handleInputChange}
        style={{ marginRight: 10, marginLeft: 10 }}
      />
      {!isValidInput && <p className="invalid-input">Invalid input</p>}
      <DialogActions>
        <Button onClick={onClose} color="primary" variant="outlined">
          {t("button_cancel")}
        </Button>
        <Button onClick={onClose} color="primary" variant="outlined">
          {t("button_confirm")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
interface InclinationTypeDialogProps extends DialogProps {
  onSelect: (inclinationType: string) => void;
}
function InclinationTypeDialog({
  open,
  onClose,
  onSelect,
}: InclinationTypeDialogProps) {
  const { t } = useTranslation();
  const [selectedInclinationType, setSelectedInclinationType] = useState("");
  const handleInclinationTypeSelect = (inclinationType: any) => {
    onSelect(inclinationType);
  };

  const handleSave = () => {
    onClose();
  };
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle style={{ textTransform: "uppercase" }}>
        {t("dialog_prompt_inclinationType")}
      </DialogTitle>
      <DialogContent>
        <p
          className={`dialog-content-option ${
            selectedInclinationType === "Lower 90°" ? "selected" : ""
          }`}
          onClick={() => handleInclinationTypeSelect("Lower 90°")}
        >
          {t("option_inclinationType_lower90")}
        </p>
        <p
          className={`dialog-content-option ${
            selectedInclinationType === "Upper 90°" ? "selected" : ""
          }`}
          onClick={() => handleInclinationTypeSelect("Upper 90°")}
        >
          {t("option_inclinationType_upper90")}
        </p>
        <p
          className={`dialog-content-option ${
            selectedInclinationType === "Full 180°" ? "selected" : ""
          }`}
          onClick={() => handleInclinationTypeSelect("Full 180°")}
        >
          {t("option_inclinationType_full180")}
        </p>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" variant="outlined">
          {t("button_cancel")}
        </Button>
        <Button onClick={onClose} color="primary" variant="outlined">
          {t("button_confirm")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function RestartAlertDialog({ open, onClose }: DialogProps) {
  const { t } = useTranslation();
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle style={{ textTransform: "uppercase" }}>
        {t("title_dialog_confirm")}
      </DialogTitle>
      <DialogContent>
        <p>{t("dialog_confirm_restart")}</p>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" variant="outlined">
          {t("button_no")}
        </Button>
        <Button onClick={onClose} color="primary" variant="outlined">
          {t("button_yes")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function DeleteDeviceAlertDialog({ open, onClose }: DialogProps) {
  const { t } = useTranslation();
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle style={{ textTransform: "uppercase" }}>
        {t("title_dialog_confirm")}
      </DialogTitle>
      <DialogContent>
        <p>{t("dialog_confirm_deleteDevice")}</p>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" variant="outlined">
          {t("button_no")}
        </Button>
        <Button onClick={onClose} color="primary" variant="outlined">
          {t("button_yes")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
