import { DeviceState, DeviceType } from "../api/DeviceApi";

export enum AdcWebsocketMessageSubject {
  AUTH_LOGIN = "auth.login",
  AUTH_ACCEPTED = "auth.accepted",
  AUTH_EXPIRED = "auth.expired",
  AUTH_REJECTED = "auth.rejected",
  SCENARIO_DELETED = "scenario.deleted",
  SCENARIO_STATUS_UPDATED = "scenario.status_updated",
  SCENAIRO_UPDATED = "scenario.updated",
  SCENARIO_CREATED = "scenario.created",
  GROUPS_UPDATED = "groups.updated",
  DEVICE_STATE_UPDATE = "device.state_update",
  DEVICE_DELETED = "device.deleted",
  DEVICE_UPDATED = "device.updated",
  DEVICE_CREATED = "device.created",
}

export type AdcWebsocketMessage = {
  subject: string;
  data?: any;
};

export type AuthLoginMessage = {
  subject: AdcWebsocketMessageSubject.AUTH_LOGIN;
  data: { token: string };
};
export type AuthAcceptedMessage = {
  subject: AdcWebsocketMessageSubject.AUTH_ACCEPTED;
  data: { expiresAt: Date };
};
export type AuthRejectedMessage = {
  subject: AdcWebsocketMessageSubject.AUTH_REJECTED;
};
export type AuthExpiredMessage = {
  subject: AdcWebsocketMessageSubject.AUTH_EXPIRED;
};
export type DeviceCreatedMessage = {
  subject: AdcWebsocketMessageSubject.DEVICE_CREATED;
  data: {
    deviceId: string;
    name: string;
    type: DeviceType | string;
    homeId: string;
  };
};
// type, name, or home id
export type DeviceUpdatedMessage = {
  subject: AdcWebsocketMessageSubject.DEVICE_UPDATED;
  data: {
    deviceId: string;
    name?: string;
    homeId?: string;
  };
};
export type DeviceDeletedMessage = {
  subject: AdcWebsocketMessageSubject.DEVICE_DELETED;
  data: {
    deviceId: string;
  };
};
export type DeviceStateUpdateMessage = {
  subject: AdcWebsocketMessageSubject.DEVICE_STATE_UPDATE;
  data: {
    deviceId: string;
    state: DeviceState & { totalRestarts: number; totalStateUpdates: number };
  };
};
export type GroupsUpdatedMessage = {
  subject: AdcWebsocketMessageSubject.GROUPS_UPDATED;
  data: {
    groups: [
      {
        activeDeviceTokens: string[];
        id: string;
        name: string;
      },
    ];
  };
};
export type ScenarioCreatedMessage = {
  subject: AdcWebsocketMessageSubject.SCENARIO_CREATED;
  data: {
    scenario: {
      enabled: boolean;
      id: string;
    };
  };
};
export type ScenarioUpdatedMessage = {
  subject: AdcWebsocketMessageSubject.SCENARIO_STATUS_UPDATED;
  data: {
    scenario: {
      enabled: boolean;
      id: string;
    };
  };
};
export type ScenarioStatusUpdatedMessage = {
  subject: AdcWebsocketMessageSubject.SCENARIO_STATUS_UPDATED;
  data: {
    scenario: {
      enabled: boolean;
      id: string;
      triggers: { type: string; matched?: boolean; value?: number }[];
    };
  };
};
export type ScenarioDeletedMessage = {
  subject: AdcWebsocketMessageSubject.SCENARIO_DELETED;
  data: {
    scenarioId: string;
  };
};

export function isAdcWebsocketMessage(
  message: unknown,
): message is AdcWebsocketMessage {
  const assumedMessage = message as AdcWebsocketMessage;
  return (
    typeof message === "object" &&
    assumedMessage.subject !== undefined &&
    typeof assumedMessage.subject === "string"
  );
}

export function isDeviceStateUpdateMessage(
  message: unknown,
): message is DeviceStateUpdateMessage {
  if (!isAdcWebsocketMessage(message)) {
    return false;
  }

  if (message.subject !== AdcWebsocketMessageSubject.DEVICE_STATE_UPDATE) {
    return false;
  }

  if (typeof message.data !== "object") {
    return false;
  }

  const assumedMessage = message as DeviceStateUpdateMessage;

  return (
    assumedMessage.data.deviceId !== undefined &&
    typeof assumedMessage.data.deviceId === "string" &&
    assumedMessage.data.state.status !== undefined &&
    typeof assumedMessage.data.state.status === "string"
  );
}

export function isAuthAcceptedMessage(
  message: unknown,
): message is AuthAcceptedMessage {
  if (!isAdcWebsocketMessage(message)) {
    return false;
  }

  if (message.subject !== AdcWebsocketMessageSubject.AUTH_ACCEPTED) {
    return false;
  }

  if (typeof message.data !== "object") {
    return false;
  }

  const assumedMessage = message as AuthAcceptedMessage;

  return (
    assumedMessage.data.expiresAt !== undefined &&
    assumedMessage.data.expiresAt instanceof Date
  );
}

export function isAuthRejectedMessage(
  message: unknown,
): message is AuthRejectedMessage {
  if (!isAdcWebsocketMessage(message)) {
    return false;
  }

  if (message.subject !== AdcWebsocketMessageSubject.AUTH_REJECTED) {
    return false;
  }

  if (typeof message.data !== "object") {
    return false;
  }

  return true;
}
