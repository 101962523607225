import { RequestOptions, apiRequest } from "./ApiRequest";

/**
 * @description Get a dictionary of all the device id to name pairs available to the user's account.
 * @param deviceLinkToken If specified fetches only the devices visible to this device link
 */
export async function getDeviceList(
  deviceLinkToken?: string,
): Promise<Record<string, string>> {
  let options: RequestOptions = {
    usesApiToken: true,
  };
  if (deviceLinkToken) {
    options = { deviceLinkToken };
  }
  return await apiRequest<Record<string, string>>(
    {
      method: "GET",
      endpoint: "/api/v1/device/list",
      query: { index: "token" },
    },
    options,
  );
}

export type LegacyDeviceType =
  | "Roleta"
  | "Žaluzija"
  | "Komarnik"
  | "Screen"
  | "Tenda";
export type DeviceType =
  | "home_door"
  | "light"
  | "roller_shutter"
  | "venetian_blind"
  | "textile_screen"
  | "insect_screen"
  | "awning"
  | "pergola"
  | "falcon"
  | LegacyDeviceType;
export type Permission = "view" | "control" | "maintain";
export type DeviceSpecifier = {
  light1: boolean;
  light2: boolean;
  handleLight: boolean;
  doorLight: boolean;
};
export type DeviceSettings = {
  light1Name: string;
  light2Name: string;
};
export type LegacyDeviceStatus =
  | "READY"
  | "BUSY_SWITCH"
  | "WAIT_FINISH"
  | "BUSY"
  | "CALIBRATE"
  | "CALIBRATE_FINISH"
  | "SELF_TEST"
  | "SELF_TEST_FINISH"
  | "ERROR";
export type DeviceStatus =
  | "offline"
  | "ready"
  | "moving"
  | "calibrating"
  | "self_testing"
  | "disabled"
  | "firmware_updating";
export type LightTarget = "none" | "light_1" | "light_2";

export type DeviceState = {
  status: DeviceStatus | LegacyDeviceStatus;
  // Window shade properties
  position?: number;
  inclination?: number;
  totalMoves?: number;
  /**
   * @description The total amount of time the device has spent moving (in milliseconds).
   */
  totalMoveTime?: number;
  // Door properties
  motorLockConnected?: boolean;
  motorLockLocked?: boolean;
  magnetLockConnected?: boolean;
  magnetLockLocked?: boolean;
  magneticSensorConnected?: boolean;
  magneticSensorOpen?: boolean;
  magicFoilConnected?: boolean;
  magicFoilOpacity?: number;
  lockMagneticSensorConnected?: boolean;
  lockMagneticSensorOpen?: boolean;
  doorLightConnected?: boolean;
  doorLightBrightness?: number;
  handleLightConnected?: boolean;
  handleLightBrightness?: number;
  // Light properteis
  light1Connected?: boolean;
  light2Connected?: boolean;
  light1Brightness?: number;
  light2Brightness?: number;
  button1Connected?: boolean;
  button2Connected?: boolean;
  button1Target?: LightTarget;
  button2Target?: LightTarget;
  button1Pressed?: boolean;
  button2Pressed?: boolean;
};

export type DeviceInfo = {
  version: string;
  owner: boolean;
  granted: boolean;
  grantedBy?: string;
  grantedPermissions?: Permission[];
  grantedDeviceSpecifiers?: DeviceSpecifier;
  settings?: DeviceSettings;
  customerId?: string;
  customerName?: string;
  customerSlug?: string;
  connected: boolean;
  initialized: true; // Devices that are not initialized don't get returned on info endpoint
  devicetype: DeviceType;
  homeId?: string;
} & DeviceState;

export type CompleteDeviceInfo = DeviceInfo & { name: string; id: string };

/**
 * @description Fetches device info for multiple devices
 * @param deviceLinkToken If specified fetches info only for the devices visible to this device link
 */
export async function getDeviceInfos(
  deviceIds: string[],
  deviceLinkToken?: string,
): Promise<Record<string, DeviceInfo>> {
  return await getDeviceInfosParallel(deviceIds, deviceLinkToken);
}

/**
 * @description Fetches device infos one batch after the other
 * @param deviceLinkToken If specified fetches info only for the devices visible to this device link
 */
async function getDeviceInfosSynchronous(
  deviceIds: string[],
  deviceLinkToken?: string,
): Promise<Record<string, DeviceInfo>> {
  let options: RequestOptions = {
    usesApiToken: true,
  };
  if (deviceLinkToken) {
    options = { deviceLinkToken };
  }
  const maxBatchSize = 100;
  let deviceInfoMap = {};
  // Urls can get too long to process, so we limit the maximum amount of devices we send at once
  for (let i = 0; i <= deviceIds.length / maxBatchSize; ++i) {
    const partialInfos = await apiRequest<Record<string, DeviceInfo>>(
      {
        method: "GET",
        endpoint: "/api/v1/device/info",
        query: {
          devicetoken: deviceIds.slice(
            i * maxBatchSize,
            (i + 1) * maxBatchSize,
          ),
        },
      },
      options,
    );
    deviceInfoMap = { ...deviceInfoMap, ...partialInfos };
  }
  return deviceInfoMap;
}

/**
 * @description Fetches all devices info batches in parallel
 * @param deviceLinkToken If specified fetches info only for the devices visible to this device link
 */
async function getDeviceInfosParallel(
  deviceIds: string[],
  deviceLinkToken?: string,
): Promise<Record<string, DeviceInfo>> {
  let options: RequestOptions = {
    usesApiToken: true,
  };
  if (deviceLinkToken) {
    options = { deviceLinkToken };
  }
  const maxBatchSize = 100;
  let deviceInfos: Promise<Record<string, DeviceInfo>>[] = [];
  // Urls can get too long to process, so we limit the maximum amount of devices we send at once
  for (let i = 0; i <= deviceIds.length / maxBatchSize; ++i) {
    deviceInfos.push(
      apiRequest<Record<string, DeviceInfo>>(
        {
          method: "GET",
          endpoint: "/api/v1/device/info",
          query: {
            devicetoken: deviceIds.slice(
              i * maxBatchSize,
              (i + 1) * maxBatchSize,
            ),
          },
        },
        options,
      ),
    );
  }
  const result = await Promise.all(deviceInfos);
  return result.reduce(
    (prev, current, index, arr) => (prev = { ...prev, ...current }),
  );
}

/**
 * @description Fetches device information for one device
 * @param deviceId The id of the device whose info should be fetched
 * @param deviceLinkToken If specified fetches info only for the devices visible to this device link
 */
export async function getDeviceInfo(
  deviceId: string,
  deviceLinkToken?: string,
): Promise<DeviceInfo> {
  const infoMap = await getDeviceInfos([deviceId], deviceLinkToken);

  return infoMap[deviceId];
}

/**
 * @description Fetches the full device list including the device info for each device
 * @param deviceLinkToken If specified fetches info only for the devices visible to this device link
 */
export async function getFullDeviceList(
  deviceLinkToken?: string,
): Promise<CompleteDeviceInfo[]> {
  const deviceList = await getDeviceList(deviceLinkToken);
  const deviceInfos = await getDeviceInfos(
    Object.keys(deviceList),
    deviceLinkToken,
  );
  const finalDeviceList: CompleteDeviceInfo[] = [];
  for (const [id, info] of Object.entries(deviceInfos)) {
    finalDeviceList.push({ ...info, id, name: deviceList[id] });
  }

  return finalDeviceList;
}

export interface ChangePositionAction {
  command: "move";
  position?: number;
  inclination?: number;
}

export interface MoveToVentilationAction {
  command: "move_to_ventilation";
}

export interface UnlockLockAction {
  command: "unlock";
  lockType: "motor" | "magnet";
  unlockDuration?: number;
}

export interface LightApplyAction {
  command: "light_apply";
  lightType: "light_1" | "light_2" | "door" | "handle";
  brightness: number;
}

export interface MagicFoilApplyAction {
  command: "magic_foil_apply";
  opacity: number;
}

export type DeviceAction =
  | ChangePositionAction
  | MoveToVentilationAction
  | UnlockLockAction
  | LightApplyAction
  | MagicFoilApplyAction;

export interface ActionResult {
  status:
    | "SUCCESS"
    | "PENDING"
    | "ERROR"
    | "success"
    | "pending"
    | "error"
    | string;
  device_ids: string[];
  error?:
    | "device_unreachable"
    | "device_busy"
    | "device_already_in_state"
    | "device_unsupported_action"
    | "device_not_responding"
    | "weather_scenario_matched"
    | string;
  error_description?: string;
  error_detail?: string;
  position?: number;
  inclination?: number;
}

function groupActionResults(actionResults: ActionResult[]): ActionResult[] {
  let finalResults: ActionResult[] = [];
  for (const result of actionResults) {
    let grouppedResult = finalResults.find(
      (val) =>
        (result.status !== "error" && val.status === result.status) ||
        (result.status === "error" && val.error === result.error),
    );
    if (grouppedResult === undefined) {
      finalResults.push(result);
      continue;
    }

    grouppedResult.device_ids.push(...result.device_ids);
  }
  return finalResults;
}

/**
 * @description Performs an action on a set of devices
 * @param action The action to perform
 * @param deviceIds The ids of the devices on which to peform this action
 * @param wait If true the function will return after the action has been executed. Note that some actions such as turning lights on or unlocking doors cannot be awaited as they are instant.
 * @param deviceLinkToken If specified affects only the devices visible to this device link
 */
export async function performAction(
  deviceIds: string[],
  action: DeviceAction,
  wait: boolean = false,
  deviceLinkToken?: string,
): Promise<ActionResult[]> {
  const commands: {
    token: string;
    command: DeviceAction["command"];
    percentage?: number;
    inclination?: number;
    lockType?: UnlockLockAction["lockType"];
    unlockDuration?: number;
    lightType?: LightApplyAction["lightType"];
    brightness?: number;
    opacity?: number;
  }[] = [];
  let baseCommand: Omit<(typeof commands)[0], "token"> = {
    command: action.command,
  };
  switch (action.command) {
    case "move":
      baseCommand = {
        ...baseCommand,
        percentage: action.position,
        inclination: action.inclination,
      };
      break;
    case "move_to_ventilation":
      baseCommand = {
        ...baseCommand,
      };
      break;
    case "unlock":
      baseCommand = {
        ...baseCommand,
        lockType: action.lockType,
        unlockDuration: action.unlockDuration,
      };
      break;
    case "light_apply":
      baseCommand = {
        ...baseCommand,
        lightType: action.lightType,
        brightness: action.brightness,
      };
      break;
    case "magic_foil_apply":
      baseCommand = {
        ...baseCommand,
        opacity: action.opacity,
      };
      break;
  }
  for (const deviceId of deviceIds) {
    commands.push({
      token: deviceId,
      ...baseCommand,
    });
  }

  let options: RequestOptions = { usesApiToken: true };
  if (deviceLinkToken) {
    options = { deviceLinkToken };
  }

  const results = await apiRequest<ActionResult[]>(
    {
      method: "POST",
      endpoint: "/api/v1/device/move",
      contentType: "application/json",
      body: {
        commands,
        wait,
      },
    },
    options,
  );

  // Results are not batched, need to batch them ourselves
  return groupActionResults(results);
}

/**
 * @description Stops an ongoing action on a device.
 * @param deviceIds The ids of the devices on which all actions should be stopped.
 * @param deviceLinkToken If specified affects only the devices visible to this device link
 */
export async function abortAction(
  deviceIds: string[],
  deviceLinkToken?: string,
): Promise<ActionResult[]> {
  const commands: { token: string }[] = [];

  for (const deviceId of deviceIds) {
    commands.push({ token: deviceId });
  }

  let options: RequestOptions = { usesApiToken: true };
  if (deviceLinkToken) {
    options = { deviceLinkToken };
  }

  const results = await apiRequest<ActionResult[]>(
    {
      method: "POST",
      endpoint: "/api/v1/device/abort",
      body: {
        commands,
      },
      contentType: "application/json",
    },
    options,
  );

  // Results are not batched, need to batch them ourselves
  return groupActionResults(results);
}

export async function deleteDevice(
  token: string,
  force: boolean = false,
): Promise<void> {
  return await apiRequest(
    {
      method: "DELETE",
      endpoint: "/api/v1/device/delete",
      query: { devicetoken: token, force },
    },
    { usesApiToken: true },
  );
}
