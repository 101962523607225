import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { AccountSlice } from "./AccountSlice";
import {
  localStorageAccountKey,
  localStorageMiddleware,
} from "./localStorageMiddleware";
import { DeviceSlice } from "./DeviceSlice";
import { adcWebsocketMiddleware } from "./adcWebsocketMiddleware";

const initialAccountState = JSON.parse(
  localStorage.getItem(localStorageAccountKey) || "null",
);

// Done like this so that RootState can be set to typeof rootReducer, and a circular dependency on the middleware can be avoided
const rootReducer = combineReducers({
  account: AccountSlice.reducer,
  device: DeviceSlice.reducer,
});

export const store = configureStore({
  preloadedState: {
    account: initialAccountState === null ? undefined : initialAccountState,
    device: DeviceSlice.getInitialState(),
  },
  reducer: rootReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .prepend(localStorageMiddleware.middleware)
      .prepend(adcWebsocketMiddleware()),
});

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
