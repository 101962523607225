import React, { useEffect, useState } from "react";
import logoImage from "../assets/adc_home_white.png";
import { useHistory } from "react-router-dom";
import { login } from "../adcApi";
import Button from "@mui/material/Button";
import { Alert, Snackbar, TextField } from "@mui/material";
import LoginIcon from "@mui/icons-material/Login";
import KeyOutlinedIcon from "@mui/icons-material/KeyOutlined";
import { loginAsync, loginFederatedAsync } from "../app/AccountSlice";
import { useAppDispatch } from "../app/hooks";
import { useSelector } from "react-redux";
import { RootState } from "../app/store";
import LoadingIndicator from "../components/LoadingIndicator";
import { useTranslation } from "react-i18next";
import GoogleSignInButton from "../components/GoogleSignInButton";
import AppleSignInButton from "../components/SignInWithApple";
import { CredentialResponse, GoogleLogin } from "@react-oauth/google";

export const LoginPage = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useHistory();
  const isLoggedIn = useSelector(
    (state: RootState) => state.account.isLoggedIn,
  );

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isBusy, setIsBusy] = useState(false);
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [invalidPassword, setInvalidPassword] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleEmailChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setEmail(e.target.value);
    setInvalidEmail(false);
  };

  const handlePasswordChange: React.ChangeEventHandler<HTMLInputElement> = (
    e,
  ) => {
    setPassword(e.target.value);
    setInvalidPassword(false);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();

    if (!emailIsValid(email)) {
      setInvalidEmail(true);
      return;
    }

    if (!passwordIsValid(password)) {
      setInvalidPassword(true);
      return;
    }

    setIsBusy(true);
    dispatch(loginAsync({ email, pass: password }))
      .unwrap()
      .then((result) => {
        console.log("login finished successfully");
        //navigate.push("/home");
      })
      .catch((error) => {
        console.error(error);
        if (error.key === "password") {
          setErrorMessage(t("dialog_alert_incorrectPassword"));
        } else if (error.key === "login") {
          setErrorMessage(t("dialog_alert_noAccountWithEmailFound"));
        }
        setSnackbarOpen(true);
      })
      .finally(() => {
        setIsBusy(false);
      });
  };

  const handleGoogleSignIn = (credentials: CredentialResponse) => {
    setIsBusy(true);
    if (credentials.credential === undefined) {
      setIsBusy(false);
      setErrorMessage(t("dialog_error_generic"));
      setSnackbarOpen(true);
      return;
    }
    dispatch(loginFederatedAsync({ idToken: credentials.credential }))
      .unwrap()
      .then((result) => {
        console.log("login finished successfully");
        //navigate.push("/home");
      })
      .catch((error) => {
        console.error(error);
        if (error.key === "password") {
          setErrorMessage(t("dialog_alert_incorrectPassword"));
        } else if (error.key === "login") {
          setErrorMessage(t("dialog_alert_noAccountWithEmailFound"));
        }
        setSnackbarOpen(true);
      })
      .finally(() => {
        setIsBusy(false);
      });
  };

  //TODO: complete email and password validation
  const emailIsValid = (email: string) => {
    return email.length > 0;
  };
  const passwordIsValid = (password: string) => {
    return password.length >= 8;
  };

  useEffect(() => {
    if (isLoggedIn) {
      navigate.push("/home");
    }
  }, [isLoggedIn]);

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <>
      <div className="form-background">
        <div className="form">
          <LogoImage />
          {isBusy === true && <LoadingIndicator />}
          <TextField
            variant="outlined"
            label={t("label_email")}
            className="form-input"
            value={email}
            onChange={handleEmailChange}
            type="text"
            name="name"
            margin="normal"
            color={invalidEmail ? "error" : "primary"}
          />

          <TextField
            variant="outlined"
            label={t("label_password")}
            className="form-input"
            value={password}
            onChange={handlePasswordChange}
            type="password"
            name="name"
            margin="normal"
            color={invalidPassword ? "error" : "primary"}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleSubmit(e);
              }
            }}
          />

          {invalidEmail && (
            <div className="invalid-alert">{t("validation_emailInvalid")}</div>
          )}
          {!invalidEmail && invalidPassword && (
            <div className="invalid-alert">
              {t("validation_passwordLength")}
            </div>
          )}

          <div
            className="forgotten-password"
            onClick={() => navigate.push("/forgottenpassword")}
          >
            {t("button_forgottenPassword")}
          </div>
          <Button
            className="form-button"
            type="submit"
            variant="contained"
            size="large"
            startIcon={<LoginIcon />}
            onClick={handleSubmit}
          >
            {t("button_logIn")}
          </Button>
          <Button
            style={{ marginTop: 10 }}
            className="form-button"
            type="submit"
            variant="contained"
            size="large"
            startIcon={<KeyOutlinedIcon />}
            onClick={() => navigate.push("/register")}
          >
            {t("title_register")}
          </Button>
          <div className="sign-in-with-text">Sign in with:</div>
          <div className="sign-in-buttons">
            <GoogleLogin
              onSuccess={handleGoogleSignIn}
              onError={() => {
                setErrorMessage(t("dialog_error_generic"));
                setSnackbarOpen(true);
              }}
            />
            {/*<AppleSignInButton />*/}
          </div>
          <Snackbar
            open={snackbarOpen}
            autoHideDuration={6000}
            onClose={handleSnackbarClose}
            message={errorMessage}
          >
            <Alert
              elevation={6}
              variant="filled"
              severity="error"
              onClose={handleSnackbarClose}
            >
              {errorMessage}
            </Alert>
          </Snackbar>
        </div>
      </div>
    </>
  );
};

function LogoImage() {
  return <img src={logoImage} className="form-logo" alt="adc-logo"></img>;
}
