import React, { useEffect, useState } from "react";
import { deviceInfo } from "../adcApi";
import Layout from "../components/Layout";
import LightOn from "../assets/icon_light_on_bold.svg";
import LightOff from "../assets/icon_light_off_bold.svg";
import MagicFoilOn from "../assets/icon_magic_foil_on_bold.svg";
import MagicFoilOff from "../assets/icon_magic_foil_off_bold.svg";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import LockLocked from "../assets/icon_lock_locked.svg";
import LockUnlocked from "../assets/icon_lock_unlocked.svg";
import DoorOpen from "../assets/door-open.svg";
import LightOffNavIcon from "../assets/icon_light_off.svg";
import MagicFoilNavIcon from "../assets/icon_magic_foil_off.svg";
import LockMagnetNavIcon from "../assets/icon_lock_magnet_unlocked_white.svg";
import LockMotorNavIcon from "../assets/icon_lock_motor_unlocked_white.svg";
import Video from "../assets/videos/clear_sky.mp4";
import BackIcon from "../assets/icon_back.svg";
import gearIcon from "../assets/icon_gear.svg";
import { useLocation, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { t } from "i18next";

type DeviceInfo = {
  name: string;
  token: string;
  devicetype: string;
  inclination: number;
  position: number;
  motorLockConnected: boolean;
  magnetLockConnected: boolean;
  magicFoilConnected: boolean;
  magicFoilOpacity: number;
  doorLightConnected: boolean;
  handleLightConnected: boolean;
  doorLightBrightness: number;
  handleLightBrightness: number;
  magneticSensorConnected: boolean;
  magneticSensorOpen: boolean;
};

type DeviceMenuItem = "Magnet" | "Motor" | "MagicFoil" | "Lights";

export const DoorDeviceInfoPage = () => {
  return (
    <>
      <Layout>
        <DeviceInfo />
      </Layout>
    </>
  );
};

function DeviceInfo() {
  const navigate = useHistory();
  const { t } = useTranslation();
  const location = useLocation();
  const state = location.state as DeviceInfo;
  const {
    name,
    magnetLockConnected,
    motorLockConnected,
    magicFoilConnected,
    magicFoilOpacity,
    handleLightConnected,
    doorLightConnected,
    handleLightBrightness,
    doorLightBrightness,
  } = state;
  const [activeMenuItem, setActiveMenuItem] = useState<DeviceMenuItem>(
    magnetLockConnected ? "Magnet" : "Motor",
  );
  console.log(state);

  const renderContent = () => {
    switch (activeMenuItem) {
      case "Magnet":
        return <MagnetContent />;
      case "Motor":
        return <MotorContent />;
      case "MagicFoil":
        return <MagicFoilContent magicFoilOpacity={magicFoilOpacity} />;
      case "Lights":
        return (
          <LightsContent
            doorLightConnected={doorLightConnected}
            handleLightConnected={handleLightConnected}
            doorLightBrightness={doorLightBrightness}
            handleLightBrightness={handleLightBrightness}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="device-list-container">
      <div className="App">
        <header className="App-body">
          <div className="rectangles-container">
            <div className="device-header-grid">
              <div>
                <img
                  src={BackIcon}
                  className="back-icon"
                  onClick={() => navigate.goBack()}
                />
              </div>
              <div>{name}</div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  textAlign: "center",
                  paddingRight: "20px",
                  cursor: "pointer",
                }}
                onClick={() => navigate.push("/doorsettingspage")}
              >
                <img src={gearIcon} className="back-icon"></img>
                <span>{t("button_settings")}</span>
              </div>
            </div>
            <div className="device-menu-grid">
              {magnetLockConnected && (
                <div
                  className={`${
                    activeMenuItem === "Magnet"
                      ? "device-menu-item active-menu-item"
                      : "device-menu-item"
                  }`}
                  onClick={() => setActiveMenuItem("Magnet")}
                >
                  <img
                    src={LockMagnetNavIcon}
                    style={{ height: 35 }}
                    alt="Magnet"
                  />
                  <span>{t("tab_magnetLock")}</span>
                </div>
              )}
              {motorLockConnected && (
                <div
                  className={`${
                    activeMenuItem === "Motor"
                      ? "device-menu-item active-menu-item"
                      : "device-menu-item"
                  }`}
                  onClick={() => setActiveMenuItem("Motor")}
                >
                  <img
                    src={LockMotorNavIcon}
                    style={{ height: 35 }}
                    alt="Motor"
                  />
                  <span>{t("tab_motorLock")}</span>
                </div>
              )}
              {magicFoilConnected && (
                <div
                  className={`${
                    activeMenuItem === "MagicFoil"
                      ? "device-menu-item active-menu-item"
                      : "device-menu-item"
                  }`}
                  onClick={() => setActiveMenuItem("MagicFoil")}
                >
                  <img
                    src={MagicFoilNavIcon}
                    style={{ height: 35 }}
                    alt="Magic Foil"
                  />
                  <span>{t("tab_magicFoil")}</span>
                </div>
              )}
              {(handleLightConnected || doorLightConnected) && (
                <div
                  className={`${
                    activeMenuItem === "Lights"
                      ? "device-menu-item active-menu-item"
                      : "device-menu-item"
                  }`}
                  onClick={() => setActiveMenuItem("Lights")}
                >
                  <img
                    src={LightOffNavIcon}
                    style={{ height: 35 }}
                    alt="Lights"
                  />
                  <span>{t("tab_lights")}</span>
                </div>
              )}
            </div>
          </div>
          <div className="device-content">
            {renderContent()}
            <video
              className="background-video"
              src={Video}
              loop
              autoPlay
              style={{ zIndex: 0, overflow: "hidden" }}
            ></video>
          </div>
        </header>
      </div>
    </div>
  );
}

const MagnetContent: React.FC = () => {
  const [locked, setLocked] = useState(true);

  const handleClick = () => {
    setLocked(false);
    setTimeout(() => {
      setLocked(true);
    }, 5000);
  };

  return (
    <div>
      <div className="door-device-content">
        <div className="circle circle1"></div>
        <div className="circle circle2"></div>
        <div className="circle circle3" onClick={handleClick}>
          <img
            src={locked ? LockLocked : LockUnlocked}
            className="lock-image"
            alt="Lock"
          ></img>
        </div>
      </div>

      <div
        className="small-button-door-left"
        style={{ flexDirection: "column", alignItems: "center" }}
      >
        <img
          src={DoorOpen}
          style={{ height: 35, width: 35 }}
          alt="DoorOpen"
        ></img>
        <div style={{ fontSize: 10, marginTop: 5 }}>ODPRTO</div>
      </div>
    </div>
  );
};

const MotorContent: React.FC = () => {
  const [locked, setLocked] = useState(true);

  const handleClick = () => {
    setLocked(false);
    setTimeout(() => {
      setLocked(true);
    }, 5000); //5 sekund
  };

  return (
    <div>
      <div className="door-device-content">
        <div className="circle circle1"></div>
        <div className="circle circle2"></div>
        <div className="circle circle3" onClick={handleClick}>
          <img
            src={locked ? LockLocked : LockUnlocked}
            className="lock-image"
            alt="Lock"
          ></img>
        </div>
      </div>

      <div
        className="small-button-door-left"
        style={{ flexDirection: "column", alignItems: "center" }}
      >
        <img
          src={DoorOpen}
          style={{ height: 35, width: 35 }}
          alt="DoorOpen"
        ></img>
        <div style={{ fontSize: 10, marginTop: 5 }}>{t("label_open")}</div>
      </div>
    </div>
  );
};

const MagicFoilContent: React.FC<{ magicFoilOpacity: number }> = ({
  magicFoilOpacity,
}) => {
  const [magicFoilIcon, setMagicFoilIcon] = useState(() =>
    magicFoilOpacity === 1 ? MagicFoilOn : MagicFoilOff,
  );

  const handleCircleClick = () => {
    setMagicFoilIcon((prevIcon) =>
      prevIcon === MagicFoilOn ? MagicFoilOff : MagicFoilOn,
    );
  };

  return (
    <div>
      <div className="door-device-content">
        {magicFoilOpacity === 1 && <div className="overlay"></div>}
        <div className="circle circle1"></div>
        <div className="circle circle2"></div>
        <div className="circle circle3" onClick={handleCircleClick}>
          <img src={magicFoilIcon} className="lock-image"></img>
        </div>
      </div>
    </div>
  );
};

const LightsContent: React.FC<{
  doorLightConnected: boolean;
  handleLightConnected: boolean;
  doorLightBrightness: number;
  handleLightBrightness: number;
}> = ({
  doorLightConnected,
  handleLightConnected,
  doorLightBrightness,
  handleLightBrightness,
}) => {
  const { t } = useTranslation();
  const [doorLightIcon, setDoorLightIcon] = useState(() =>
    doorLightBrightness === 1 ? LightOn : LightOff,
  );

  const doorLightClick = () => {
    setDoorLightIcon((prevIcon) => (prevIcon === LightOn ? LightOff : LightOn));
  };

  const [handleLightIcon, setHandleLightIcon] = useState(() =>
    handleLightBrightness === 1 ? LightOn : LightOff,
  );

  const handleLightClick = () => {
    setHandleLightIcon((prevIcon) =>
      prevIcon === LightOn ? LightOff : LightOn,
    );
  };

  return (
    <div>
      <div className="door-device-container">
        {doorLightConnected && (
          <div className="door-light-container">
            <div className="door-light-banner">{t("label_doorLight")}</div>
            <div className="door-device-content">
              <div className="circle circle1-lights"></div>
              <div className="circle circle2-lights"></div>
              <div className="circle circle3-lights" onClick={doorLightClick}>
                <img src={doorLightIcon} className="lock-image"></img>
              </div>
            </div>
          </div>
        )}
        {handleLightConnected && (
          <div className="door-light-container">
            <div className="door-light-banner">{t("label_handleLight")}</div>
            <div className="door-device-content">
              <div className="circle circle1-lights"></div>
              <div className="circle circle2-lights"></div>
              <div className="circle circle3-lights" onClick={handleLightClick}>
                <img src={handleLightIcon} className="lock-image"></img>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
