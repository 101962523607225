import React, { CSSProperties, useEffect, useState } from "react";
import ArrowUpwardOutlinedIcon from "@mui/icons-material/ArrowUpwardOutlined";
import ArrowDownwardOutlinedIcon from "@mui/icons-material/ArrowDownwardOutlined";
import Video from "../assets/videos/clear_sky.mp4";
import BackIcon from "../assets/icon_back.svg";
import OpenIcon from "../assets/icon_all_up.svg";
import CloseIcon from "../assets/icon_all_down.svg";
import { useHistory } from "react-router-dom";
import { Loading } from "../components/Loading";
import { useTranslation } from "react-i18next";
import DeviceOfflineIndicator from "../components/DeviceOfflineIndicator";
import { ScreenCanvas } from "../components/devices/ScreenCanvas";
import { AwningCanvas } from "../components/devices/AwningCanvas";
import { VenetianBlindCanvas } from "../components/devices/VenetianBlindCanvas";
import { InsectScreenCanvas } from "../components/devices/InsectScreenCanvas";
import { RollerShutterCanvas } from "../components/devices/RollerShutterCanvas";
import {
  InclinationType,
  VenetianBlindSideViewCanvas,
} from "../components/devices/VenetianBlindSideViewCanvas";
import { FalconCanvas } from "../components/devices/FalconCanvas";
import { PergolaCanvas } from "../components/devices/PergolaCanvas";
import { DiamondProgressBarCanvas } from "../components/devices/DiamondProgressBarCanvas";
import { RootState } from "../app/store";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import {
  executeDeviceAction,
  Device,
  refreshDeviceLinkDevices,
  abortCurrentDeviceAction,
} from "../app/DeviceSlice";
import LightOn from "../assets/icon_light_on_bold.svg";
import LightOff from "../assets/icon_light_off_bold.svg";
import MagicFoilOn from "../assets/icon_magic_foil_on_bold.svg";
import MagicFoilOff from "../assets/icon_magic_foil_off_bold.svg";
import LockLocked from "../assets/icon_lock_locked.svg";
import LockUnlocked from "../assets/icon_lock_unlocked.svg";
import DoorOpen from "../assets/icon_door_open.svg";
import DoorClosed from "../assets/icon_door_closed.svg";
import LightOffNavIcon from "../assets/icon_light_off.svg";
import MagicFoilNavIcon from "../assets/icon_magic_foil_off.svg";
import LockMagnetNavIcon from "../assets/icon_lock_magnet_unlocked_white.svg";
import LockMotorNavIcon from "../assets/icon_lock_motor_unlocked_white.svg";
import { refreshWebsocketToken } from "../app/AccountSlice";
import { createSelector } from "@reduxjs/toolkit";
import { LoginApiErrorId, OtherApiErrorId, isApiError } from "../api/ApiError";
import { getErrorMessage } from "../app/DefaultErrorHandler";
import AlertDialog from "../components/AlertDialog";
import { ActionResult } from "../api/DeviceApi";
import { Button } from "@mui/material";
import { Circular3DButton } from "../components/Circular3DButton";
import "./DeviceLinkDetailPage.css";

const selectIsRefreshingDevices = (state: RootState) =>
  state.device.isRefreshingDevices;
const selectInitialDeviceRefreshDone = (state: RootState) =>
  state.device.initialDeviceRefreshDone;
const selectRefreshing = createSelector(
  [selectIsRefreshingDevices, selectInitialDeviceRefreshDone],
  (isRefreshingDevices, initialDeviceRefreshDone) => {
    return {
      isRefreshingDevices,
      initialDeviceRefreshDone,
    };
  },
);

export const DeviceLinkDetailPage = () => {
  const navigate = useHistory();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [deviceId, setDeviceId] = useState<string | undefined>(undefined);
  const [deviceLinkToken, setDeviceLinkToken] = useState<string | undefined>(
    undefined,
  );
  const [deviceSpecifier, setDeviceSpecifier] = useState<string | undefined>(
    undefined,
  );
  const deviceInfo = useAppSelector(
    (state: RootState) => state.device.devices[deviceId ?? ""],
  );
  const { isRefreshingDevices, initialDeviceRefreshDone } = useAppSelector(
    (state) => selectRefreshing(state),
  );
  const [isDialogVisible, setIsDialogVisible] = useState<boolean>(false);
  const [dialogTitle, setDialogTitle] = useState<string>("");
  const [dialogContent, setDialogContent] = useState<React.ReactNode>(null);

  const handleErrorDialogDismiss = () => {
    setIsDialogVisible(false);
  };

  const showError = (error: string) => {
    setDialogTitle(t("title_error"));
    setDialogContent(<p>{error}</p>);
    setIsDialogVisible(true);
  };

  const handleActionResults = (results: ActionResult[]) => {
    if (!results.some((result) => result.device_ids.includes(deviceInfo.id))) {
      return;
    }

    const result = results.find((result) =>
      result.device_ids.includes(deviceInfo.id),
    )!;

    if (result.status.toLowerCase() === "error") {
      showError(getErrorMessage(result));
    } else {
      console.log("Action succeeded:", result);
    }
  };

  const handleActionError = (error: unknown) => {
    console.error(error);
    if (isApiError(error)) {
      if (error.error === OtherApiErrorId.INSUFFICIENT_PERMISSION) {
        showError(t("deviceLink_expired"));
        return;
      }
    }

    showError(getErrorMessage(error));
  };

  useEffect(() => {
    if (navigate.location.state) {
      const navigationState = navigate.location.state as {
        id: string;
        deviceLinkToken: string;
        lightType?: "light_1" | "light_2";
      };

      setDeviceId(navigationState.id);
      setDeviceLinkToken(navigationState.deviceLinkToken);
      setDeviceSpecifier(navigationState.lightType);
    } else if (navigate.location.search) {
      const queryString = new URLSearchParams(navigate.location.search);
      let deviceLinkToken = queryString.get("token") ?? undefined;
      let deviceId = queryString.get("deviceId") ?? undefined;
      let lightType = queryString.get("deviceSpecifier") ?? undefined;

      setDeviceId(deviceId);
      setDeviceLinkToken(deviceLinkToken);
      setDeviceSpecifier(lightType);
    }
  }, []);

  useEffect(() => {
    if (deviceLinkToken === null || deviceLinkToken === undefined) {
      return;
    }

    dispatch(refreshDeviceLinkDevices({ deviceLinkToken }))
      .unwrap()
      .then(() => dispatch(refreshWebsocketToken({ deviceLinkToken })).unwrap())
      .catch((err) => {
        console.error(err);
        if (isApiError(err)) {
          if (
            err.error === LoginApiErrorId.API_TOKEN_INVALID ||
            err.error === LoginApiErrorId.API_TOKEN_MISSING
          ) {
            showError(t("deviceLink_notRecognized"));
            return;
          }
        }

        showError(getErrorMessage(err));
      });
  }, [deviceLinkToken]);

  let name = "";
  let lightType: "light_1" | "light_2" = "light_1";
  let deviceType = "";
  if (deviceInfo) {
    deviceType = deviceInfo.devicetype;
    name = deviceInfo.name;
    switch (deviceSpecifier) {
      case "light_1":
        name = deviceInfo.settings?.light1Name ?? deviceInfo.name;
        lightType = "light_1";
        break;
      case "light_2":
        name = deviceInfo.settings?.light2Name ?? deviceInfo.name;
        lightType = "light_2";
        break;
      default:
        break;
    }
  }

  return (
    <div className="App">
      <header className="App-body">
        <AlertDialog
          open={isDialogVisible}
          title={dialogTitle}
          content={dialogContent}
          onDismiss={handleErrorDialogDismiss}
        />
        <div className="device-header-grid" style={{ flexShrink: 0 }}>
          <div>
            <img
              src={BackIcon}
              alt="back"
              className="back-icon"
              onClick={() =>
                navigate.push({
                  pathname: `/device-link`,
                  search: `token=${deviceLinkToken}`,
                })
              }
            />
          </div>
          <div>{name}</div>
        </div>
        {isRefreshingDevices && !initialDeviceRefreshDone && (
          <div style={{ position: "relative", width: "100%", height: "100vh" }}>
            <Loading />
          </div>
        )}
        {deviceInfo &&
          deviceLinkToken &&
          [
            "roleta",
            "roller_shutter",
            "žaluzija",
            "venetian_blind",
            "komarnik",
            "insect_screen",
            "screen",
            "textile_screen",
            "tenda",
            "awning",
            "falcon",
            "pergola",
          ].some((type) => type === deviceType) && (
            <BlindLayout
              deviceInfo={deviceInfo}
              deviceLinkToken={deviceLinkToken}
              handleActionResults={handleActionResults}
              handleActionError={handleActionError}
            />
          )}
        {deviceInfo && deviceLinkToken && deviceType === "home_door" && (
          <DoorLayout
            deviceInfo={deviceInfo}
            deviceLinkToken={deviceLinkToken}
            handleActionResults={handleActionResults}
            handleActionError={handleActionError}
          />
        )}
        {deviceInfo && deviceLinkToken && deviceType === "light" && (
          <LightLayout
            deviceInfo={deviceInfo}
            deviceLinkToken={deviceLinkToken}
            lightType={lightType}
            handleActionResults={handleActionResults}
            handleActionError={handleActionError}
          />
        )}
      </header>
    </div>
  );
};

const BlindLayout = ({
  deviceInfo,
  deviceLinkToken,
  handleActionResults,
  handleActionError,
}: {
  deviceInfo: Device;
  deviceLinkToken: string;
  handleActionResults: (results: ActionResult[]) => void;
  handleActionError: (error: unknown) => void;
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const {
    id: deviceId,
    devicetype,
    connected,
    status,
    position,
    inclination,
  } = deviceInfo;
  const [percentage, setPercentage] = useState(0.3);
  const [isPercentageShown, setIsPercentageShown] = useState(false);
  const [canvasType, setCanvasType] = useState("default");

  // Function to handle the arrow button click
  const handleArrowButtonClick = () => {
    // Toggle between different canvases based on the current canvas state
    setCanvasType((prevCanvasType) =>
      prevCanvasType === "default" ? "alternative" : "default",
    );
  };

  return (
    <>
      <div className="device-menu-grid" style={{ flexShrink: 0 }}>
        <div
          onClick={(_event) => {
            dispatch(
              executeDeviceAction({
                deviceIds: [deviceId],
                action: {
                  command: "move",
                  position: 0.0,
                  inclination: 0.0,
                },
                deviceLinkToken: deviceLinkToken ?? undefined,
              }),
            )
              .unwrap()
              .then((results) => handleActionResults(results))
              .catch((err) => handleActionError(err));
          }}
        >
          <img alt="open" src={OpenIcon} style={{ height: 35 }}></img>
          <span>{t("button_open")}</span>
        </div>
        <div
          onClick={(_event) => {
            dispatch(
              executeDeviceAction({
                deviceIds: [deviceId],
                action: {
                  command: "move",
                  position: 1.0,
                  inclination: 1.0,
                },
                deviceLinkToken: deviceLinkToken ?? undefined,
              }),
            )
              .unwrap()
              .then((results) => handleActionResults(results))
              .catch((err) => handleActionError(err));
          }}
        >
          <img alt="close" src={CloseIcon} style={{ height: 35 }}></img>
          <span>{t("button_close")}</span>
        </div>
      </div>

      <div className="device-content-canvas">
        <video
          className="background-video"
          src={Video}
          loop
          autoPlay
          muted
          playsInline
          style={{
            zIndex: 0,
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
        />
        <div style={{ position: "relative", zIndex: 1, height: "100%" }}>
          {status !== "ready" && (
            <Loading
              style={{ position: "absolute", height: "100%" }}
              spinnerSize="200px"
              spinnerThickness="20px"
            />
          )}
          {status !== "ready" && status !== "offline" && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
                height: "100%",
              }}
            >
              <Button
                style={{
                  alignSelf: "center",
                  position: "absolute",
                  width: "200px",
                  height: "200px",
                  borderRadius: "100px",
                  fontSize: "2rem",
                  zIndex: 1001,
                }}
                onClick={(_event) => {
                  dispatch(
                    abortCurrentDeviceAction({
                      deviceIds: [deviceId],
                      deviceLinkToken: deviceLinkToken ?? undefined,
                    }),
                  )
                    .unwrap()
                    .then((results) => console.log(results))
                    .catch((err) => handleActionError(err));
                }}
              >
                <span>{t("button_stopDevice")}</span>
              </Button>
            </div>
          )}
          {connected !== true && <DeviceOfflineIndicator />}

          <BlindCanvas
            style={{ position: "absolute", top: 0, left: 0 }}
            position={position}
            rotation={inclination}
            devicetype={devicetype}
            canvasType={canvasType}
            onDragStart={() => {
              setIsPercentageShown(true);
            }}
            onDragFinish={() => setIsPercentageShown(false)}
            onStateChange={(args) => {
              if (args.position !== undefined) {
                setPercentage(args.position);
              } else if (args.rotation !== undefined) {
                setPercentage(args.rotation);
              }
            }}
            onMoveRequest={({ position, rotation }) => {
              dispatch(
                executeDeviceAction({
                  deviceIds: [deviceId],
                  action: {
                    command: "move",
                    position,
                    inclination: rotation,
                  },
                  deviceLinkToken: deviceLinkToken ?? undefined,
                }),
              )
                .unwrap()
                .then((results) => handleActionResults(results))
                .catch((err) => handleActionError(err));
            }}
          />
          {devicetype === "venetian_blind" && (
            <div className="small-square" onClick={handleArrowButtonClick}>
              {canvasType === "default" ? (
                <ArrowDownwardOutlinedIcon />
              ) : (
                <ArrowUpwardOutlinedIcon />
              )}
            </div>
          )}
          {isPercentageShown && (
            <DiamondProgressBarCanvas
              percentage={percentage}
              style={{
                position: "absolute",
                left: 0,
                top: 0,
                touchAction: "none",
                pointerEvents: "none",
              }}
            />
          )}
        </div>
      </div>
    </>
  );
};

function BlindCanvas({
  style,
  position,
  rotation,
  devicetype,
  canvasType,
  onDragStart,
  onDragFinish,
  onStateChange,
  onMoveRequest,
}: {
  style: CSSProperties | undefined;
  position?: number;
  rotation?: number;
  devicetype: string;
  canvasType: string;
  onDragStart?: () => void;
  onDragFinish?: () => void;
  onStateChange?: (args: { position?: number; rotation?: number }) => void;
  onMoveRequest?: (args: { position?: number; rotation?: number }) => void;
}) {
  let deviceCanvas: React.ReactElement | null = null;

  switch (devicetype.toLowerCase()) {
    case "roleta":
    case "roller_shutter":
      deviceCanvas = (
        <RollerShutterCanvas
          style={style}
          position={position}
          onDragStart={onDragStart}
          onDragFinish={onDragFinish}
          onStateChange={onStateChange}
          onMoveRequest={onMoveRequest}
        />
      );
      break;
    case "žaluzija":
    case "venetian_blind":
      deviceCanvas =
        canvasType === "default" ? (
          <VenetianBlindCanvas
            style={style}
            position={position}
            rotation={rotation}
            onDragStart={onDragStart}
            onDragFinish={onDragFinish}
            onStateChange={onStateChange}
            onMoveRequest={onMoveRequest}
          />
        ) : (
          <VenetianBlindSideViewCanvas
            style={style}
            rotation={rotation}
            inclinationtype={InclinationType.Full}
            onMoveRequest={onMoveRequest}
          />
        );
      break;
    case "komarnik":
    case "insect_screen":
      deviceCanvas = (
        <InsectScreenCanvas
          style={style}
          position={position}
          onDragStart={onDragStart}
          onDragFinish={onDragFinish}
          onStateChange={onStateChange}
          onMoveRequest={onMoveRequest}
        />
      );
      break;
    case "screen":
    case "textile_screen":
      deviceCanvas = (
        <ScreenCanvas
          style={style}
          position={position}
          onDragStart={onDragStart}
          onDragFinish={onDragFinish}
          onStateChange={onStateChange}
          onMoveRequest={onMoveRequest}
        />
      );
      break;
    case "tenda":
    case "awning":
      deviceCanvas = (
        <AwningCanvas
          style={style}
          position={position}
          onDragStart={onDragStart}
          onDragFinish={onDragFinish}
          onStateChange={onStateChange}
          onMoveRequest={onMoveRequest}
        />
      );
      break;
    case "falcon":
      deviceCanvas = (
        <FalconCanvas
          style={style}
          position={position}
          onDragStart={onDragStart}
          onDragFinish={onDragFinish}
          onStateChange={onStateChange}
          onMoveRequest={onMoveRequest}
        />
      );
      break;
    case "pergola":
      deviceCanvas = (
        <PergolaCanvas
          style={style}
          rotation={rotation}
          onDragStart={onDragStart}
          onDragFinish={onDragFinish}
          onStateChange={onStateChange}
          onMoveRequest={onMoveRequest}
        />
      );
      break;
  }

  return deviceCanvas;
}

type DeviceMenuItem = "Magnet" | "Motor" | "MagicFoil" | "Lights";

const DoorLayout = ({
  deviceInfo,
  deviceLinkToken,
  handleActionResults,
  handleActionError,
}: {
  deviceInfo: Device;
  deviceLinkToken: string;
  handleActionResults: (results: ActionResult[]) => void;
  handleActionError: (error: unknown) => void;
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const {
    magnetLockConnected,
    motorLockConnected,
    magicFoilConnected,
    magicFoilOpacity,
    handleLightConnected,
    doorLightConnected,
    handleLightBrightness,
    doorLightBrightness,
    motorLockLocked,
    magnetLockLocked,
    magneticSensorConnected,
    magneticSensorOpen,
    lockMagneticSensorConnected,
    lockMagneticSensorOpen,
    id: deviceId,
    status,
    connected,
  } = deviceInfo;
  const [activeMenuItem, setActiveMenuItem] = useState<DeviceMenuItem>(
    magnetLockConnected ? "Magnet" : "Motor",
  );

  const DoorTab = () => {
    switch (activeMenuItem) {
      case "Magnet":
        return (
          <LockTab
            isOpened={magneticSensorConnected ? magneticSensorOpen : undefined}
            isLocked={magnetLockLocked}
            isDetectingLocked={
              lockMagneticSensorConnected ? lockMagneticSensorOpen : undefined
            }
            onLockClicked={() => {
              dispatch(
                executeDeviceAction({
                  deviceIds: [deviceId],
                  action: { command: "unlock", lockType: "magnet" },
                  deviceLinkToken: deviceLinkToken ?? undefined,
                }),
              )
                .unwrap()
                .then((results) => handleActionResults(results))
                .catch((err) => handleActionError(err));
            }}
          />
        );
      case "Motor":
        return (
          <LockTab
            isOpened={magneticSensorConnected ? magneticSensorOpen : undefined}
            isLocked={motorLockLocked}
            isDetectingLocked={
              lockMagneticSensorConnected ? lockMagneticSensorOpen : undefined
            }
            onLockClicked={() => {
              dispatch(
                executeDeviceAction({
                  deviceIds: [deviceId],
                  action: { command: "unlock", lockType: "motor" },
                  deviceLinkToken: deviceLinkToken ?? undefined,
                }),
              )
                .unwrap()
                .then((results) => handleActionResults(results))
                .catch((err) => handleActionError(err));
            }}
          />
        );
      case "MagicFoil":
        return (
          <MagicFoilTab
            magicFoilOpacity={magicFoilOpacity ?? 0}
            onMagicFoilClicked={() => {
              dispatch(
                executeDeviceAction({
                  deviceIds: [deviceId],
                  action: {
                    command: "magic_foil_apply",
                    opacity: magicFoilOpacity ? 0 : 1,
                  },
                  deviceLinkToken: deviceLinkToken ?? undefined,
                }),
              )
                .unwrap()
                .then((results) => handleActionResults(results))
                .catch((err) => handleActionError(err));
            }}
          />
        );
      case "Lights":
        return (
          <LightsTab
            doorLightConnected={doorLightConnected ?? false}
            handleLightConnected={handleLightConnected ?? false}
            doorLightBrightness={doorLightBrightness ?? 0}
            handleLightBrightness={handleLightBrightness ?? 0}
            onDoorLightClicked={() => {
              dispatch(
                executeDeviceAction({
                  deviceIds: [deviceId],
                  action: {
                    command: "light_apply",
                    lightType: "door",
                    brightness: doorLightBrightness ? 0 : 1,
                  },
                  deviceLinkToken: deviceLinkToken ?? undefined,
                }),
              )
                .unwrap()
                .then((results) => handleActionResults(results))
                .catch((err) => handleActionError(err));
            }}
            onHandleLightClicked={() => {
              dispatch(
                executeDeviceAction({
                  deviceIds: [deviceId],
                  action: {
                    command: "light_apply",
                    lightType: "handle",
                    brightness: handleLightBrightness ? 0 : 1,
                  },
                  deviceLinkToken: deviceLinkToken ?? undefined,
                }),
              )
                .unwrap()
                .then((results) => handleActionResults(results))
                .catch((err) => handleActionError(err));
            }}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      <div className="device-menu-grid">
        {magnetLockConnected && (
          <div
            className={`${
              activeMenuItem === "Magnet"
                ? "device-menu-item active-menu-item"
                : "device-menu-item"
            }`}
            onClick={() => setActiveMenuItem("Magnet")}
          >
            <img src={LockMagnetNavIcon} style={{ height: 35 }} alt="Magnet" />
            <span>{t("tab_magnetLock")}</span>
          </div>
        )}
        {motorLockConnected && (
          <div
            className={`${
              activeMenuItem === "Motor"
                ? "device-menu-item active-menu-item"
                : "device-menu-item"
            }`}
            onClick={() => setActiveMenuItem("Motor")}
          >
            <img src={LockMotorNavIcon} style={{ height: 35 }} alt="Motor" />
            <span>{t("tab_motorLock")}</span>
          </div>
        )}
        {magicFoilConnected && (
          <div
            className={`${
              activeMenuItem === "MagicFoil"
                ? "device-menu-item active-menu-item"
                : "device-menu-item"
            }`}
            onClick={() => setActiveMenuItem("MagicFoil")}
          >
            <img
              src={MagicFoilNavIcon}
              style={{ height: 35 }}
              alt="Magic Foil"
            />
            <span>{t("tab_magicFoil")}</span>
          </div>
        )}
        {(handleLightConnected || doorLightConnected) && (
          <div
            className={`${
              activeMenuItem === "Lights"
                ? "device-menu-item active-menu-item"
                : "device-menu-item"
            }`}
            onClick={() => setActiveMenuItem("Lights")}
          >
            <img src={LightOffNavIcon} style={{ height: 35 }} alt="Lights" />
            <span>{t("tab_lights")}</span>
          </div>
        )}
      </div>

      <div className="device-content">
        <video
          className="background-video"
          src={Video}
          loop
          autoPlay
          muted
          playsInline
          style={{ zIndex: 0, overflow: "hidden" }}
        />
        <div style={{ position: "relative", zIndex: 1, height: "100%" }}>
          {status !== "ready" && (
            <Loading style={{ position: "absolute", height: "100%" }} />
          )}
          {connected !== true && <DeviceOfflineIndicator />}
          <DoorTab />
        </div>
      </div>
    </>
  );
};

const LockTab: React.FC<{
  isOpened?: boolean;
  isLocked?: boolean;
  isDetectingLocked?: boolean;
  onLockClicked?: () => void;
}> = ({ isOpened, onLockClicked, isLocked, isDetectingLocked }) => {
  const { t } = useTranslation();
  const hasFilter = isDetectingLocked !== undefined;
  const iconFilter = isDetectingLocked ? "green-icon" : "red-icon";

  const finalButtonClassName = [
    "button",
    hasFilter ? iconFilter : undefined,
  ].join(" ");

  const hasDoorIcon = isOpened !== undefined;
  const doorIcon = isOpened ? DoorOpen : DoorClosed;
  const doorText = isOpened ? t("label_open") : t("label_closed");
  const doorAltText = isOpened ? "Door open" : "Door closed";

  return (
    <div className="door-tab-content lock-tab">
      <div className="button-container">
        <Circular3DButton
          className={finalButtonClassName}
          onClick={(_event) => onLockClicked?.()}
          icon={isLocked ? LockLocked : LockUnlocked}
          iconAlt="Lock"
        />
      </div>
      <div className="informationals">
        {hasDoorIcon && (
          <div className="informational">
            <img
              className="informational-icon"
              src={doorIcon}
              alt={doorAltText}
            />
            <div className="informational-label">{doorText}</div>
          </div>
        )}
      </div>
    </div>
  );
};

const MagicFoilTab: React.FC<{
  magicFoilOpacity: number;
  onMagicFoilClicked?: () => void;
}> = ({ magicFoilOpacity, onMagicFoilClicked }) => {
  const magicFoilIcon = magicFoilOpacity === 0 ? MagicFoilOn : MagicFoilOff;

  return (
    <div className="door-tab-content">
      {magicFoilOpacity === 1 && <div className="magic-foil-overlay"></div>}
      <div className="button-container">
        <Circular3DButton
          className="button"
          onClick={(_event) => onMagicFoilClicked?.()}
          icon={magicFoilIcon}
          iconAlt="Magic foil"
        />
      </div>
    </div>
  );
};

const LightsTab: React.FC<{
  doorLightConnected: boolean;
  handleLightConnected: boolean;
  doorLightBrightness: number;
  handleLightBrightness: number;
  onDoorLightClicked?: () => void;
  onHandleLightClicked?: () => void;
}> = ({
  doorLightConnected,
  handleLightConnected,
  doorLightBrightness,
  handleLightBrightness,
  onDoorLightClicked,
  onHandleLightClicked,
}) => {
  const { t } = useTranslation();
  const doorLightIcon = doorLightBrightness === 1 ? LightOn : LightOff;
  const handleLightIcon = handleLightBrightness === 1 ? LightOn : LightOff;

  return (
    <div className="door-tab-content light-tab">
      {doorLightConnected && (
        <div className="button-container">
          <div className="label">{t("label_doorLight")}</div>
          <Circular3DButton
            className="button"
            onClick={(_event) => onDoorLightClicked?.()}
            icon={doorLightIcon}
            iconAlt="Door light"
          />
        </div>
      )}
      {handleLightConnected && (
        <div className="button-container">
          <div className="label">{t("label_handleLight")}</div>
          <Circular3DButton
            className="button"
            onClick={(_event) => onHandleLightClicked?.()}
            icon={handleLightIcon}
            iconAlt="Handle light"
          />
        </div>
      )}
    </div>
  );
};

const LightLayout: React.FC<{
  deviceInfo: Device;
  deviceLinkToken: string;
  lightType: "light_1" | "light_2";
  handleActionResults: (results: ActionResult[]) => void;
  handleActionError: (error: unknown) => void;
}> = ({
  deviceInfo,
  deviceLinkToken,
  lightType,
  handleActionResults,
  handleActionError,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { status, connected, id: deviceId } = deviceInfo;
  const brightness =
    lightType === "light_1"
      ? deviceInfo.light1Brightness
      : deviceInfo.light2Brightness;
  const lightIcon = brightness ? LightOn : LightOff;

  return (
    <div className="device-content">
      <video
        className="background-video"
        src={Video}
        loop
        autoPlay
        muted
        playsInline
        style={{ zIndex: 0, overflow: "hidden" }}
      />
      <div className="light-container">
        {status !== "ready" && (
          <Loading style={{ position: "absolute", height: "100%" }} />
        )}
        {connected !== true && <DeviceOfflineIndicator />}
        <div className="light-device-content">
          <div className="circle circle1-lights"></div>
          <div className="circle circle2-lights"></div>
          <div
            className="circle circle3-lights"
            onClick={(_event) => {
              dispatch(
                executeDeviceAction({
                  deviceIds: [deviceId],
                  action: {
                    command: "light_apply",
                    lightType,
                    brightness: brightness ? 0 : 1,
                  },
                  deviceLinkToken: deviceLinkToken ?? undefined,
                }),
              )
                .unwrap()
                .then((results) => handleActionResults(results))
                .catch((err) => handleActionError(err));
            }}
          >
            <img alt="Light" src={lightIcon} className="lock-image"></img>
          </div>
        </div>
      </div>
    </div>
  );
};
