import React from "react";
import ClearSky from "../assets/icon_weather_clear_sky.svg";

function WeatherComponent() {
  return (
    <div className="rectangles-container">
      <div className="weather-rectangle">
        {/*
        <img src={ClearSky} style={{ height: 40 }}></img>
        <span className="weather-text"> 20 °C </span>
        <span className="weather-text">10 km/h</span>
        <span className="weather-text">70%</span>
        */}
      </div>
    </div>
  );
}

export default WeatherComponent;
