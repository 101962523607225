import { Loading } from "../components/Loading";
import { Button, Paper, PaperProps, Slider } from "@mui/material";
import RollerShutterIcon from "../assets/roller_shutter.svg";
import VenetianBlindIcon from "../assets/venetian_blind.svg";
import AwningIcon from "../assets/awning.svg";
import TextileScreenIcon from "../assets/textile_screen.svg";
import InsectScreenIcon from "../assets/insect_screen.svg";
import PergolaIcon from "../assets/pergola.svg";
import FalconIcon from "../assets/falcon.svg";
import IconUp from "../assets/icon_up.svg";
import IconDown from "../assets/icon_down.svg";
import {
  MouseEvent as ReactMouseEvent,
  SyntheticEvent,
  useEffect,
  useState,
} from "react";
import { Device } from "../app/DeviceSlice";
import "./DeviceCard.css";

type OnClickedCallback<T> = (event: ReactMouseEvent<T, MouseEvent>) => void;
type OnPositionChangeCallback = (
  event: Event | SyntheticEvent,
  value: number | number[],
) => void;

export interface BlindsCardProps extends PaperProps {
  device: Device;
  onOpenClicked?: OnClickedCallback<HTMLDivElement>;
  onCloseClicked?: OnClickedCallback<HTMLDivElement>;
  onCardClicked?: OnClickedCallback<HTMLDivElement>;
  onChangeRequested?: OnPositionChangeCallback;
}

export const BlindsCard: React.FC<BlindsCardProps> = (args) => {
  const {
    device,
    onOpenClicked,
    onCloseClicked,
    onCardClicked,
    onChangeRequested,
    className,
    ...props
  } = args;

  const [sliderValue, setSliderValue] = useState(
    device.devicetype === "pergola" ? device.inclination : device.position,
  );

  useEffect(() => {
    setSliderValue(
      device.devicetype === "pergola" ? device.inclination : device.position,
    );
  }, [device.devicetype, device.position, device.inclination]);

  const finalClassName = [className, "card", "blind-card"].join(" ");

  return (
    <Paper
      {...props}
      variant="outlined"
      square={false}
      className={finalClassName}
      onClick={onCardClicked}
    >
      <div className="card-content">
        {device.status !== "ready" && device.status !== "offline" && (
          <Loading className="card-busy" />
        )}
        <div className="card-icon">
          <BlindsIcon devicetype={device.devicetype} className="device-icon" />
          {device.status === "offline" && <p className="offline-icon">!</p>}
        </div>
        <div className="card-name">{device.name}</div>
        <div className="card-extra-buttons">
          <div
            className="image-action-button"
            onClick={(event) => {
              event.stopPropagation();
              onOpenClicked?.(event);
            }}
          >
            <img src={IconUp} alt="Up icon" />
          </div>
          <div
            className="image-action-button"
            onClick={(event) => {
              event.stopPropagation();
              onCloseClicked?.(event);
            }}
          >
            <img src={IconDown} alt="Down icon" />
          </div>
        </div>
        <div className="card-control">
          <Slider
            min={0}
            max={1}
            step={0.01}
            value={sliderValue}
            onChange={(_ev, value, activeThumb) => {
              if (Array.isArray(value)) {
                setSliderValue(value[activeThumb]);
              } else {
                setSliderValue(value);
              }
            }}
            onChangeCommitted={onChangeRequested}
            onClick={(event) => event.stopPropagation()}
          />
        </div>
      </div>
    </Paper>
  );
};

type IconProps = React.DetailedHTMLProps<
  React.ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>;

function BlindsIcon({
  devicetype,
  ...props
}: { devicetype: string } & IconProps) {
  let iconImage;

  switch (devicetype.toLowerCase()) {
    case "roleta":
    case "roller_shutter":
      iconImage = RollerShutterIcon;
      break;
    case "žaluzija":
    case "venetian_blind":
      iconImage = VenetianBlindIcon;
      break;
    case "komarnik":
    case "insect_screen":
      iconImage = InsectScreenIcon;
      break;
    case "screen":
    case "textile_screen":
      iconImage = TextileScreenIcon;
      break;
    case "tenda":
    case "awning":
      iconImage = AwningIcon;
      break;
    case "falcon":
      iconImage = FalconIcon;
      break;
    case "pergola":
      iconImage = PergolaIcon;
      break;
  }

  return <img {...props} src={iconImage} alt="Blinds icon" />;
}
