import { ApiToken, renewToken } from "./AccountApi";
import { ApiError } from "./ApiError";
import { AwaitableCountdown } from "./AwaitableCountdown";
import { Semaphore } from "./Semaphore";

const API_BASE_URL = "/authserver";

type RequestMethod = "GET" | "POST" | "PUT" | "DELETE";
type SupportedContentType =
  | "application/json"
  | "application/x-www-form-urlencoded";

export interface RequestParameters {
  method: RequestMethod;
  endpoint: string;
  query?: Record<string, any>;
  body?: Record<string, any>;
  contentType?: SupportedContentType;
}

export interface RequestOptions {
  usesApiToken?: boolean;
  usesSession?: boolean;
  changesSession?: boolean;
  invalidatesApiToken?: boolean;
  deviceLinkToken?: string;
}

export function urlEncodeObject(obj: any) {
  let parts: Array<string> = [];
  for (let propertyName in obj) {
    if (Object.hasOwn(obj, propertyName)) {
      parts.push(
        `${encodeURIComponent(propertyName)}=${encodeURIComponent(
          obj[propertyName],
        )}`,
      );
    }
  }
  return parts.join("&");
}

const apiTokenSemaphore = new Semaphore(1);
let apiToken: ApiToken | null = null;
const sessionUseSemaphore = new Semaphore(1);
const sessionUsingRequests: AwaitableCountdown = new AwaitableCountdown(0);

export async function apiRequest<T>(
  requestParams: RequestParameters,
  options?: RequestOptions,
): Promise<T> {
  const { method, endpoint, body, query, contentType } = requestParams;

  let headers: Headers = new Headers();
  let req: RequestInit = { method };

  if (body) {
    if (contentType) {
      switch (contentType) {
        case "application/json":
          req.body = JSON.stringify(body);
          break;
        case "application/x-www-form-urlencoded":
          req.body = urlEncodeObject(body);
          break;
        default:
          throw Error(`Unhandeled content type ${requestParams.contentType}`);
      }
      headers.set("Content-Type", contentType);
    } else {
      console.warn("Content type not specified assuming application/json");
      req.body = JSON.stringify(body);
      headers.set("Content-Type", "application/json");
    }
  }

  let url = API_BASE_URL + endpoint;

  if (query) {
    const urlParams = new URLSearchParams();
    for (const [key, values] of Object.entries(query)) {
      if (Array.isArray(values)) {
        for (const value of values) {
          urlParams.append(key, value);
        }
      } else {
        urlParams.append(key, values);
      }
    }
    url = url + "?" + urlParams.toString();
  }

  // Adds ApiToken to requests that need it
  if (options?.usesApiToken) {
    await apiTokenSemaphore.wait();
    try {
      if (
        !apiToken ||
        (apiToken.expiresAt && new Date(apiToken.expiresAt) < new Date())
      ) {
        apiToken = await renewToken();
      }
    } finally {
      apiTokenSemaphore.release();
    }

    headers.set("Authorization", `Bearer ${apiToken.token}`);
  }

  if (options?.deviceLinkToken) {
    headers.set("X-Device-Link-Token", options.deviceLinkToken);
  }

  req = {
    ...req,
    headers,
  };

  let res: Response;
  // Ensures session change does not invalidate a session using request
  if (options?.usesSession || options?.changesSession) {
    await sessionUseSemaphore.wait();
    try {
      if (options.usesSession && !options.changesSession) {
        sessionUsingRequests.increment();
      }
    } finally {
      sessionUseSemaphore.release();
    }

    req = {
      ...req,
      credentials: "include",
    };

    if (options?.changesSession) {
      await sessionUseSemaphore.wait();
      try {
        await sessionUsingRequests.waitForZero();

        res = await fetch(url, req);
      } finally {
        sessionUseSemaphore.release();
      }
    } else {
      try {
        res = await fetch(url, req);
      } finally {
        sessionUsingRequests.decrement();
      }
    }
  } else {
    res = await fetch(url, req);
  }

  if (!res.ok) {
    try {
      const response: ApiError = await res.json();

      throw response;
    } catch (error) {
      if (error instanceof TypeError) {
        throw new Error(`Request failed with: ${await res.text()}`);
      }

      // Some other error occurred
      throw error;
    }
  }

  if (options?.invalidatesApiToken) {
    apiToken = null;
  }

  return await res.json();
}
