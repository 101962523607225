import { RequestOptions, apiRequest } from "./ApiRequest";
import { DeviceSpecifier } from "./DeviceApi";

export type DeviceLink = {
  token: string;
  name: string;
  devices: string[];
  deviceSpecifiers: DeviceSpecifier[];
  createdAt: string;
  multiUse: boolean;
  startsAt: string;
  expiresAt: string;
  usedAt?: string;
};

/**
 * @description Get a list of all device links available to the user/deviceLinkToken
 * @param deviceLinkToken If specified fetches only the links available to this device link token (generally this means only the link being used).
 */
export async function getDeviceLinkList(
  deviceLinkToken?: string,
): Promise<DeviceLink[]> {
  let options: RequestOptions = {
    usesApiToken: true,
  };
  if (deviceLinkToken) {
    options = { deviceLinkToken };
  }
  const links = await apiRequest<DeviceLink[]>(
    {
      method: "GET",
      endpoint: "/api/v1/device-link/list",
    },
    options,
  );
  // We can't really return dates as strings, as they are not serializable and that messes with the store
  return links;
}
