import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Button from "@mui/material/Button";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import BackIcon from "../assets/icon_back.svg";
import { useTranslation } from "react-i18next";
import { confirmDeletionToken } from "../api/AccountApi";
import { useQuery } from "react-query";
import { deleteAccountAsync } from "../app/AccountSlice";
import { useAppDispatch } from "../app/hooks";
import { Alert, Snackbar } from "@mui/material";
import LoadingIndicator from "../components/LoadingIndicator";
import ConfirmDialog from "../components/ConfirmDialog";

export const DeleteAccountPage = () => {
  const navigate = useHistory();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  let [deletionToken, setDeletionToken] = useState<string | null>(null);
  let [deletionState, setDeletionState] = useState<string | null>(null);
  const [isErrorSnackbarShown, setIsErrorSnackbarShown] = useState(false);
  const [errorSnackbarMessage, setErrorSnackbarMessage] = useState("");
  const [
    isDeleteAccountConfirmationShown,
    setIsDeleteAccountConfirmationShown,
  ] = useState(false);

  const showError = (error: string) => {
    setErrorSnackbarMessage(error);
    setIsErrorSnackbarShown(true);
  };

  const handleErrorDismiss = () => {
    setIsErrorSnackbarShown(false);
  };

  const { data, isLoading, isError, isSuccess, error } = useQuery(
    "exchangeTokenForState",
    async () => {
      let delToken: string | null = null;
      if (navigate.location.state) {
        const navigationState = navigate.location.state as {
          token?: string;
        };

        setDeletionToken(navigationState.token ?? null);
        delToken = navigationState.token ?? null;
      }
      if (navigate.location.search) {
        const queryString = new URLSearchParams(navigate.location.search);
        setDeletionToken(queryString.get("token"));
        delToken = queryString.get("token");
      }

      if (delToken == null) {
        showError(t("dialog_error_generic"));
        throw new Error("No token provided");
      }

      const state = await confirmDeletionToken(delToken);
      setDeletionState(state);
      return state;
    },
    { retry: 3, staleTime: Infinity, refetchOnWindowFocus: false },
  );

  const openConfirmDeleteAccountDialog = () => {
    setIsDeleteAccountConfirmationShown(true);
  };

  const deleteAccount = () => {
    try {
      if (deletionState == null || deletionToken == null) {
        throw new Error("Deletion state or token missing");
      }
      // Deletes the account and the devices on it
      dispatch(
        deleteAccountAsync({ state: deletionState, token: deletionToken }),
      )
        .unwrap()
        .then((result) => {
          navigate.replace("/");
        })
        .catch((error) => {
          showError(t("dialog_error_generic"));
          console.error(error);
        });
    } catch (err) {
      showError(`${err}`);
      console.error(`Failed to delete account, error: ${err}`);
    }
  };

  if (isError) {
    console.error(error);
  }

  return (
    <>
      <div className="form-background">
        <div className="form">
          <div className="back-button" onClick={() => navigate.replace("/")}>
            <img src={BackIcon} alt={t("button_back")} className="back-icon" />
            {t("button_back")}
          </div>
          {isLoading && <LoadingIndicator />}
          {isError && <p>{t("dialog_error_confirmationLinkExpired")}</p>}
          {isSuccess && (
            <>
              <div className="form-forgotten-password-text">
                {t("dialog_confirm_deleteAccountExplanation")}
              </div>
              <Button
                className="form-button"
                variant="contained"
                color="error"
                size="medium"
                style={{ marginTop: 20 }}
                startIcon={<DeleteOutlineOutlinedIcon />}
                onClick={openConfirmDeleteAccountDialog}
              >
                {t("button_deleteAccount")}
              </Button>
            </>
          )}
          <ConfirmDialog
            open={isDeleteAccountConfirmationShown}
            title={t("title_dialog_confirm")}
            content={<p>{t("dialog_confirm_deleteAccount_deleteAccount")}</p>}
            onConfirm={deleteAccount}
            onCancel={() => setIsDeleteAccountConfirmationShown(false)}
            confirmButtonText={t("button_yes")}
            cancelButtonText={t("button_no")}
          />
          {isErrorSnackbarShown && (
            <Snackbar
              open={isErrorSnackbarShown}
              onClose={handleErrorDismiss}
              message={errorSnackbarMessage}
            >
              <Alert
                elevation={6}
                variant="filled"
                severity="error"
                onClose={handleErrorDismiss}
              >
                {errorSnackbarMessage}
              </Alert>
            </Snackbar>
          )}
        </div>
      </div>
    </>
  );
};
