import React from "react";
import {
  Divider,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListItemTextProps,
} from "@mui/material";

interface MenuItemProps {
  icon: React.ReactNode;
  primaryText: string;
  onClick?: () => void;
  primaryTextStyle?: ListItemTextProps["style"];
}

const MenuItem: React.FC<MenuItemProps> = ({
  icon,
  primaryText,
  onClick,
  primaryTextStyle,
}) => {
  return (
    <>
      <ListItem>
        <ListItemButton style={{ borderRadius: 10 }} onClick={onClick}>
          <ListItemIcon>{icon}</ListItemIcon>
          <ListItemText primary={primaryText} style={primaryTextStyle} />
        </ListItemButton>
      </ListItem>
      <Divider />
    </>
  );
};

export default MenuItem;
