import React, { useCallback, useEffect, useRef, useState } from "react";
import "./DeviceCanvas.css";

enum InclinationType {
  LowerHalf,
  UpperHalf,
  Full,
}

function positionFromMousePosition(
  mouseY: number,
  canvasY: number,
  canvasUnscaledHeight: number,
) {
  const actualPosition = Math.min(
    Math.max((mouseY - canvasY) / canvasUnscaledHeight, 0.0),
    1.0,
  );

  const reservedPercentage = 0.02;

  return Math.min(
    Math.max(
      actualPosition / (1.0 - reservedPercentage * 2) - reservedPercentage,
      0.0,
    ),
    1.0,
  );
}

type CanvasProps = React.DetailedHTMLProps<
  React.CanvasHTMLAttributes<HTMLCanvasElement>,
  HTMLCanvasElement
>;

export const VenetianBlindCanvas: React.FC<
  {
    position?: number;
    rotation?: number;
    onMoveRequest?: (args: { position?: number; rotation?: number }) => void;
    onStateChange?: (args: { position?: number; rotation?: number }) => void;
    onDragStart?: () => void;
    onDragFinish?: () => void;
    inclinationType?: InclinationType;
  } & CanvasProps
> = ({ ...props }) => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [isInside, setIsInside] = useState(false);
  const [preDragPosition, setPreDragPosition] = useState(0.0);
  const [preLeavePosition, setPreLeavePosition] = useState(0.0);
  const [position, setPosition] = useState(props.position ?? 0.0);
  const [rotation, setRotation] = useState(props.rotation ?? 0.0);
  const { onMoveRequest, onStateChange, onDragStart, onDragFinish } = props;

  useEffect(() => {
    if (props.position !== undefined) {
      setPosition(props.position);
    }
    if (props.rotation !== undefined) {
      setRotation(props.rotation);
    }
  }, [props.position, props.rotation]);

  const handleMouseDown = useCallback(
    (e: { clientY: number }) => {
      if (!isDragging) {
        setIsDragging(true);
        setIsInside(true);
        setPreDragPosition(position);
        const newPosition = positionFromMousePosition(
          e.clientY,
          canvasRef.current!.getBoundingClientRect().y,
          canvasRef.current!.clientHeight,
        );
        setPosition(newPosition);
        if (onStateChange) onStateChange({ position: newPosition, rotation });
        if (onDragStart) onDragStart();
      }
    },
    [isDragging, position, rotation, onStateChange, onDragStart],
  );
  const handleMouseUp = useCallback(
    (_event: any) => {
      if (isDragging) {
        setIsDragging(false);
        if (isInside) {
          if (onMoveRequest) onMoveRequest({ position, rotation });
          if (onDragFinish) onDragFinish();
        }
      }
    },
    [isDragging, isInside, position, rotation, onMoveRequest, onDragFinish],
  );
  const handleMouseMove = useCallback(
    (e: { clientY: number }) => {
      if (isDragging && isInside) {
        const newPosition = positionFromMousePosition(
          e.clientY,
          canvasRef.current!.getBoundingClientRect().y,
          canvasRef.current!.clientHeight,
        );
        setPosition(newPosition);
        if (onStateChange) onStateChange({ position: newPosition, rotation });
        setPreLeavePosition(position);
      }
    },
    [isDragging, isInside, position, rotation, onStateChange],
  );
  const handleMouseLeave = useCallback(
    (_: any) => {
      if (isDragging) {
        setIsInside(false);
        setPosition(preDragPosition);
        if (onStateChange)
          onStateChange({ position: preDragPosition, rotation });
        if (onDragFinish) onDragFinish();
      }
    },
    [isDragging, preDragPosition, rotation, onStateChange, onDragFinish],
  );
  const handleMouseEnter = useCallback(
    (_: any) => {
      if (isDragging) {
        setIsInside(true);
        setPosition(preLeavePosition);
        if (onStateChange)
          onStateChange({ position: preLeavePosition, rotation });
        if (onDragStart) onDragStart();
      }
    },
    [isDragging, preLeavePosition, rotation, onStateChange, onDragStart],
  );
  const handleTouchStart = useCallback(
    (e: TouchEvent) => {
      handleMouseDown(e.touches[0]);
      e.stopPropagation();
    },
    [handleMouseDown],
  );
  const handleTouchEnd = useCallback(
    (e: TouchEvent) => {
      handleMouseUp(e);
      if (isInside) {
        e.stopPropagation();
      }
    },
    [handleMouseUp, isInside],
  );
  const handleTouchMove = useCallback(
    (e: TouchEvent) => {
      handleMouseMove(e.touches[0]);
      e.stopPropagation();
    },
    [handleMouseMove],
  );

  useEffect(() => {
    const onresize = () => {
      const canvas = canvasRef.current;
      if (!canvas) return;

      const parent = canvas.parentElement;
      if (!parent) return;

      // Handles proper resizing on MacOS and devices with a pixel ratio != 1
      const scale = window.devicePixelRatio;
      canvas.width = Math.floor(parent.clientWidth * scale);
      canvas.height = Math.floor(parent.clientHeight * scale);
    };
    window.addEventListener("resize", onresize);
    onresize();
    return () => window.removeEventListener("resize", onresize);
  }, []);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) {
      return;
    }

    window.addEventListener("mouseup", handleMouseUp);
    canvas.addEventListener("mousedown", handleMouseDown);
    canvas.addEventListener("mousemove", handleMouseMove);
    canvas.addEventListener("mouseleave", handleMouseLeave);
    canvas.addEventListener("mouseenter", handleMouseEnter);
    window.addEventListener("touchend", handleTouchEnd);
    canvas.addEventListener("touchstart", handleTouchStart);
    canvas.addEventListener("touchmove", handleTouchMove);

    return () => {
      window.removeEventListener("mouseup", handleMouseUp);
      canvas.removeEventListener("mousedown", handleMouseDown);
      canvas.removeEventListener("mousemove", handleMouseMove);
      canvas.removeEventListener("mouseleave", handleMouseLeave);
      canvas.removeEventListener("mouseenter", handleMouseEnter);
      window.removeEventListener("touchend", handleTouchEnd);
      canvas.removeEventListener("touchstart", handleTouchStart);
      canvas.removeEventListener("touchmove", handleTouchMove);
    };
  }, [
    handleMouseUp,
    handleMouseDown,
    handleMouseMove,
    handleMouseLeave,
    handleMouseEnter,
    handleTouchEnd,
    handleTouchStart,
    handleTouchMove,
  ]);

  const render = (canvas: HTMLCanvasElement | null) => {
    if (!canvas) {
      return;
    }
    const ctx = canvas.getContext("2d");
    if (!ctx) {
      return;
    }

    const scale = window.devicePixelRatio;
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.save();
    ctx.scale(scale, scale);

    const cWidth = canvas.width / scale;
    const cHeight = canvas.height / scale;

    const drawVerticalLines = (
      ctx: CanvasRenderingContext2D,
      offset: number,
    ) => {
      ctx.strokeStyle = "rgba(60, 60, 60, 1)";
      ctx.lineWidth = 2;

      // Left line
      ctx.beginPath();
      ctx.moveTo(offset, 0);
      ctx.lineTo(offset, cHeight);
      ctx.stroke();

      // Right line
      ctx.beginPath();
      ctx.moveTo(cWidth - offset, 0);
      ctx.lineTo(cWidth - offset, cHeight);
      ctx.stroke();
    };

    //venetian blind
    const gradientRectWidth = cWidth;
    const slateHeight = cHeight * 0.05;
    const totalRectangles = cHeight / slateHeight;

    function drawSlate(ctx: CanvasRenderingContext2D, slateHeight: number) {
      const gradient = ctx.createLinearGradient(0, -slateHeight / 2, 0, 0);
      gradient.addColorStop(0, "rgba(255, 255, 255, 1)");
      gradient.addColorStop(0.15, "rgba(214, 214, 214, 1)");
      gradient.addColorStop(0.45, "rgba(194, 194, 194, 1)");
      gradient.addColorStop(0.8, "rgba(182, 182, 182, 1)");
      gradient.addColorStop(1.0, "rgba(178, 178, 178, 1)");

      ctx.fillStyle = gradient;
      ctx.fillRect(0, -slateHeight / 2, gradientRectWidth, slateHeight / 2);

      const gradient2 = ctx.createLinearGradient(0, 0, 0, slateHeight / 2);
      gradient2.addColorStop(0, "rgba(255, 255, 255, 1)");
      gradient2.addColorStop(0.28, "rgba(248, 248, 248, 1)");
      gradient2.addColorStop(0.4, "rgba(227, 227, 227, 1)");
      gradient2.addColorStop(0.7, "rgba(194, 194, 194, 1)");
      gradient2.addColorStop(0.89, "rgba(148, 148, 147, 1)");
      gradient2.addColorStop(1.0, "rgba(103, 103, 102, 1)");

      ctx.fillStyle = gradient2;
      ctx.fillRect(0, 0, gradientRectWidth, slateHeight / 2);
    }

    ctx.save();
    ctx.translate(0, (1 - position) * -cHeight);
    ctx.save();
    ctx.translate(0, slateHeight / 2);

    let yScaling;
    switch (props.inclinationType) {
      case InclinationType.LowerHalf:
        yScaling = rotation;
        break;
      case InclinationType.UpperHalf:
        yScaling = rotation - 1;
        break;
      case InclinationType.Full:
        yScaling = rotation * 2 - 1;
        break;
      default:
        yScaling = rotation;
        break;
    }

    //scaling spodnjih 90 = od 0 do 1 = rotation
    //zgornjih 90 = -1 do 0 = rotation - 1
    //180 = rotation * 2 - 1

    if (Math.abs(yScaling) < 0.1) {
      if (yScaling > 0) {
        yScaling = 0.1;
      } else {
        yScaling = -0.1;
      }
    }

    for (let i = 0; i < totalRectangles; i++) {
      ctx.save();
      ctx.scale(1, yScaling);
      drawSlate(ctx, slateHeight);
      ctx.restore();
      ctx.translate(0, slateHeight);
    }
    ctx.restore();
    const lineOffset = 15;
    drawVerticalLines(ctx, lineOffset);
    ctx.restore();
    ctx.restore();
  };

  useEffect(() => {
    render(canvasRef.current);
  }, []);
  render(canvasRef.current);

  return (
    <canvas className="device-canvas" style={props.style} ref={canvasRef} />
  );
};
