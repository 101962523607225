import {
  addListener,
  createListenerMiddleware,
  isAnyOf,
  TypedAddListener,
  TypedStartListening,
} from "@reduxjs/toolkit";
import { loginAsync, loginFederatedAsync, setupAsync } from "./AccountSlice";

import { RootState, AppDispatch } from "./store";

export const localStorageMiddleware = createListenerMiddleware();

export type AppStartListening = TypedStartListening<RootState, AppDispatch>;

export const startAppListening =
  localStorageMiddleware.startListening as AppStartListening;
export const addAppListener = addListener as TypedAddListener<
  RootState,
  AppDispatch
>;

export const localStorageAccountKey = "account";

startAppListening({
  matcher: isAnyOf(
    loginAsync.fulfilled,
    loginFederatedAsync.fulfilled,
    setupAsync.fulfilled,
  ),
  effect: (action, listenerApi) => {
    console.log("Local storage middleware called with action", action);
    localStorage.setItem(
      localStorageAccountKey,
      JSON.stringify(listenerApi.getState().account),
    );
  },
});
startAppListening({
  predicate: (action, currentState, previousState) =>
    previousState.account.isLoggedIn != currentState.account.isLoggedIn &&
    !currentState.account.isLoggedIn,
  effect: (action, listenerApi) => {
    console.log("Local storage middleware (remvoe) called with action", action);
    localStorage.removeItem(localStorageAccountKey);
  },
});
