import React from "react";
import { NavLink } from "react-router-dom";
import logoImage from "../assets/adc_home_white.png";
import deviceIcon from "../assets/deviceIcon.svg";
import scenarioIcon from "../assets/scenarioIcon.svg";
import groupIcon from "../assets/groupIcon.svg";
import menuIcon from "../assets/menuIcon.svg";
import deviceIconActive from "../assets/deviceIconActive.svg";
import scenarioIconActive from "../assets/scenarioIconActive.svg";
import groupIconActive from "../assets/groupIconActive.svg";
import menuIconActive from "../assets/menuIconActive.svg";
import { useTranslation } from "react-i18next";

interface IconProps {
  isActive: boolean;
}

export function Navbar() {
  const { t } = useTranslation();
  return (
    <div className="header">
      <img src={logoImage} alt="Logo" />

      <div className="nav-links">
        {/*<NavLink to="/home" className="nav-link" activeClassName="active-link">
          <DeviceIcon isActive={window.location.pathname === "/home"} />
          <span className="nav-span">{t("title_devices")}</span>
        </NavLink>

        <NavLink
          to="/groups"
          className="nav-link"
          activeClassName="active-link"
        >
          <GroupIcon isActive={window.location.pathname === "/groups"} />
          <span className="nav-span">{t("title_groups")}</span>
        </NavLink>

        <NavLink
          to="/scenarios"
          className="nav-link"
          activeClassName="active-link"
        >
          <ScenarioIcon isActive={window.location.pathname === "/scenarios"} />
          <span className="nav-span">{t("title_scenarios")}</span>
        </NavLink>
        */}

        <NavLink to="/menu" className="nav-link" activeClassName="active-link">
          <MenuIcon isActive={window.location.pathname === "/menu"} />
          <span className="nav-span">{t("title_menu")}</span>
        </NavLink>
      </div>
    </div>
  );
}

function DeviceIcon({ isActive }: IconProps) {
  const iconSrc = isActive ? deviceIconActive : deviceIcon;
  return (
    <img
      src={iconSrc}
      alt="adc-logo"
      className="nav-icon"
      style={{ width: 20, height: 20 }}
    ></img>
  );
}
function GroupIcon({ isActive }: IconProps) {
  const iconSrc = isActive ? groupIconActive : groupIcon;
  return (
    <img
      src={iconSrc}
      alt="adc-logo"
      className="nav-icon"
      style={{ width: 20, height: 20 }}
    ></img>
  );
}
function ScenarioIcon({ isActive }: IconProps) {
  const iconSrc = isActive ? scenarioIconActive : scenarioIcon;
  return (
    <img
      src={iconSrc}
      alt="adc-logo"
      className="nav-icon"
      style={{ width: 20, height: 20 }}
    ></img>
  );
}
function MenuIcon({ isActive }: IconProps) {
  const iconSrc = isActive ? menuIconActive : menuIcon;
  return (
    <img
      src={iconSrc}
      alt="adc-logo"
      className="nav-icon"
      style={{ width: 20, height: 20 }}
    ></img>
  );
}
