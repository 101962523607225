import React from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

interface AlertDialogProps {
  open: boolean;
  title: string;
  content: React.ReactNode;
  onDismiss: () => void;
  okButtonText?: string;
}

const AlertDialog: React.FC<AlertDialogProps> = ({
  open,
  title,
  content,
  onDismiss,
  okButtonText,
}: AlertDialogProps) => {
  const { t } = useTranslation();

  return (
    <Dialog open={open} onClose={onDismiss}>
      <DialogTitle style={{ textTransform: "uppercase" }}>{title}</DialogTitle>
      <DialogContent>{content}</DialogContent>
      <DialogActions>
        <Button onClick={onDismiss} color="primary" variant="outlined">
          {okButtonText || t("button_ok")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AlertDialog.defaultProps = {
  okButtonText: undefined,
} as Partial<AlertDialogProps>;

export default AlertDialog;
