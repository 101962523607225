import { login } from "../adcApi";

// kličeš login iz apija in shrani v local storage
// preveriš v local storageu a maš token (če je veljaven)
// shrani ostale podatke (expiresAt)

// dodaš funkcijo da dobiš token (vrne api token) -- preveriš a je token v storage veljaven,
// drugače narediš še en request brez logina pa passworda
// ali dobiš nov token z expires at ali pa napako da se uporabnik še enkrat prijavi

export async function Login(username: string, password: string) {
  let response = await login(username, password);
  localStorage.setItem("apiToken", response.token);
  localStorage.setItem("expiresAt", response.expiresAt);
}

export async function getToken(): Promise<string> {
  return "fake-token";
}
