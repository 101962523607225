import React from "react";

interface LoadingIndicatorProps {
  style?: React.CSSProperties;
}

const LoadingIndicator: React.FC<LoadingIndicatorProps> = ({ style }) => {
  return (
    <div className="loading-device-overlay" style={style}>
      <div className="loading-device-spinner"></div>
    </div>
  );
};

export default LoadingIndicator;
