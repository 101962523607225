import React from "react";
import "./Circular3DButton.css";

export interface Circular3DButtonProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  labelText?: string;
  icon?: string;
  iconAlt?: string;
  size?: string;
}

export const Circular3DButton: React.FC<Circular3DButtonProps> = ({
  labelText,
  icon,
  iconAlt,
  onClick,
  size,
  className,
  ...props
}) => {
  const width = size !== undefined ? size : props.style?.width;
  const height = size !== undefined ? size : props.style?.height;
  const finalClassName = [className, "circular-3d-button"].join(" ");
  return (
    <div
      {...props}
      style={{ ...props.style, width, height }}
      className={finalClassName}
    >
      <div className="outter-ring"></div>
      <div className="inner-ring"></div>
      <div className="button" onClick={onClick}>
        <img
          draggable={false}
          alt={iconAlt}
          src={icon}
          className="button-icon"
        ></img>
      </div>
    </div>
  );
};
