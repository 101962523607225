import React, { useEffect, useState } from "react";
import Layout from "../components/Layout";
import { scenarioList } from "../adcApi";
import { Paper, Switch } from "@mui/material";
import { getToken } from "../services/AccountService";
import WeatherComponent from "../components/WeatherComponent";

type DaysOfWeek = {
  monday: boolean;
  tuesday: boolean;
  wednesday: boolean;
  thursday: boolean;
  friday: boolean;
  saturday: boolean;
  sunday: boolean;
};

export const ScenarioPage = () => {
  return (
    <>
      <Layout>
        <ScenarioList />
      </Layout>
    </>
  );
};

function ScenarioList() {
  const [list, setList] = useState<{ [key: string]: any }>();

  const [isChecked, setIsChecked] = useState(false);

  const handleSwitchToggle = () => {
    setIsChecked(!isChecked);
  };

  const formatDaysOfWeek = (daysOfWeek: DaysOfWeek) => {
    let dayLabels: { [key in keyof DaysOfWeek]: string } = {
      monday: "Mon",
      tuesday: "Tue",
      wednesday: "Wed",
      thursday: "Thu",
      friday: "Fri",
      saturday: "Sat",
      sunday: "Sun",
    };

    const temp = Object.entries(daysOfWeek)
      .filter(([key, value]) => value)
      .map(([key, value]) => dayLabels[key as keyof DaysOfWeek])
      .join(", ");
    return temp;
  };

  const formatTime = (hour: number, minute: number) => {
    const formattedHour = hour < 10 ? `0${hour}` : `${hour}`;
    const formattedMinute = minute < 10 ? `0${minute}` : `${minute}`;
    return `${formattedHour}:${formattedMinute}`;
  };

  const formatWeatherTrigger = (trigger: any) => {
    const { measurement, condition } = trigger.parameters;
    const comparisonOperator = condition.lt ? "<" : condition.gt ? ">" : ">";

    const measurementDisplayNames: { [key: string]: string } = {
      wind_speed: "Wind speed",
      temperature: "Temperature",
      wind_gust: "Wind gust",
      humidity: "Humidity",
      rain: "Rain",
      snow: "Snow",
    };

    const measurementDisplayName =
      measurementDisplayNames[measurement] || measurement;

    return `Weather: ${measurementDisplayName} ${comparisonOperator} ${
      condition.lt || condition.gt
    }`;
  };

  useEffect(() => {
    getToken()
      .then((apiToken) => scenarioList(apiToken))
      .then((data) => {
        setList(data);
        console.log(data);
      });
  }, []);

  return (
    <div className="scenario-list-container">
      <div className="App">
        <header className="App-body">
          <WeatherComponent />
          <div className="scrollable-content">
            <ul className="device-container">
              {list &&
                Object.entries(list).map(([scenarioId, scenario]) => (
                  <Paper
                    variant="outlined"
                    square={false}
                    key={scenarioId}
                    className="list-item"
                  >
                    <div className="switch-item-container">
                      <span>{scenario.name}</span>
                      <span>{scenario.trigger}</span>

                      <div className="switch-container">
                        <Switch
                          checked={isChecked}
                          onChange={handleSwitchToggle}
                          color="primary"
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        textAlign: "left",
                        color: "#7bbbcd",
                        marginLeft: 20,
                      }}
                    >
                      <ul
                        style={{
                          listStyle: "none",
                          fontSize: 14,
                        }}
                      >
                        {scenario.triggers.map(
                          (trigger: any, index: number) => (
                            <li key={index}>
                              {trigger.type === "DayOfWeek" && (
                                <span>
                                  Day of Week:{" "}
                                  {formatDaysOfWeek(trigger.parameters)}
                                </span>
                              )}
                              {trigger.type === "TimeOfDay" && (
                                <span>
                                  Time of Day:{" "}
                                  {formatTime(
                                    trigger.parameters.hour,
                                    trigger.parameters.minute,
                                  )}
                                </span>
                              )}
                              {trigger.type === "Weather" && (
                                <span>{formatWeatherTrigger(trigger)}</span>
                              )}
                              {trigger.type === "Sunrise" && (
                                <span>Time of day: Sunrise</span>
                              )}
                              {trigger.type === "Sunset" && (
                                <span>Time of day: Sunset</span>
                              )}
                              {trigger.type === "Security" && (
                                <span>Security mode</span>
                              )}
                              {/*<span>{JSON.stringify(trigger)}</span>*/}
                            </li>
                          ),
                        )}
                      </ul>
                    </div>
                  </Paper>
                ))}
            </ul>
          </div>
        </header>
      </div>
    </div>
  );
}
