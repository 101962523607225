import React from "react";
import { useTranslation } from "react-i18next";

const DeviceOfflineIndicator: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div className="loading-device-overlay">
      <div>{t("label_deviceOffline")}</div>
    </div>
  );
};

export default DeviceOfflineIndicator;
