import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Button from "@mui/material/Button";
import { Alert, Snackbar, TextField } from "@mui/material";
import PasswordIcon from "@mui/icons-material/Password";
import BackIcon from "../assets/icon_back.svg";
import { changePassword } from "../api/AccountApi";
import { ApiError } from "../api/ApiError";
import LoadingIndicator from "../components/LoadingIndicator";
import { useTranslation } from "react-i18next";
import AlertDialog from "../components/AlertDialog";

export const ChangePasswordPage = () => {
  const navigate = useHistory();
  const { t } = useTranslation();

  const [isBusy, setIsBusy] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [passwordsMatch, setPasswordsMatch] = useState(false);
  const [formValid, setFormValid] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [successSnackbarOpen, setSuccessSnackbarOpen] = useState(false);

  useEffect(() => {
    setPasswordsMatch(newPassword === confirmNewPassword);
  }, [newPassword, confirmNewPassword]);

  useEffect(() => {
    setFormValid(
      currentPassword.length > 0 &&
        newPassword.length > 0 &&
        confirmNewPassword.length > 0 &&
        passwordsMatch,
    );
  }, [currentPassword, newPassword, confirmNewPassword, passwordsMatch]);

  const handleCurrentPasswordChange: React.ChangeEventHandler<
    HTMLInputElement
  > = (e) => setCurrentPassword(e.target.value);

  const handleNewPasswordChange: React.ChangeEventHandler<HTMLInputElement> = (
    e,
  ) => setNewPassword(e.target.value);

  const handleConfirmNewPasswordChange: React.ChangeEventHandler<
    HTMLInputElement
  > = (e) => setConfirmNewPassword(e.target.value);

  const handleSubmit: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    setIsBusy(true);
    changePassword(currentPassword, newPassword)
      .then((res) => {
        console.log("password changed: ", res);
        setSuccessSnackbarOpen(true);
      })
      .catch((err: ApiError) => {
        console.error("Password change failed: ", err);
        if (err.error === "invalid_credentials") {
          setSnackbarMessage(t("dialog_alert_incorrectPassword"));
        } else if (err.error === "password_not_strong_enough") {
          setSnackbarMessage(t("dialog_alert_passwordTooWeak"));
        }

        setSnackbarOpen(true);
      })
      .finally(() => {
        setIsBusy(false);
      });
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleSuccessSnackbarDismiss = () => {
    navigate.goBack();
    setSuccessSnackbarOpen(false);
  };

  return (
    <>
      <div className="form-background">
        <div className="form">
          <div className="back-button" onClick={() => navigate.goBack()}>
            <img src={BackIcon} alt={t("button_back")} className="back-icon" />
            {t("button_back")}
          </div>
          {isBusy === true && <LoadingIndicator />}
          <TextField
            variant="outlined"
            label={t("label_currentPassword")}
            className="form-input"
            value={currentPassword}
            onChange={handleCurrentPasswordChange}
            type="password"
            name="name"
            margin="normal"
          />
          <TextField
            variant="outlined"
            label={t("label_newPassword")}
            className="form-input"
            color={passwordsMatch ? "primary" : "error"}
            value={newPassword}
            onChange={handleNewPasswordChange}
            type="password"
            name="name"
            margin="normal"
          />
          <TextField
            variant="outlined"
            label={t("label_confirmNewPassword")}
            color={passwordsMatch ? "primary" : "error"}
            className="form-input"
            value={confirmNewPassword}
            onChange={handleConfirmNewPasswordChange}
            type="password"
            name="name"
            margin="normal"
          />
          {!passwordsMatch && (
            <p className="invalid-alert">{t("validation_passwordsNoMatch")}</p>
          )}

          <Button
            className="form-button"
            type="submit"
            variant="contained"
            size="medium"
            startIcon={<PasswordIcon />}
            disabled={!formValid}
            onClick={handleSubmit}
          >
            {t("button_changePassword")}
          </Button>
          <Snackbar
            open={snackbarOpen}
            autoHideDuration={6000}
            onClose={handleSnackbarClose}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={handleSnackbarClose}
              severity="error"
            >
              {snackbarMessage}
            </Alert>
          </Snackbar>
          <AlertDialog
            open={successSnackbarOpen}
            title={t("title_success")}
            content={<p>{t("dialog_alert_passwordUpdated")}</p>}
            onDismiss={handleSuccessSnackbarDismiss}
          />
        </div>
      </div>
    </>
  );
};
