import React, { useCallback, useEffect, useRef, useState } from "react";
import "./DeviceCanvas.css";

function positionFromMousePosition(
  mouseY: number,
  canvasY: number,
  canvasUnscaledHeight: number,
) {
  const actualPosition = Math.min(
    Math.max((mouseY - canvasY) / canvasUnscaledHeight, 0.0),
    1.0,
  );

  const reservedPercentage = 0.02;

  return Math.min(
    Math.max(
      actualPosition / (1.0 - reservedPercentage * 2) - reservedPercentage,
      0.0,
    ),
    1.0,
  );
}

type CanvasProps = React.DetailedHTMLProps<
  React.CanvasHTMLAttributes<HTMLCanvasElement>,
  HTMLCanvasElement
>;

export const ScreenCanvas: React.FC<
  {
    position?: number;
    onMoveRequest?: (args: { position?: number }) => void;
    onStateChange?: (args: { position?: number }) => void;
    onDragStart?: () => void;
    onDragFinish?: () => void;
  } & CanvasProps
> = ({ ...props }) => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [isInside, setIsInside] = useState(false);
  const [preDragPosition, setPreDragPosition] = useState(0.0);
  const [preLeavePosition, setPreLeavePosition] = useState(0.0);
  const [position, setPosition] = useState(props.position ?? 0.0);
  const { onMoveRequest, onStateChange, onDragStart, onDragFinish } = props;

  useEffect(() => {
    if (props.position !== undefined) {
      setPosition(props.position);
    }
  }, [props.position]);

  const handleMouseDown = useCallback(
    (e: { clientY: number }) => {
      if (!isDragging) {
        setIsDragging(true);
        setIsInside(true);
        setPreDragPosition(position);
        const newPosition = positionFromMousePosition(
          e.clientY,
          canvasRef.current!.getBoundingClientRect().y,
          canvasRef.current!.clientHeight,
        );
        setPosition(newPosition);
        if (onStateChange) onStateChange({ position: newPosition });
        if (onDragStart) onDragStart();
      }
    },
    [isDragging, position, onStateChange, onDragStart],
  );
  const handleMouseUp = useCallback(
    (_event: any) => {
      if (isDragging) {
        setIsDragging(false);
        if (isInside) {
          if (onMoveRequest) onMoveRequest({ position });
          if (onDragFinish) onDragFinish();
        }
      }
    },
    [isDragging, isInside, position, onMoveRequest, onDragFinish],
  );
  const handleMouseMove = useCallback(
    (e: { clientY: number }) => {
      if (isDragging && isInside) {
        const newPosition = positionFromMousePosition(
          e.clientY,
          canvasRef.current!.getBoundingClientRect().y,
          canvasRef.current!.clientHeight,
        );
        setPosition(newPosition);
        if (onStateChange) onStateChange({ position: newPosition });
        setPreLeavePosition(position);
      }
    },
    [isDragging, isInside, position, onStateChange],
  );
  const handleMouseLeave = useCallback(
    (_: any) => {
      if (isDragging) {
        setIsInside(false);
        setPosition(preDragPosition);
        if (onStateChange) onStateChange({ position: preDragPosition });
        if (onDragFinish) onDragFinish();
      }
    },
    [isDragging, preDragPosition, onStateChange, onDragFinish],
  );
  const handleMouseEnter = useCallback(
    (_: any) => {
      if (isDragging) {
        setIsInside(true);
        setPosition(preLeavePosition);
        if (onStateChange) onStateChange({ position: preLeavePosition });
        if (onDragStart) onDragStart();
      }
    },
    [isDragging, preLeavePosition, onStateChange, onDragStart],
  );
  const handleTouchStart = useCallback(
    (e: TouchEvent) => {
      handleMouseDown(e.touches[0]);
      e.stopPropagation();
    },
    [handleMouseDown],
  );
  const handleTouchEnd = useCallback(
    (e: TouchEvent) => {
      handleMouseUp(e);
      if (isInside) {
        e.stopPropagation();
      }
    },
    [handleMouseUp, isInside],
  );
  const handleTouchMove = useCallback(
    (e: TouchEvent) => {
      handleMouseMove(e.touches[0]);
      e.stopPropagation();
    },
    [handleMouseMove],
  );

  useEffect(() => {
    const onresize = () => {
      const canvas = canvasRef.current;
      if (!canvas) return;

      const parent = canvas.parentElement;
      if (!parent) return;

      // Handles proper resizing on MacOS and devices with a pixel ratio != 1
      const scale = window.devicePixelRatio;
      canvas.width = Math.floor(parent.clientWidth * scale);
      canvas.height = Math.floor(parent.clientHeight * scale);
    };
    window.addEventListener("resize", onresize);
    onresize();
    return () => window.removeEventListener("resize", onresize);
  }, []);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) {
      return;
    }

    window.addEventListener("mouseup", handleMouseUp);
    canvas.addEventListener("mousedown", handleMouseDown);
    canvas.addEventListener("mousemove", handleMouseMove);
    canvas.addEventListener("mouseleave", handleMouseLeave);
    canvas.addEventListener("mouseenter", handleMouseEnter);
    window.addEventListener("touchend", handleTouchEnd);
    canvas.addEventListener("touchstart", handleTouchStart);
    canvas.addEventListener("touchmove", handleTouchMove);

    return () => {
      window.removeEventListener("mouseup", handleMouseUp);
      canvas.removeEventListener("mousedown", handleMouseDown);
      canvas.removeEventListener("mousemove", handleMouseMove);
      canvas.removeEventListener("mouseleave", handleMouseLeave);
      canvas.removeEventListener("mouseenter", handleMouseEnter);
      window.removeEventListener("touchend", handleTouchEnd);
      canvas.removeEventListener("touchstart", handleTouchStart);
      canvas.removeEventListener("touchmove", handleTouchMove);
    };
  }, [
    handleMouseUp,
    handleMouseDown,
    handleMouseMove,
    handleMouseLeave,
    handleMouseEnter,
    handleTouchEnd,
    handleTouchStart,
    handleTouchMove,
  ]);

  (() => {
    const canvas = canvasRef.current;
    if (!canvas) {
      return;
    }
    const ctx = canvas.getContext("2d");
    if (!ctx) {
      return;
    }
    const scale = window.devicePixelRatio;
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.save();
    ctx.scale(scale, scale);

    const cWidth = canvas.width / scale;
    const cHeight = canvas.height / scale;

    //screen
    const gradient = ctx.createLinearGradient(0, 0, 0, cHeight);
    gradient.addColorStop(0, "rgba(255, 255, 255, 1)");
    gradient.addColorStop(0.13, "rgba(255, 255, 255, 1)");
    gradient.addColorStop(0.24, "rgba(247, 247, 247, 1)");
    gradient.addColorStop(0.4, "rgba(226, 226, 226, 1)");
    gradient.addColorStop(0.6, "rgba(193, 193, 193, 1)");
    gradient.addColorStop(0.81, "rgba(147, 147, 146, 1)");
    gradient.addColorStop(1.0, "rgba(102, 102, 102, 1)");
    ctx.save();
    ctx.translate(0, (1 - position) * -cHeight);
    ctx.fillStyle = gradient;
    ctx.save();

    ctx.fillRect(0, 0, cWidth, cHeight);
    const rectangleHeight = 3;

    ctx.fillStyle = "white";
    ctx.fillRect(0, cHeight - rectangleHeight, cWidth, rectangleHeight);
    ctx.restore();

    ctx.restore();
    ctx.restore();
  })();

  return (
    <canvas
      style={{ width: "100%", height: "100%", ...props.style }}
      ref={canvasRef}
    />
  );
};
