import { RequestOptions, apiRequest, urlEncodeObject } from "./ApiRequest";

export type ApiToken = {
  iat: number;
  token: string;
  expiresIn?: number;
  expiresAt?: Date;
};

export async function login(
  email: string,
  password: string,
): Promise<ApiToken> {
  return await apiRequest<ApiToken>(
    {
      method: "POST",
      endpoint: "/login",
      body: { login: email, password },
      contentType: "application/x-www-form-urlencoded",
    },
    { usesSession: true, changesSession: true },
  );
}

export async function federatedLogin(idToken: string): Promise<ApiToken> {
  return await apiRequest<ApiToken>(
    {
      method: "POST",
      endpoint: "/login",
      body: { idtoken: idToken },
      contentType: "application/x-www-form-urlencoded",
    },
    { usesSession: true, changesSession: true },
  );
}

export async function renewToken(): Promise<ApiToken> {
  try {
    return await apiRequest<ApiToken>(
      {
        method: "POST",
        endpoint: "/login",
      },
      { usesSession: true, changesSession: true },
    );
  } catch (err) {
    if (
      err instanceof Object &&
      "error" in err &&
      typeof err.error === "string"
    ) {
      // If renewing the token and the request fails, then the problem must be that the user is now logged in anymore, but since we can get multiple errors for that, we narrow it down to just one for easier handling
      switch (err.error) {
        case "missing_login":
        case "missing_data":
        case "missing_password":
        case "login_required":
          err.error = "login_required";
          break;
      }
    }
    throw err;
  }
}

export async function logout(): Promise<void> {
  await apiRequest(
    {
      method: "POST",
      endpoint: "/logout",
    },
    { invalidatesApiToken: true, usesSession: true, changesSession: true },
  );
}

export async function logoutEverywhere(): Promise<void> {
  await apiRequest(
    {
      method: "POST",
      endpoint: "/logout-all",
    },
    { invalidatesApiToken: true, usesSession: true, changesSession: true },
  );
}

export async function changePassword(oldPassword: string, newPassword: string) {
  await apiRequest(
    {
      method: "POST",
      endpoint: "/account/changepassword",
      body: {
        old_password: oldPassword,
        new_password: newPassword,
        confirm_password: newPassword,
      },
      contentType: "application/json",
    },
    { usesSession: true, changesSession: true },
  );
}

export type AutomationScenario = {
  name: string;
  id: number;
  activeDeviceTokens: Array<string>;
};

export type Home = {
  id: string;
  name: string;
  lat: number;
  lon: number;
  timezone: string;
};

export enum Permission {
  View = "view",
  Command = "command",
  Maintain = "maintain",
}

export type UserDevice = {
  id: string;
  revokedAt?: Date;
  magneticSensorOpenNotification?: boolean;
  magneticSensorClosedNotification?: boolean;
  lockMagneticSensorOpenNotification?: boolean;
  lockMagneticSensorClosedNotification?: boolean;
  bellTriggeredNotification?: boolean;
  weatherScenarioNotification?: boolean;
  grantedScenarioChangedNotification?: boolean;
  localScenarioNotification?: boolean;
};

type DeviceLink = {};

export type UserProfile = {
  email: string;
  confirmed: boolean;
  password_set: boolean;
  locale?: string;
  scenarios: Array<AutomationScenario>;
  homes: Record<string, Home>;
  permissions: { rolesByUser: Record<string, Array<Permission>> };
  userDevices: Array<UserDevice>;
  deviceLinks: Array<DeviceLink>;
};

export async function getUserProfile(): Promise<UserProfile> {
  return await apiRequest<UserProfile>(
    {
      method: "GET",
      endpoint: "/account/whoami",
    },
    { usesSession: true },
  );
}

export async function requestWebsocketToken(
  deviceLinkToken?: string,
): Promise<string> {
  let options: RequestOptions = { usesSession: true };
  if (deviceLinkToken) {
    options = { deviceLinkToken };
  }

  const response = await apiRequest<{ status: string; token: string }>(
    { method: "GET", endpoint: "/account/websocket/token" },
    options,
  );

  return response.token;
}

export async function revokeUserDevice(userDeviceId: string): Promise<void> {
  await apiRequest(
    {
      method: "POST",
      endpoint: "/api/v1/user-device/revoke",
      body: { onlyId: userDeviceId },
      contentType: "application/json",
    },
    { usesApiToken: true },
  );
}

export async function revokeAllUserDevices(): Promise<void> {
  await apiRequest(
    { method: "POST", endpoint: "/api/v1/user-device/revoke" },
    { usesApiToken: true },
  );
}

type EmailConfirmType = "link" | "code";

export async function register(
  email: string,
  password: string,
  confirmType: EmailConfirmType,
  locale: string = "en",
): Promise<string | null> {
  const response = await apiRequest<{ status: "success"; prefix: string }>({
    method: "POST",
    endpoint: `/register?${urlEncodeObject({ lang: locale })}`,
    body: { email, password, confirm_type: confirmType },
    contentType: "application/x-www-form-urlencoded",
  });

  if (Object.hasOwn(response, "prefix")) {
    return response["prefix"];
  }

  return null;
}

export async function resendConfirmation(
  confirmType: EmailConfirmType,
  locale: string = "en",
): Promise<string | null> {
  const response = await apiRequest<{ status: "success"; prefix: string }>(
    {
      method: "POST",
      endpoint: `/resendconfirmation?${urlEncodeObject({ lang: locale })}`,
      body: { confirm_type: confirmType },
      contentType: "application/x-www-form-urlencoded",
    },
    { usesSession: true },
  );

  if (Object.hasOwn(response, "prefix")) {
    return response["prefix"];
  }

  return null;
}

export async function confirmEmail(token: string) {
  await apiRequest({
    method: "POST",
    endpoint: `/account/${token}/confirm`,
  });
}
/**
 * Starts the account deletion process, by sending the user a link/code to confirm.
 *
 * @param confirmType - The confirm type to use, code sends a code, link sends a link.
 * @returns The prefix if confirmType = "code" was specified, nothing otherwise.
 */
export async function requestAccountDeletion(
  confirmType: EmailConfirmType,
  locale: string = "en",
): Promise<string | null> {
  const result = await apiRequest<{
    status: string;
    prefix?: string;
    message: string;
  }>(
    {
      method: "GET",
      endpoint: "/account/delete",
      query: {
        confirmtype: confirmType,
        lang: locale,
      },
    },
    { usesSession: true, changesSession: false },
  );

  if (confirmType === "code") {
    return result.prefix!;
  }

  return null;
}

export async function confirmDeletionToken(token: string): Promise<string> {
  const response = await apiRequest<{ state: string }>({
    method: "POST",
    endpoint: "/account/delete",
    body: { token },
    contentType: "application/x-www-form-urlencoded",
  });

  return response["state"];
}

export async function deleteAccount(
  state: string,
  token: string,
): Promise<void> {
  await apiRequest({
    method: "POST",
    endpoint: `/account/${token}/delete`,
    body: { state },
    contentType: "application/x-www-form-urlencoded",
  });
}

export async function requestAddPassword(
  confirmType: EmailConfirmType,
  locale: string = "en",
) {
  let result = await apiRequest<{
    status: string;
    prefix?: string;
    message: string;
  }>(
    {
      method: "GET",
      endpoint: "/account/addpassword",
      query: {
        confirmtype: confirmType,
        lang: locale,
      },
    },
    { usesSession: true },
  );

  if (confirmType === "code") {
    return result.prefix!;
  }

  return null;
}

export async function addPassword(request: string, password: string) {
  await apiRequest({
    method: "POST",
    endpoint: "/account/addpassword",
    body: {
      request,
      password,
    },
  });
}

export async function requestPasswordReset(
  email: string,
  confirmType: EmailConfirmType,
  locale: string = "en",
): Promise<string | null> {
  const response = await apiRequest<{
    status: "success";
    prefix: string;
  }>({
    method: "GET",
    endpoint: "/account/resetpassword",
    query: {
      email,
      confirmtype: confirmType,
      lang: locale,
    },
  });

  if (confirmType === "code") {
    return response.prefix;
  }

  return null;
}

export async function confirmPasswordResetRequest(
  token: string,
): Promise<string> {
  const response = await apiRequest<{ state: string }>({
    method: "POST",
    endpoint: "/account/reset",
    body: {
      token,
    },
    contentType: "application/x-www-form-urlencoded",
  });

  return response.state;
}

export async function resetPassword(
  token: string,
  state: string,
  newPassword: string,
) {
  await apiRequest({
    method: "POST",
    endpoint: `/account/${token}/reset`,
    body: {
      state,
      new_password: newPassword,
    },
    contentType: "application/x-www-form-urlencoded",
  });
}
