import React, {
  CSSProperties,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { AngleSlider } from "./AngleSlider";
import "./DeviceCanvas.css";

export enum InclinationType {
  LowerHalf,
  UpperHalf,
  Full,
}

export const VenetianBlindSideViewCanvas: React.FC<{
  rotation?: number;
  onMoveRequest?: (args: { position?: number; rotation?: number }) => void;
  onStateChange?: (args: { position?: number; rotation?: number }) => void;
  onDragStart?: () => void;
  onDragFinish?: () => void;
  inclinationtype?: InclinationType;
  style?: CSSProperties;
}> = ({ ...props }) => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const [rotation, setRotation] = useState(props.rotation ?? 0.0);
  const [canvasArea, setCanvasArea] = useState({
    width: 1,
    height: 1,
    scale: 1,
  });
  const [isDragging, setIsDragging] = useState(false);
  const [isInside, setIsInside] = useState(false);
  const [preDragRotation, setPreDragRotation] = useState(0.0);
  const [preLeaveRotation, setPreLeaveRotation] = useState(0.0);
  const { onMoveRequest, onStateChange, onDragStart, onDragFinish } = props;

  useEffect(() => {
    if (props.rotation !== undefined) {
      setRotation(props.rotation);
    }
  }, [props.rotation]);

  const [lastPropRotation, setLastPropRotation] = useState(props.rotation);
  if (lastPropRotation !== props.rotation && props.rotation !== undefined) {
    setLastPropRotation(props.rotation);
    setRotation(props.rotation);
  }

  const onResize = useCallback(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const parent = canvas.parentElement;
    if (!parent) return;

    const scale = window.devicePixelRatio;
    canvas.width = Math.floor(parent.clientWidth * scale);
    canvas.height = Math.floor(parent.clientHeight * scale);
    setCanvasArea({ width: canvas.width, height: canvas.height, scale });
  }, [canvasRef]);

  const handleMouseDown = useCallback(
    (e: { clientY: number }) => {
      if (!isDragging) {
        setIsDragging(true);
        setIsInside(true);
        setPreDragRotation(rotation);
        // TODO: Set value
        if (onStateChange) onStateChange({ rotation });
        if (onDragStart) onDragStart();
      }
    },
    [isDragging, rotation, onStateChange, onDragStart],
  );
  const handleMouseUp = useCallback(
    (_: any) => {
      if (isDragging) {
        setIsDragging(false);
        if (isInside) {
          if (onMoveRequest) onMoveRequest({ rotation });
          if (onDragFinish) onDragFinish();
        }
      }
    },
    [isDragging, isInside, rotation, onMoveRequest, onDragFinish],
  );
  const handleMouseMove = useCallback(
    (e: { clientY: number }) => {
      if (isDragging && isInside) {
        // TODO: Set value
        if (onStateChange) onStateChange({ rotation });
        setPreLeaveRotation(rotation);
      }
    },
    [isDragging, isInside, rotation, onStateChange],
  );
  const handleMouseLeave = useCallback(
    (_: any) => {
      if (isDragging) {
        setIsInside(false);
        setRotation(preDragRotation);
        if (onStateChange) onStateChange({ rotation });
        if (onDragFinish) onDragFinish();
      }
    },
    [isDragging, preDragRotation, rotation, onStateChange, onDragFinish],
  );
  const handleMouseEnter = useCallback(
    (_: any) => {
      if (isDragging) {
        setIsInside(true);
        setRotation(preLeaveRotation);
        if (onStateChange) onStateChange({ rotation });
        if (onDragStart) onDragStart();
      }
    },
    [isDragging, preLeaveRotation, rotation, onStateChange, onDragStart],
  );
  const handleTouchStart = useCallback(
    (e: TouchEvent) => {
      handleMouseDown(e.touches[0]);
      e.stopPropagation();
    },
    [handleMouseDown],
  );
  const handleTouchEnd = useCallback(
    (e: TouchEvent) => {
      handleMouseUp(e);
      if (isInside) {
        e.stopPropagation();
      }
    },
    [handleMouseUp, isInside],
  );
  const handleTouchMove = useCallback(
    (e: TouchEvent) => {
      handleMouseMove(e.touches[0]);
      e.stopPropagation();
    },
    [handleMouseMove],
  );

  useEffect(() => {
    window.addEventListener("resize", onResize);
    onResize();
    return () => window.removeEventListener("resize", onResize);
  }, [onResize]);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) {
      return;
    }
    const ctx = canvas.getContext("2d");
    if (!ctx) {
      return;
    }

    const scale = window.devicePixelRatio;
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.save();

    const venetianBlindSideSlate = (
      ctx: CanvasRenderingContext2D,
      offsetX: number,
      strokeWidth: number,
      strokeColor: string,
    ) => {
      ctx.beginPath();
      ctx.lineWidth = 1;
      ctx.strokeStyle = "black";
      ctx.moveTo(offsetX + 10, 0);
      ctx.lineTo(offsetX + 10, ctx.canvas.height);
      ctx.stroke();
      ctx.closePath();
      ctx.lineWidth = strokeWidth;
      ctx.strokeStyle = strokeColor;
      const numberOfCurves = 10;
      var curveSegmentHeight = ctx.canvas.height / numberOfCurves;
      let angleDeg: number;

      switch (props.inclinationtype) {
        case InclinationType.LowerHalf:
          angleDeg = 90 - rotation * 90;
          break;
        case InclinationType.UpperHalf:
          angleDeg = -rotation * 90;
          break;
        case InclinationType.Full:
          angleDeg = -rotation * 180;
          break;
        default:
          angleDeg = -rotation * 90;
          break;
      }

      for (var i = 0; i < numberOfCurves; i++) {
        var startX = offsetX + 10;
        var startY = i * curveSegmentHeight + curveSegmentHeight / 2;

        ctx.save();
        ctx.translate(startX, startY);
        ctx.rotate((-angleDeg! * Math.PI) / 180);
        ctx.translate(-startX, -startY);
        ctx.beginPath();
        startY = startY - curveSegmentHeight / 2;
        ctx.moveTo(startX, startY);

        var controlPoint1X = startX - 20;
        var controlPoint1Y = startY + curveSegmentHeight * 0.33;
        var controlPoint2X = startX + 20;
        var controlPoint2Y = startY + curveSegmentHeight * 0.67;
        var endX = startX;
        var endY = startY + curveSegmentHeight;

        ctx.bezierCurveTo(
          controlPoint1X,
          controlPoint1Y,
          controlPoint2X,
          controlPoint2Y,
          endX,
          endY,
        );
        ctx.stroke();
        ctx.closePath();
        ctx.restore();
      }
    };

    ctx.clearRect(0, 0, canvas.width, canvas.height);

    //background
    ctx.fillStyle = "rgba(0, 0, 0, 0.2)";
    venetianBlindSideSlate(ctx, 75, 8, "white");
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    ctx.restore();
  }, [rotation, canvasArea, props.inclinationtype]);

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        position: "relative",
        ...props.style,
      }}
    >
      <canvas
        style={{
          position: "absolute",
          left: 0,
          top: 0,
        }}
        className="device-canvas"
        ref={canvasRef}
      />
      <AngleSlider
        style={{
          width: "100%",
          height: "100%",
          touchAction: "none",
          position: "absolute",
          right: 0,
          top: 0,
        }}
        value={rotation}
        onValueChange={(val) => setRotation(val)}
        onValueSelected={(val) => onMoveRequest?.({ rotation: val })}
        maxDownAngle={90}
        maxUpAngle={90}
      />
    </div>
  );
};
