import React, { useEffect, useRef, useState } from "react";
import { deviceInfo } from "../adcApi";
import Layout from "../components/Layout";
import ArrowUpwardOutlinedIcon from "@mui/icons-material/ArrowUpwardOutlined";
import ArrowDownwardOutlinedIcon from "@mui/icons-material/ArrowDownwardOutlined";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import IconAllUp from "../assets/icon_all_up.svg";
import IconAllDown from "../assets/icon_all_down.svg";
import TestRoleta from "../assets/roleta_test2.png";
import BackIcon from "../assets/icon_back.svg";
import { useHistory } from "react-router-dom";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useTranslation } from "react-i18next";

type DeviceInfo = {
  name: string;
  token: string;
  devicetype: string;
  inclination: number;
  position: number;
};

interface SaveDialogProps {
  open: boolean;
  onClose: () => void;
  onSave: () => void;
}

export const DeviceFavouritePage = () => {
  return (
    <>
      <Layout>
        <DeviceFavourite />
      </Layout>
    </>
  );
};

function DeviceFavourite() {
  const navigate = useHistory();
  const { t } = useTranslation();
  const [isSaveDialogOpen, setIsSaveDialog] = useState(false);

  const saveClicked = () => {
    navigate.goBack();
  };

  const openSaveDialog = () => {
    setIsSaveDialog(true);
  };

  const closeSaveDialog = () => {
    setIsSaveDialog(false);
  };

  //TODO: Prikaži samo ko uporabnik naredi spremembe
  useEffect(() => {
    let unblock = navigate.block((location, action) => {
      if (!isSaveDialogOpen) {
        openSaveDialog();
        return false;
      }
    });
    return unblock;
  }, [isSaveDialogOpen]);

  //const [deviceInfo, setDeviceInfo] = useState<DeviceInfo[]>();
  return (
    <div className="device-list-container">
      <div className="App">
        <header className="App-body">
          <div className="rectangles-container">
            <div className="device-header-grid">
              <div>
                <img
                  src={BackIcon}
                  className="back-icon"
                  onClick={openSaveDialog}
                />
              </div>
              <div>{t("button_favorite")}</div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  textAlign: "center",
                  paddingRight: "20px",
                  cursor: "pointer",
                }}
                onClick={() => navigate.goBack()}
              >
                <SaveOutlinedIcon fontSize="large" />
                <span>{t("button_save")}</span>
              </div>
            </div>
            <div className="device-menu-two-item-grid">
              <div>
                <img src={IconAllUp} style={{ height: 35 }}></img>
                <span>{t("button_open")}</span>
              </div>
              <div>
                <img src={IconAllDown} style={{ height: 35 }}></img>
                <span>{t("button_close")}</span>
              </div>
            </div>
          </div>

          <div className="device-content">
            <img src={TestRoleta}></img>
            <div className="small-square">
              <ArrowDownwardOutlinedIcon />
            </div>
          </div>
        </header>
      </div>

      {isSaveDialogOpen && (
        <SaveDialog
          open={isSaveDialogOpen}
          onClose={closeSaveDialog}
          onSave={saveClicked}
        />
      )}
    </div>
  );
}

function SaveDialog({ open, onClose, onSave }: SaveDialogProps) {
  const { t } = useTranslation();

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{t("dialog_confirmation")}</DialogTitle>
      <DialogContent>
        {t("dialog_confirm_savePosition").replace("{0}", t("button_favorite"))}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {t("button_cancel")}
        </Button>
        <Button onClick={onSave} color="primary">
          {t("button_save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
