import React, { useCallback, useEffect, useRef, useState } from "react";
import "./DeviceCanvas.css";

function positionFromMousePosition(
  mouseY: number,
  canvasY: number,
  canvasUnscaledHeight: number,
) {
  const actualPosition = Math.min(
    Math.max((mouseY - canvasY) / canvasUnscaledHeight, 0.0),
    1.0,
  );

  const reservedPercentage = 0.02;

  return Math.min(
    Math.max(
      actualPosition / (1.0 - reservedPercentage * 2) - reservedPercentage,
      0.0,
    ),
    1.0,
  );
}

type CanvasProps = React.DetailedHTMLProps<
  React.CanvasHTMLAttributes<HTMLCanvasElement>,
  HTMLCanvasElement
>;

export const RollerShutterCanvas: React.FC<
  {
    position?: number;
    onMoveRequest?: (args: { position?: number }) => void;
    onStateChange?: (args: { position?: number }) => void;
    onDragStart?: () => void;
    onDragFinish?: () => void;
  } & CanvasProps
> = ({ ...props }) => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [isInside, setIsInside] = useState(false);
  const [preDragPosition, setPreDragPosition] = useState(0.0);
  const [preLeavePosition, setPreLeavePosition] = useState(0.0);
  const [position, setPosition] = useState(props.position ?? 0.0);
  const { onMoveRequest, onStateChange, onDragStart, onDragFinish } = props;

  useEffect(() => {
    if (props.position !== undefined) {
      setPosition(props.position);
    }
  }, [props.position]);

  const handleMouseDown = useCallback(
    (e: { clientY: number }) => {
      if (!isDragging) {
        setIsDragging(true);
        setIsInside(true);
        setPreDragPosition(position);
        const newPosition = positionFromMousePosition(
          e.clientY,
          canvasRef.current!.getBoundingClientRect().y,
          canvasRef.current!.clientHeight,
        );
        setPosition(newPosition);
        if (onStateChange) onStateChange({ position: newPosition });
        if (onDragStart) onDragStart();
      }
    },
    [isDragging, position, onStateChange, onDragStart],
  );
  const handleMouseUp = useCallback(
    (_event: any) => {
      if (isDragging) {
        setIsDragging(false);
        if (isInside) {
          if (onMoveRequest) onMoveRequest({ position });
          if (onDragFinish) onDragFinish();
        }
      }
    },
    [isDragging, isInside, position, onMoveRequest, onDragFinish],
  );
  const handleMouseMove = useCallback(
    (e: { clientY: number }) => {
      if (isDragging && isInside) {
        const newPosition = positionFromMousePosition(
          e.clientY,
          canvasRef.current!.getBoundingClientRect().y,
          canvasRef.current!.clientHeight,
        );
        setPosition(newPosition);
        if (onStateChange) onStateChange({ position: newPosition });
        setPreLeavePosition(position);
      }
    },
    [isDragging, isInside, position, onStateChange],
  );
  const handleMouseLeave = useCallback(
    (_: any) => {
      if (isDragging) {
        setIsInside(false);
        setPosition(preDragPosition);
        if (onStateChange) onStateChange({ position: preDragPosition });
        if (onDragFinish) onDragFinish();
      }
    },
    [isDragging, preDragPosition, onStateChange, onDragFinish],
  );
  const handleMouseEnter = useCallback(
    (_: any) => {
      if (isDragging) {
        setIsInside(true);
        setPosition(preLeavePosition);
        if (onStateChange) onStateChange({ position: preLeavePosition });
        if (onDragStart) onDragStart();
      }
    },
    [isDragging, preLeavePosition, onStateChange, onDragStart],
  );
  const handleTouchStart = useCallback(
    (e: TouchEvent) => {
      handleMouseDown(e.touches[0]);
      e.stopPropagation();
    },
    [handleMouseDown],
  );
  const handleTouchEnd = useCallback(
    (e: TouchEvent) => {
      handleMouseUp(e);
      if (isInside) {
        e.stopPropagation();
      }
    },
    [handleMouseUp, isInside],
  );
  const handleTouchMove = useCallback(
    (e: TouchEvent) => {
      handleMouseMove(e.touches[0]);
      e.stopPropagation();
    },
    [handleMouseMove],
  );

  useEffect(() => {
    const onresize = () => {
      const canvas = canvasRef.current;
      if (!canvas) return;

      const parent = canvas.parentElement;
      if (!parent) return;

      // Handles proper resizing on MacOS and devices with a pixel ratio != 1
      const scale = window.devicePixelRatio;
      canvas.width = Math.floor(parent.clientWidth * scale);
      canvas.height = Math.floor(parent.clientHeight * scale);
    };
    window.addEventListener("resize", onresize);
    onresize();
    return () => window.removeEventListener("resize", onresize);
  }, []);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) {
      return;
    }

    window.addEventListener("mouseup", handleMouseUp);
    canvas.addEventListener("mousedown", handleMouseDown);
    canvas.addEventListener("mousemove", handleMouseMove);
    canvas.addEventListener("mouseleave", handleMouseLeave);
    canvas.addEventListener("mouseenter", handleMouseEnter);
    window.addEventListener("touchend", handleTouchEnd);
    canvas.addEventListener("touchstart", handleTouchStart);
    canvas.addEventListener("touchmove", handleTouchMove);

    return () => {
      window.removeEventListener("mouseup", handleMouseUp);
      canvas.removeEventListener("mousedown", handleMouseDown);
      canvas.removeEventListener("mousemove", handleMouseMove);
      canvas.removeEventListener("mouseleave", handleMouseLeave);
      canvas.removeEventListener("mouseenter", handleMouseEnter);
      window.removeEventListener("touchend", handleTouchEnd);
      canvas.removeEventListener("touchstart", handleTouchStart);
      canvas.removeEventListener("touchmove", handleTouchMove);
    };
  }, [
    handleMouseUp,
    handleMouseDown,
    handleMouseMove,
    handleMouseLeave,
    handleMouseEnter,
    handleTouchEnd,
    handleTouchStart,
    handleTouchMove,
  ]);

  (() => {
    const canvas = canvasRef.current;
    if (!canvas) {
      return;
    }
    const ctx = canvas.getContext("2d");
    if (!ctx) {
      return;
    }
    const scale = window.devicePixelRatio;
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.save();
    ctx.scale(scale, scale);

    const cWidth = canvas.width / scale;
    const cHeight = canvas.height / scale;

    // Create roller shutter canvas
    const gradientRectWidth = cWidth;
    const gradientRectHeight = cHeight * 0.05; // 54 -> velikost 1 lamele
    const gradientRectHeight2 = cHeight * 0.011; // 11 -> velikost prostora med lamelam
    const totalSlateHeight = gradientRectHeight + gradientRectHeight2; // 65 -> velikost obeh skupaj

    const percentage = position * 100;

    const botPos =
      cHeight * Math.min(Math.max(position / 0.95, 0.0), 1.0) -
      totalSlateHeight;
    let slateDrawCount = Math.ceil(botPos / totalSlateHeight) + 1;
    let squishedSpace = 0;

    function drawSlate1(
      ctx: CanvasRenderingContext2D,
      gradientRectHeight: number,
    ) {
      const gradient = ctx.createLinearGradient(0, 0, 0, gradientRectHeight);
      gradient.addColorStop(0, "rgba(255, 255, 255, 255)");
      gradient.addColorStop(1, "rgba(114, 114, 114, 255)");

      ctx.fillStyle = gradient;
      ctx.fillRect(
        0,
        gradientRectHeight2, //namesto 0 daš gradientRectHeight2
        gradientRectWidth,
        gradientRectHeight,
      );
    }

    function drawSlate2(
      ctx: CanvasRenderingContext2D,
      gradientRectHeight2: number,
      canvasWidth: number,
    ) {
      const tempCanvas = document.createElement("canvas");
      const tempCtx = tempCanvas.getContext("2d");

      ctx.fillStyle = "rgba(222, 223, 222, 255)";
      ctx.fillRect(0, 0, gradientRectWidth, gradientRectHeight2);
      if (!tempCtx) {
        return;
      }

      tempCanvas.width = canvasWidth;
      tempCanvas.height = gradientRectHeight2;

      // Draw ellipses
      const ellipseWidth = 4;
      const ellipseHeight = 1.5;
      const numberOfEllipses = Math.floor(canvasWidth / (ellipseWidth * 2));
      const ellipseSpacing = 20;
      const offset = 5;

      tempCtx.fillStyle = "rgba(0, 0, 0, 1)";
      for (let i = 0; i < numberOfEllipses; i++) {
        const ellipseX = i * ellipseSpacing + offset;
        const ellipseY = gradientRectHeight2 / 2;
        tempCtx.beginPath();
        tempCtx.ellipse(
          ellipseX,
          ellipseY,
          ellipseWidth,
          ellipseHeight,
          0,
          0,
          2 * Math.PI,
        );
        tempCtx.fill();
      }

      // Cut out the ellipses
      ctx.globalCompositeOperation = "destination-out";
      ctx.drawImage(tempCanvas, 0, 0);
      ctx.globalCompositeOperation = "source-over";
    }

    if (position - 0.95 > 0) {
      const maxVentilationSpace =
        (cHeight / gradientRectHeight) * gradientRectHeight2;
      const ventSpace = ((position - 0.95) / 0.05) * maxVentilationSpace;
      squishedSpace = ventSpace;

      slateDrawCount += Math.ceil(squishedSpace / gradientRectHeight);
    }
    ctx.save();
    ctx.translate(0, botPos);

    for (let i = 0; i < slateDrawCount; i++) {
      const canvasWidth = cWidth;
      if (squishedSpace < gradientRectHeight2) {
        drawSlate2(ctx, gradientRectHeight2, canvasWidth);
      }
      drawSlate1(ctx, gradientRectHeight); //odstrani gradientRectHeight - gradientRectHeight2 in nadomesti samo z gradientRectHeight
      if (squishedSpace == 0) {
        ctx.translate(0, -totalSlateHeight);
      } else {
        if (squishedSpace >= gradientRectHeight2) {
          ctx.translate(0, -gradientRectHeight);
          squishedSpace -= gradientRectHeight2;
        } else {
          ctx.translate(0, -(totalSlateHeight - squishedSpace));
          squishedSpace = 0;
        }
      }
    }
    ctx.restore();
    ctx.restore();
  })();

  return (
    <canvas className="device-canvas" style={props.style} ref={canvasRef} />
  );
};
