import { LoginApiErrorId, OtherApiErrorId, isApiError } from "../api/ApiError";
import i18n from "../i18n";

export const getErrorMessage = (error: unknown): string => {
  if (isApiError(error)) {
    switch (error.error) {
      case OtherApiErrorId.DEVICE_UNREACHABLE:
        return i18n.t("dialog_error_deviceUnreachable");
      case OtherApiErrorId.INSUFFICIENT_PERMISSION:
        return i18n.t("dialog_alert_insufficientPermissionForAction");
      case OtherApiErrorId.DEVICE_NAME_IN_USE:
        return i18n.t("dialog_error_deviceNameInUse");
      case OtherApiErrorId.RESOURCE_NOT_FOUND:
        // Depends on where this error occurs.
        break;
      case OtherApiErrorId.DEVICE_UNSUPPORTED_ACTION:
        return i18n.t("dialog_error_deviceActionNotSupported");
      case OtherApiErrorId.OPEN_WEATHER_SUBSCRIPTION_NEEDED:
        // TODO: Weather subscription text contains a question about subscribing now, but we don't support that over the online web portal. Change when this error will actually be needed.
        return "A weather subscription is required for this action.";
      case OtherApiErrorId.WEATHER_SCENARIO_MATCHED:
        return i18n.t("dialog_prompt_weatherScenarioActiveSafety");
      case OtherApiErrorId.SIMILAR_WEATHER_SCENARIO_ALREADY_EXISTS:
        return i18n.t("dialog_alert_oneDevicePerWeatherScenario");
      case OtherApiErrorId.ROLE_ALREADY_EXISTS:
        return i18n.t("dialog_alert_roleWithNameAlreadyExists");
      case LoginApiErrorId.DATA_MISSING:
      case LoginApiErrorId.MISSING_LOGIN:
      case LoginApiErrorId.MISSING_PASSWORD:
        return i18n.t("dialog_alert_loginRequired");
      case LoginApiErrorId.INVALID_CREDENTIALS:
      case LoginApiErrorId.API_TOKEN_MISSING:
      case LoginApiErrorId.API_TOKEN_INVALID:
      case LoginApiErrorId.LOGIN_REQUIRED:
        return i18n.t("dialog_alert_loginRequired");
      case LoginApiErrorId.CONFIRMATION_CODE_EXPIRED:
        return i18n.t("dialog_error_confirmationCodeInvalid");
      case LoginApiErrorId.CONFIRMATION_INVALID:
        return i18n.t("dialog_error_confirmationLinkExpired");
      case LoginApiErrorId.ACCOUNT_NOT_FOUND:
      case LoginApiErrorId.ACCOUNT_ALREADY_CONFIRMED:
      case LoginApiErrorId.ACCOUNT_CONFIRMATION_REQUIRED:
        return i18n.t("dialog_error_confirmAccountForAction");
      case OtherApiErrorId.INTERNAL_ERROR:
      case OtherApiErrorId.BAD_COMMAND_KEY:
      case OtherApiErrorId.BAD_COMMAND_TYPE:
      case OtherApiErrorId.BAD_COMMAND_VALUE:
      case OtherApiErrorId.MULTIPLE_COMMAND_VALUES:
      case OtherApiErrorId.NO_COMMANDS_FOUND:
      case OtherApiErrorId.INVALID_COMMAND:
      case OtherApiErrorId.MALFORMED_REQUEST:
      case OtherApiErrorId.MALFORMED_TRIGGER:
      case OtherApiErrorId.MALFORMED_ACTION:
      case OtherApiErrorId.MALFORMED_SCENARIO:
        return i18n.t("dialog_error_generic");
      case LoginApiErrorId.OTHER_ACCOUNT_CONFIRMATION_REQUIRED:
      case LoginApiErrorId.EMAIL_ALREADY_IN_USE:
      case LoginApiErrorId.PASSWORD_NOT_STRONG_ENOUGH:
      case LoginApiErrorId.PASSWORD_ALREADY_SET:
      case OtherApiErrorId.ROLE_USERS_LIMIT_EXCEEDED:
      case OtherApiErrorId.HOME_ALREADY_EXISTS:
      case OtherApiErrorId.PUBLIC_KEY_ALREADY_EXISTS:
      case OtherApiErrorId.DEVICE_LINKS_LIMIT_EXCEEDED:
      case OtherApiErrorId.COMMAND_PARAMS_INVALID:
      case OtherApiErrorId.DEVICE_NOT_EXIST:
      case OtherApiErrorId.DEVICE_NOT_READY:
      case OtherApiErrorId.DEVICE_OFFLINE:
      case OtherApiErrorId.DEVICE_REQUEST_TIMEOUT:
      case OtherApiErrorId.DEVICE_SCENARIOS_LIMIT_EXCEEDED:
      case OtherApiErrorId.PUBLIC_KEY_INVALID:
      case OtherApiErrorId.SUBSCRIPTION_ALREADY_EXISTS:
      case OtherApiErrorId.DEVICE_TOO_MANY_SCENARIOS:
      case OtherApiErrorId.DEVICE_UPDATE_FAILED:
        break;
      case OtherApiErrorId.DEVICE_BUSY:
      case OtherApiErrorId.DEVICE_NOT_BUSY:
      case OtherApiErrorId.DEVICE_ALREADY_IN_STATE:
      case OtherApiErrorId.DEVICE_NOT_RESPONDING:
        break;
      default:
        break;
    }

    return i18n
      .t("dialog_alert_errorOccured")
      .replace("{0}", error.error_description);
  }

  return i18n.t("dialog_error_generic");
};
