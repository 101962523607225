import React from "react";
import "./Loading.css";

type DivProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;

export type LoadingProps = {
  style?: React.CSSProperties;
  spinnerSize?: React.CSSProperties["width"] & React.CSSProperties["height"];
  spinnerThickness?: React.CSSProperties["borderWidth"];
} & DivProps;

export const Loading: React.FC<LoadingProps> = ({
  spinnerSize,
  spinnerThickness,
  ...props
}) => {
  const finalClassName = ["loading-overlay", props.className].join(" ");
  return (
    <div {...props} className={finalClassName}>
      <div
        className="loading-spinner"
        style={{
          borderWidth: spinnerThickness ?? "7px",
          width: spinnerSize ?? "60px",
          height: spinnerSize ?? "60px",
        }}
      />
    </div>
  );
};
