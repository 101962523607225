import React, { useCallback, useEffect, useRef, useState } from "react";
import "./DeviceCanvas.css";

function positionFromMousePosition(
  mouseY: number,
  canvasY: number,
  canvasUnscaledHeight: number,
) {
  const actualPosition = Math.min(
    Math.max((mouseY - canvasY) / canvasUnscaledHeight, 0.0),
    1.0,
  );

  const reservedPercentage = 0.02;

  return Math.min(
    Math.max(
      actualPosition / (1.0 - reservedPercentage * 2) - reservedPercentage,
      0.0,
    ),
    1.0,
  );
}

type CanvasProps = React.DetailedHTMLProps<
  React.CanvasHTMLAttributes<HTMLCanvasElement>,
  HTMLCanvasElement
>;

export const AwningCanvas: React.FC<
  {
    position?: number;
    onMoveRequest?: (args: { position?: number }) => void;
    onStateChange?: (args: { position?: number }) => void;
    onDragStart?: () => void;
    onDragFinish?: () => void;
  } & CanvasProps
> = ({ ...props }) => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [isInside, setIsInside] = useState(false);
  const [preDragPosition, setPreDragPosition] = useState(0.0);
  const [preLeavePosition, setPreLeavePosition] = useState(0.0);
  const [position, setPosition] = useState(props.position ?? 0.0);
  const { onMoveRequest, onStateChange, onDragStart, onDragFinish } = props;

  useEffect(() => {
    if (props.position !== undefined) {
      setPosition(props.position);
    }
  }, [props.position]);

  const handleMouseDown = useCallback(
    (e: { clientY: number }) => {
      if (!isDragging) {
        setIsDragging(true);
        setIsInside(true);
        setPreDragPosition(position);
        const newPosition = positionFromMousePosition(
          e.clientY,
          canvasRef.current!.getBoundingClientRect().y,
          canvasRef.current!.clientHeight,
        );
        setPosition(newPosition);
        if (onStateChange) onStateChange({ position: newPosition });
        if (onDragStart) onDragStart();
      }
    },
    [isDragging, position, onStateChange, onDragStart],
  );
  const handleMouseUp = useCallback(
    (_event: any) => {
      if (isDragging) {
        setIsDragging(false);
        if (isInside) {
          if (onMoveRequest) onMoveRequest({ position });
          if (onDragFinish) onDragFinish();
        }
      }
    },
    [isDragging, isInside, position, onMoveRequest, onDragFinish],
  );
  const handleMouseMove = useCallback(
    (e: { clientY: number }) => {
      if (isDragging && isInside) {
        const newPosition = positionFromMousePosition(
          e.clientY,
          canvasRef.current!.getBoundingClientRect().y,
          canvasRef.current!.clientHeight,
        );
        setPosition(newPosition);
        if (onStateChange) onStateChange({ position: newPosition });
        setPreLeavePosition(position);
      }
    },
    [isDragging, isInside, position, onStateChange],
  );
  const handleMouseLeave = useCallback(
    (_: any) => {
      if (isDragging) {
        setIsInside(false);
        setPosition(preDragPosition);
        if (onStateChange) onStateChange({ position: preDragPosition });
        if (onDragFinish) onDragFinish();
      }
    },
    [isDragging, preDragPosition, onStateChange, onDragFinish],
  );
  const handleMouseEnter = useCallback(
    (_: any) => {
      if (isDragging) {
        setIsInside(true);
        setPosition(preLeavePosition);
        if (onStateChange) onStateChange({ position: preLeavePosition });
        if (onDragStart) onDragStart();
      }
    },
    [isDragging, preLeavePosition, onStateChange, onDragStart],
  );
  const handleTouchStart = useCallback(
    (e: TouchEvent) => {
      handleMouseDown(e.touches[0]);
      e.stopPropagation();
    },
    [handleMouseDown],
  );
  const handleTouchEnd = useCallback(
    (e: TouchEvent) => {
      handleMouseUp(e);
      if (isInside) {
        e.stopPropagation();
      }
    },
    [handleMouseUp, isInside],
  );
  const handleTouchMove = useCallback(
    (e: TouchEvent) => {
      handleMouseMove(e.touches[0]);
      e.stopPropagation();
    },
    [handleMouseMove],
  );

  useEffect(() => {
    const onresize = () => {
      const canvas = canvasRef.current;
      if (!canvas) return;

      const parent = canvas.parentElement;
      if (!parent) return;

      // Handles proper resizing on MacOS and devices with a pixel ratio != 1
      const scale = window.devicePixelRatio;
      canvas.width = Math.floor(parent.clientWidth * scale);
      canvas.height = Math.floor(parent.clientHeight * scale);
    };
    window.addEventListener("resize", onresize);
    onresize();
    return () => window.removeEventListener("resize", onresize);
  }, []);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) {
      return;
    }

    window.addEventListener("mouseup", handleMouseUp);
    canvas.addEventListener("mousedown", handleMouseDown);
    canvas.addEventListener("mousemove", handleMouseMove);
    canvas.addEventListener("mouseleave", handleMouseLeave);
    canvas.addEventListener("mouseenter", handleMouseEnter);
    window.addEventListener("touchend", handleTouchEnd);
    canvas.addEventListener("touchstart", handleTouchStart);
    canvas.addEventListener("touchmove", handleTouchMove);

    return () => {
      window.removeEventListener("mouseup", handleMouseUp);
      canvas.removeEventListener("mousedown", handleMouseDown);
      canvas.removeEventListener("mousemove", handleMouseMove);
      canvas.removeEventListener("mouseleave", handleMouseLeave);
      canvas.removeEventListener("mouseenter", handleMouseEnter);
      window.removeEventListener("touchend", handleTouchEnd);
      canvas.removeEventListener("touchstart", handleTouchStart);
      canvas.removeEventListener("touchmove", handleTouchMove);
    };
  }, [
    handleMouseUp,
    handleMouseDown,
    handleMouseMove,
    handleMouseLeave,
    handleMouseEnter,
    handleTouchEnd,
    handleTouchStart,
    handleTouchMove,
  ]);

  (() => {
    const canvas = canvasRef.current;
    if (!canvas) {
      return;
    }
    const ctx = canvas.getContext("2d");
    if (!ctx) {
      return;
    }

    const scale = window.devicePixelRatio;
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.save();
    ctx.scale(scale, scale);

    const cWidth = canvas.width / scale;
    const cHeight = canvas.height / scale;

    //awning
    const gradientRectWidth = cWidth * 0.05;
    const gradientRectHeight = cHeight - 20;
    const totalRectangles = cWidth / gradientRectWidth;
    const curveHeight = 40;

    function drawGradientRectangle(
      ctx: CanvasRenderingContext2D,
      startX: number,
      gradientRectWidth: number,
    ) {
      const gradient = ctx.createLinearGradient(
        startX,
        0,
        startX + gradientRectWidth,
        0,
      );
      gradient.addColorStop(0.28, "rgb(236, 236, 236)");
      gradient.addColorStop(0.7, "rgb(234, 234, 234)");
      gradient.addColorStop(0.89, "rgb(227, 227, 227)");
      gradient.addColorStop(1.0, "rgb(217, 217, 217)");

      ctx.fillStyle = gradient;
      ctx.fillRect(startX, 0, gradientRectWidth, gradientRectHeight);
    }

    function drawCurvedGradient(
      ctx: CanvasRenderingContext2D,
      startX: number,
      gradientRectWidth: number,
      gradientRectHeight: number,
      curveHeight: number,
    ) {
      const gradient = ctx.createLinearGradient(
        startX + gradientRectWidth,
        0,
        startX,
        0,
      );
      ctx.beginPath();
      ctx.moveTo(startX, gradientRectHeight);
      ctx.quadraticCurveTo(
        startX + gradientRectWidth / 2,
        gradientRectHeight + curveHeight,
        startX + gradientRectWidth,
        gradientRectHeight,
      );
      gradient.addColorStop(0.28, "rgb(236, 236, 236)");
      gradient.addColorStop(0.7, "rgb(234, 234, 234)");
      gradient.addColorStop(0.89, "rgb(227, 227, 227)");
      gradient.addColorStop(1.0, "rgb(217, 217, 217)");
      ctx.fillStyle = gradient;
      ctx.fill();
    }
    ctx.save();
    ctx.translate(0, (1 - position) * -cHeight);

    for (let i = 0; i < totalRectangles; i++) {
      const startX = i * gradientRectWidth;
      drawGradientRectangle(ctx, startX, gradientRectWidth);
      drawCurvedGradient(
        ctx,
        startX,
        gradientRectWidth,
        gradientRectHeight,
        curveHeight,
      );
    }
    ctx.restore();
    ctx.restore();
  })();

  return (
    <canvas className="device-canvas" style={props.style} ref={canvasRef} />
  );
};
