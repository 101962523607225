import React, { useState } from "react";
import logoImage from "../assets/adc_home_white.png";
import { useHistory } from "react-router-dom";
import Button from "@mui/material/Button";
import { Alert, Snackbar, TextField } from "@mui/material";
import RestoreIcon from "@mui/icons-material/Restore";
import BackIcon from "../assets/icon_back.svg";
import { useTranslation } from "react-i18next";
import { requestPasswordReset } from "../api/AccountApi";
import { ApiError } from "../api/ApiError";

export const ForgottenPasswordPage = () => {
  const navigate = useHistory();
  const { t } = useTranslation();

  const [email, setEmail] = useState("");
  const [isEmailSentShown, setIsEmailSentShown] = useState(false);
  const [isErrorSnackbarShown, setIsErrorSnackbarShown] = useState(false);
  const [errorSnackbarMessage, setErrorSnackbarMessage] = useState("");

  const showError = (error: string) => {
    setErrorSnackbarMessage(error);
    setIsErrorSnackbarShown(true);
  };

  const handleErrorDismiss = () => {
    setIsErrorSnackbarShown(false);
  };

  const handleEmailSentDismiss = () => {
    setIsEmailSentShown(false);
  };

  const handleRecoverClicked = async () => {
    try {
      await requestPasswordReset(email, "link");

      setIsEmailSentShown(true);
      setEmail("");
    } catch (err) {
      console.error(err);
      if (err && Object.hasOwn(err, "error")) {
        const apiError = err as ApiError;
        switch (apiError.error) {
          case "account_not_found":
            showError(t("dialog_alert_noAccountWithEmailFound"));
            break;
        }
      } else {
        showError(`${err}`);
      }
    }
  };

  return (
    <>
      <div className="form-background">
        <div className="form">
          <div className="back-button" onClick={() => navigate.goBack()}>
            <img src={BackIcon} alt={t("button_back")} className="back-icon" />
            {t("button_back")}
          </div>
          <div className="form-forgotten-password-text">
            {t("prompt_accountRecovery")}
          </div>
          <TextField
            variant="outlined"
            label={t("label_email")}
            className="form-input"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            type="text"
            name="name"
            margin="normal"
          />

          <Button
            disabled={email.length <= 0}
            className="form-button"
            type="submit"
            variant="contained"
            size="medium"
            startIcon={<RestoreIcon />}
            onClick={handleRecoverClicked}
          >
            {t("button_recoverMyAccount")}
          </Button>
        </div>
      </div>
      <Snackbar
        open={isEmailSentShown}
        autoHideDuration={6000}
        message={t("dialog_passwordResetEmailSent")}
        onClose={handleEmailSentDismiss}
      >
        <Alert elevation={6} variant="filled" severity="success">
          {t("dialog_passwordResetEmailSent")}
        </Alert>
      </Snackbar>
      {isErrorSnackbarShown && (
        <Snackbar
          open={isErrorSnackbarShown}
          onClose={handleErrorDismiss}
          message={errorSnackbarMessage}
        >
          <Alert
            elevation={6}
            variant="filled"
            severity="error"
            onClose={handleErrorDismiss}
          >
            {errorSnackbarMessage}
          </Alert>
        </Snackbar>
      )}
    </>
  );
};

function LogoImage() {
  return <img src={logoImage} className="form-logo" alt="adc-logo"></img>;
}
