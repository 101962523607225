import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationEN from "./locales/en/translation.json";
import translationSL from "./locales/sl/translation.json";
import translationBS from "./locales/bs/translation.json";
import translationDE from "./locales/de/translation.json";
import translationRScyrl from "./locales/rs_Cyrl/translation.json";
import translationRSlatn from "./locales/rs-Latn/translation.json";
import translationHR from "./locales/hr/translation.json";
import translationIT from "./locales/it/translation.json";
import LanguageDetector from "i18next-browser-languagedetector";

const resources = {
  en: {
    translation: translationEN,
  },
  sl: {
    translation: translationSL,
  },
  bs: {
    translation: translationBS,
  },
  hr: {
    translation: translationHR,
  },
  de: {
    translation: translationDE,
  },
  rs_latn: {
    translation: translationRSlatn,
  },
  rs_cyrl: {
    translation: translationRScyrl,
  },
  it: {
    translation: translationIT,
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: ["en", "sl", "de", "hr", "rs_latn", "rs_cyrl", "it"],
    resources,
    // lng must not be set, or the language detector stops working
    fallbackLng: "en", // Fallback language
    interpolation: {
      escapeValue: false, // React already escapes values, so no need for this
    },
    detection: { lookupQuerystring: "lang" },
  });

export default i18n;
