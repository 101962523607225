import React, { useEffect, useState } from "react";
import { deviceInfo } from "../adcApi";
import Layout from "../components/Layout";
import KeyboardArrowLeftRoundedIcon from "@mui/icons-material/KeyboardArrowLeftRounded";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import WavesOutlinedIcon from "@mui/icons-material/WavesOutlined";
import ArrowUpwardOutlinedIcon from "@mui/icons-material/ArrowUpwardOutlined";
import ArrowDownwardOutlinedIcon from "@mui/icons-material/ArrowDownwardOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import TestRoleta from "../assets/roleta_test2.png";
import Video from "../assets/videos/clear_sky.mp4";
import BackIcon from "../assets/icon_back.svg";
import gearIcon from "../assets/icon_gear.svg";
import FavoriteIcon from "../assets/icon_heart.svg";
import VentilationIcon from "../assets/icon_ventilation.svg";
import OpenIcon from "../assets/icon_all_up.svg";
import CloseIcon from "../assets/icon_all_down.svg";
import { useLocation, useHistory } from "react-router-dom";
import { Button } from "@mui/material";
import LoadingIndicator from "../components/LoadingIndicator";
import { useTranslation } from "react-i18next";
import DeviceOfflineIndicator from "../components/DeviceOfflineIndicator";
import { ScreenCanvas } from "../components/devices/ScreenCanvas";
import { AwningCanvas } from "../components/devices/AwningCanvas";
import { VenetianBlindCanvas } from "../components/devices/VenetianBlindCanvas";
import { InsectScreenCanvas } from "../components/devices/InsectScreenCanvas";
import { RollerShutterCanvas } from "../components/devices/RollerShutterCanvas";
import {
  InclinationType,
  VenetianBlindSideViewCanvas,
} from "../components/devices/VenetianBlindSideViewCanvas";
import { FalconCanvas } from "../components/devices/FalconCanvas";
import { PergolaCanvas } from "../components/devices/PergolaCanvas";
import { DiamondProgressBarCanvas } from "../components/devices/DiamondProgressBarCanvas";
import { useSelector } from "react-redux";
import { RootState } from "../app/store";

type DeviceInfo = {
  name: string;
  token: string;
  devicetype: string;
  inclination: number;
  position: number;
  status: string;
  connected: boolean;
};

export const DeviceInfoPage = () => {
  return (
    <>
      <Layout>
        <DeviceInfo />
      </Layout>
    </>
  );
};

function DeviceInfo() {
  const navigate = useHistory();
  const { t } = useTranslation();
  const location = useLocation();
  const state = location.state as {
    name: string;
    deviceType: string;
    id: string;
  };
  const { id } = state;
  const deviceInfo = useSelector(
    (state: RootState) => state.device.devices[id],
  );
  const { name, devicetype, connected, status, position, inclination } =
    deviceInfo;
  const [percentage, setPercentage] = useState(0.3);
  const [isPercentageShown, setIsPercentageShown] = useState(false);
  const [currentCanvas, setCurrentCanvas] = useState("default"); // Initial canvas type

  // Function to handle the arrow button click
  const handleArrowButtonClick = () => {
    // Toggle between different canvases based on the current canvas state
    setCurrentCanvas((prevCanvas) =>
      prevCanvas === "default" ? "alternative" : "default",
    );
  };
  //const [deviceInfo, setDeviceInfo] = useState<DeviceInfo[]>();
  return (
    <div className="device-list-container">
      <div className="App">
        <header className="App-body">
          <div className="rectangles-container">
            <div className="device-header-grid">
              <div>
                <img
                  src={BackIcon}
                  alt="back"
                  className="back-icon"
                  onClick={() => navigate.goBack()}
                />
              </div>
              <div>{name}</div>
              <div
                className="settings-button"
                onClick={() =>
                  navigate.push("/devicesettingspage", {
                    name: name,
                    devicetype: devicetype,
                    token: id,
                  })
                }
              >
                <img alt="settings" src={gearIcon} className="back-icon"></img>
                <span>{t("button_settings")}</span>
              </div>
            </div>
            <div className="device-menu-grid">
              <div>
                <img
                  alt="favorite"
                  src={FavoriteIcon}
                  style={{ height: 35 }}
                ></img>
                <span>{t("button_favorite")}</span>
              </div>
              <div>
                <img
                  alt="ventilation"
                  src={VentilationIcon}
                  style={{ height: 35 }}
                ></img>
                <span>{t("button_ventilation")}</span>
              </div>
              <div>
                <img alt="open" src={OpenIcon} style={{ height: 35 }}></img>
                <span>{t("button_open")}</span>
              </div>
              <div>
                <img alt="close" src={CloseIcon} style={{ height: 35 }}></img>
                <span>{t("button_close")}</span>
              </div>
            </div>
          </div>

          <div className="device-content-canvas">
            <video
              className="background-video"
              src={Video}
              loop
              autoPlay
              style={{
                zIndex: 0,
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
            ></video>
            <div style={{ position: "relative", zIndex: 1, height: "100%" }}>
              {status !== "ready" && <LoadingIndicator />}
              {connected !== true && <DeviceOfflineIndicator />}

              <Canvas
                position={position}
                rotation={inclination}
                devicetype={devicetype}
                canvasType={currentCanvas}
                onDragStart={() => {
                  setIsPercentageShown(true);
                }}
                onDragFinish={() => setIsPercentageShown(false)}
                onStateChange={(args) => {
                  if (args.position !== undefined) {
                    setPercentage(args.position);
                  } else if (args.rotation !== undefined) {
                    setPercentage(args.rotation);
                  }
                }}
              />
              {devicetype === "venetian_blind" && (
                <div className="small-square" onClick={handleArrowButtonClick}>
                  {currentCanvas === "default" ? (
                    <ArrowDownwardOutlinedIcon />
                  ) : (
                    <ArrowUpwardOutlinedIcon />
                  )}
                </div>
              )}
              {isPercentageShown && (
                <DiamondProgressBarCanvas
                  percentage={percentage}
                  style={{
                    position: "absolute",
                    left: 0,
                    top: 0,
                    touchAction: "none",
                    pointerEvents: "none",
                  }}
                />
              )}
            </div>
          </div>
        </header>
      </div>
    </div>
  );
}

function Canvas({
  position,
  rotation,
  devicetype,
  canvasType,
  onDragStart,
  onDragFinish,
  onStateChange,
}: {
  position?: number;
  rotation?: number;
  devicetype: string;
  canvasType: string;
  onDragStart?: () => void;
  onDragFinish?: () => void;
  onStateChange?: (args: { position?: number; rotation?: number }) => void;
}) {
  let deviceCanvas: React.ReactElement | null = null;

  switch (devicetype.toLowerCase()) {
    case "roleta":
    case "roller_shutter":
      deviceCanvas = (
        <RollerShutterCanvas
          position={position}
          onDragStart={onDragStart}
          onDragFinish={onDragFinish}
          onStateChange={onStateChange}
        />
      );
      break;
    case "žaluzija":
    case "venetian_blind":
      deviceCanvas =
        canvasType === "default" ? (
          <VenetianBlindCanvas
            position={position}
            rotation={rotation}
            onDragStart={onDragStart}
            onDragFinish={onDragFinish}
            onStateChange={onStateChange}
          />
        ) : (
          <VenetianBlindSideViewCanvas
            rotation={rotation}
            inclinationtype={InclinationType.Full}
          />
        );
      break;
    case "komarnik":
    case "insect_screen":
      deviceCanvas = (
        <InsectScreenCanvas
          position={position}
          onDragStart={onDragStart}
          onDragFinish={onDragFinish}
          onStateChange={onStateChange}
        />
      );
      break;
    case "screen":
    case "textile_screen":
      deviceCanvas = (
        <ScreenCanvas
          position={position}
          onDragStart={onDragStart}
          onDragFinish={onDragFinish}
          onStateChange={onStateChange}
        />
      );
      break;
    case "tenda":
    case "awning":
      deviceCanvas = (
        <AwningCanvas
          position={position}
          onDragStart={onDragStart}
          onDragFinish={onDragFinish}
          onStateChange={onStateChange}
        />
      );
      break;
    case "falcon":
      deviceCanvas = (
        <FalconCanvas
          position={position}
          onDragStart={onDragStart}
          onDragFinish={onDragFinish}
          onStateChange={onStateChange}
        />
      );
      break;
    case "pergola":
      deviceCanvas = (
        <PergolaCanvas
          rotation={rotation}
          onDragStart={onDragStart}
          onDragFinish={onDragFinish}
          onStateChange={onStateChange}
        />
      );
      break;
  }

  return deviceCanvas;
}
