import React, { useCallback, useEffect, useRef, useState } from "react";

/**
 * @description Calculates the angle from the actual center of the circle to a point on the arc, from the angle between the fake center (the one we draw) and the point on the arc.
 * @param arcPointerDeg The angle between the line from the arc pointer (fake center) to the point on the arc and a horizontal line.
 * @param arcPointerOffset The offset of the fake center (the one we draw, referred to as the arc pointer) from the actual circle center.
 * @param arcRadius The radius of the circle from which the arc is cut out.
 */
function arcPointerAngleToArcAngle(
  arcPointerDeg: number,
  arcPointerXOffset: number,
  arcRadius: number,
) {
  const arcPointerRad = (arcPointerDeg / 180.0) * Math.PI;
  const cos = Math.cos(arcPointerRad);
  const sin = Math.sin(arcPointerRad);
  const a = arcPointerXOffset;
  const r = arcRadius;
  // Distance from the arc pointer to the point on the arc
  const k = -a * cos + Math.sqrt(r * r - Math.pow(a * sin, 2));
  const arcRad = Math.acos((cos * k + a) / r);

  // Result needs to be in degrees
  return (arcRad / Math.PI) * 180.0;
}

function valueToArcPoint(
  value: number,
  upMaxAngle: number,
  downMaxAngle: number,
  arcRadius: number,
  arcPointerXOffset: number,
) {
  // Do negative rotation for up and positive rotation for down (counterclockwise and clockwise respectively)
  const totalAngleSweep = upMaxAngle + downMaxAngle;
  const slateAngleDeg = value * totalAngleSweep - upMaxAngle;
  const a = arcPointerXOffset;
  const r = arcRadius;
  const angleInRad = (slateAngleDeg / 180.0) * Math.PI;
  const cos = Math.cos(angleInRad);
  const sin = Math.sin(angleInRad);
  // Equation: k = -a * cos(angle) + sqrt(r^2 - (a*sin(angle))^2)
  const k = -a * cos + Math.sqrt(r * r - Math.pow(a * sin, 2));

  return {
    x: a + k * Math.cos(angleInRad),
    y: k * Math.sin(angleInRad),
  };
}

function valueFromMousePosition(
  mouseY: number,
  canvasY: number,
  usableArcStartAngleDeg: number,
  usableArcSweepAngleDeg: number,
  sliderBoundingBox: { x: number; y: number; width: number; height: number },
  arcCenter: { x: number; y: number },
  arcRadius: number,
) {
  const positionInCanvas = mouseY - canvasY;
  const positionRelativeToArcCenter = positionInCanvas - arcCenter.y;
  const tightBoundingBoxHeight =
    Math.sin((-usableArcStartAngleDeg / 180.0) * Math.PI) * arcRadius * 2;
  const clampedRelativePosition = Math.min(
    Math.max(positionRelativeToArcCenter, -tightBoundingBoxHeight),
    tightBoundingBoxHeight,
  );
  const angleRad = Math.asin(
    Math.min(
      Math.max(
        (clampedRelativePosition / tightBoundingBoxHeight) * 2.0,
        Math.sin((usableArcStartAngleDeg / 180.0) * Math.PI),
      ),
      Math.sin(
        ((usableArcStartAngleDeg + usableArcSweepAngleDeg) / 180.0) * Math.PI,
      ),
    ),
  );
  const angleDeg = (angleRad / Math.PI) * 180.0;
  //console.log(
  //  `mouseY: ${mouseY}, canvasY: ${canvasY}, positionInCanvas: ${positionInCanvas}, positionRelativeToArcCenter: ${positionRelativeToArcCenter}, arcCenter: ${arcCenter.y}, clampedRelativePosition: ${clampedRelativePosition}, angleRad: ${angleRad}, angleDeg: ${angleDeg}, sliderBoundingBox: {y: ${sliderBoundingBox.y}, height: ${sliderBoundingBox.height}}, tightBoundingBoxHeight: ${tightBoundingBoxHeight}`,
  //);

  return (-usableArcStartAngleDeg + angleDeg) / usableArcSweepAngleDeg;
}

type CanvasProps = React.DetailedHTMLProps<
  React.CanvasHTMLAttributes<HTMLCanvasElement>,
  HTMLCanvasElement
>;

export const AngleSlider: React.FC<
  {
    value?: number;
    maxUpAngle: number;
    maxDownAngle: number;
    onValueChange?: (value: number) => void;
    onValueSelected?: (value: number) => void;
    onDragStart?: () => void;
    onDragFinish?: () => void;
  } & CanvasProps
> = ({ ...props }) => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const [value, setValue] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [isInside, setIsInside] = useState(false);
  const [preDragValue, setPreDragValue] = useState(0.0);
  const [preLeaveValue, setPreLeaveValue] = useState(0.0);
  const [canvasArea, setCanvasArea] = useState({
    width: 1,
    height: 1,
    scale: 1,
  });
  const { onValueChange, onValueSelected, onDragStart, onDragFinish } = props;

  const onResize = useCallback(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const parent = canvas.parentElement;
    if (!parent) return;

    const scale = window.devicePixelRatio;
    canvas.width = Math.floor(parent.clientWidth * scale);
    canvas.height = Math.floor(parent.clientHeight * scale);
    setCanvasArea({ width: canvas.width, height: canvas.height, scale });
  }, [canvasRef]);

  useEffect(() => {
    window.addEventListener("resize", onResize);
    onResize();
    return () => window.removeEventListener("resize", onResize);
  }, [onResize]);

  useEffect(() => {
    if (props.value !== undefined) {
      setValue(props.value);
    }
  }, [props.value]);

  const handleMouseDown = useCallback(
    (e: { clientY: number }) => {
      if (!isDragging) {
        setIsDragging(true);
        setIsInside(true);
        setPreDragValue(value);
        const newValue = valueFromMousePosition(
          e.clientY,
          canvasRef.current!.getBoundingClientRect().y,
          -80,
          160,
          canvasRef.current!.getBoundingClientRect(),
          {
            x: canvasRef.current!.width / 2 / window.devicePixelRatio,
            y: canvasRef.current!.height / 2 / window.devicePixelRatio,
          },
          Math.max(
            Math.min(
              (canvasRef.current!.width - 50) / 3,
              (canvasRef.current!.height - 50) / 2,
            ),
            1,
          ) / window.devicePixelRatio,
        );
        setValue(newValue);
        if (onValueChange) onValueChange(newValue);
        if (onDragStart) onDragStart();
      }
    },
    [isDragging, value, onValueChange, onDragStart],
  );
  const handleMouseUp = useCallback(
    (_event: any) => {
      if (isDragging) {
        setIsDragging(false);
        if (isInside) {
          if (onValueChange) onValueChange(value);
          if (onValueSelected) onValueSelected(value);
          if (onDragFinish) onDragFinish();
        }
      }
    },
    [isDragging, isInside, value, onValueChange, onValueSelected, onDragFinish],
  );
  const handleMouseMove = useCallback(
    (e: { clientY: number }) => {
      if (isDragging && isInside) {
        const newValue = valueFromMousePosition(
          e.clientY,
          canvasRef.current!.getBoundingClientRect().y,
          -80,
          160,
          canvasRef.current!.getBoundingClientRect(),
          {
            x: canvasRef.current!.width / 2 / window.devicePixelRatio,
            y: canvasRef.current!.height / 2 / window.devicePixelRatio,
          },
          Math.max(
            Math.min(
              (canvasRef.current!.width - 50) / 3,
              (canvasRef.current!.height - 50) / 2,
            ),
            1,
          ) / window.devicePixelRatio,
        );
        setValue(newValue);
        if (onValueChange) onValueChange(newValue);
        setPreLeaveValue(value);
      }
    },
    [isDragging, isInside, value, onValueChange],
  );
  const handleMouseLeave = useCallback(
    (_: any) => {
      if (isDragging) {
        setIsInside(false);
        setValue(preDragValue);
        if (onValueChange) onValueChange(preDragValue);
        if (onDragFinish) onDragFinish();
      }
    },
    [isDragging, preDragValue, onValueChange, onDragFinish],
  );
  const handleMouseEnter = useCallback(
    (_: any) => {
      if (isDragging) {
        setIsInside(true);
        setValue(preLeaveValue);
        if (onValueChange) onValueChange(preLeaveValue);
        if (onDragStart) onDragStart();
      }
    },
    [isDragging, preLeaveValue, onValueChange, onDragStart],
  );
  const handleTouchStart = useCallback(
    (e: TouchEvent) => {
      handleMouseDown(e.touches[0]);
      e.stopPropagation();
    },
    [handleMouseDown],
  );
  const handleTouchEnd = useCallback(
    (e: TouchEvent) => {
      handleMouseUp(e);
      if (isInside) {
        e.stopPropagation();
      }
    },
    [handleMouseUp, isInside],
  );
  const handleTouchMove = useCallback(
    (e: TouchEvent) => {
      handleMouseMove(e.touches[0]);
      e.stopPropagation();
    },
    [handleMouseMove],
  );

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) {
      return;
    }

    window.addEventListener("mouseup", handleMouseUp);
    canvas.addEventListener("mousedown", handleMouseDown);
    canvas.addEventListener("mousemove", handleMouseMove);
    canvas.addEventListener("mouseleave", handleMouseLeave);
    canvas.addEventListener("mouseenter", handleMouseEnter);
    window.addEventListener("touchend", handleTouchEnd);
    canvas.addEventListener("touchstart", handleTouchStart);
    canvas.addEventListener("touchmove", handleTouchMove);

    return () => {
      window.removeEventListener("mouseup", handleMouseUp);
      canvas.removeEventListener("mousedown", handleMouseDown);
      canvas.removeEventListener("mousemove", handleMouseMove);
      canvas.removeEventListener("mouseleave", handleMouseLeave);
      canvas.removeEventListener("mouseenter", handleMouseEnter);
      window.removeEventListener("touchend", handleTouchEnd);
      canvas.removeEventListener("touchstart", handleTouchStart);
      canvas.removeEventListener("touchmove", handleTouchMove);
    };
  }, [
    handleMouseUp,
    handleMouseDown,
    handleMouseMove,
    handleMouseLeave,
    handleMouseEnter,
    handleTouchEnd,
    handleTouchStart,
    handleTouchMove,
  ]);

  const render = (canvas: HTMLCanvasElement | null) => {
    if (!canvas) return;

    const ctx = canvas.getContext("2d");
    if (!ctx) return;

    ctx.save();
    const arcRadius = Math.max(
      Math.min((canvas.width - 50) / 3, (canvas.height - 50) / 2),
      1,
    );

    const drawThumb = (ctx: CanvasRenderingContext2D) => {
      const sliceAngle = (2 / 5) * Math.PI;
      const slices = (2 * Math.PI) / sliceAngle;
      const radius = 21.5;

      ctx.save();
      ctx.rotate(-Math.PI / 2);
      ctx.beginPath();
      ctx.moveTo(radius, 0);
      for (let i = 0; i < slices; ++i) {
        ctx.rotate(sliceAngle);
        ctx.lineTo(radius, 0);
      }
      ctx.fillStyle = "rgba(123, 187, 205, 255)";
      ctx.strokeStyle = "rgba(0,0,0,1)";
      ctx.lineJoin = "bevel";
      // Outline before fill, so that the outline is smaller
      ctx.stroke();
      ctx.fill();
      ctx.closePath();
      ctx.restore();
    };

    const drawSlider = (ctx: CanvasRenderingContext2D) => {
      const arcRadius = Math.max(
        Math.min((canvas.width - 50) / 3, (canvas.height - 50) / 2),
        1,
      );
      const arcAngleDeg = 80.0;
      const arcAngleRad = (arcAngleDeg / 180.0) * Math.PI;
      const arcCenter = {
        x: 0,
        y: 0,
      };
      const arcOffset = {
        x: arcRadius * Math.cos(arcAngleRad),
        y: 0,
      };
      const arcPointer = {
        x: arcCenter.x + arcOffset.x,
        y: arcCenter.y + arcOffset.y,
      };

      const arcMaxHeight = arcRadius * Math.sin(arcAngleRad);

      ctx.save();

      // Draw the arc
      ctx.beginPath();
      ctx.lineWidth = 8;
      ctx.strokeStyle = "white";
      ctx.lineCap = "round";
      ctx.arc(
        arcCenter.x,
        arcCenter.y,
        arcRadius,
        -arcAngleRad,
        arcAngleRad,
        false,
      );
      ctx.stroke();
      ctx.closePath();

      // Draw the line from arc pointer to arc
      ctx.beginPath();
      ctx.lineWidth = 4;
      ctx.moveTo(arcPointer.x, arcPointer.y);
      let maxUpAngle = props.maxUpAngle;
      let maxDownAngle = props.maxDownAngle;
      const pointOnArc = valueToArcPoint(
        value,
        maxUpAngle,
        maxDownAngle,
        arcRadius,
        arcOffset.x,
      );
      ctx.lineTo(arcCenter.x + pointOnArc.x, arcCenter.y + pointOnArc.y);
      ctx.stroke();
      ctx.closePath();

      // Draw the arc center dot
      ctx.beginPath();
      ctx.fillStyle = "rgba(45, 45, 45, 255)";
      ctx.arc(arcPointer.x, arcPointer.y, 8, 0, 2 * Math.PI);
      ctx.fill();
      ctx.closePath();

      ctx.save();
      ctx.translate(pointOnArc.x, pointOnArc.y);
      drawThumb(ctx);
      ctx.restore();
      ctx.font = "3em Nunito";
      const percentageText = `${Math.round(value * 100)}%`;
      const measured = ctx.measureText(percentageText);
      ctx.translate(
        -measured.width * (1 / 2 + 1 / 4),
        (measured.actualBoundingBoxAscent + measured.actualBoundingBoxDescent) /
          2,
      );
      ctx.fillStyle = "white";
      ctx.fillText(percentageText, 0, 0);

      ctx.restore();
    };

    ctx.clearRect(0, 0, canvas.width, canvas.height);

    ctx.translate(canvas.width / 2, canvas.height / 2);
    drawSlider(ctx);
    ctx.restore();
  };

  useEffect(() => {
    render(canvasRef.current);
  }, []);
  render(canvasRef.current);

  return (
    <canvas
      style={{
        width: "100%",
        height: "100%",
        touchAction: "none",
        ...props.style,
      }}
      ref={canvasRef}
    />
  );
};
