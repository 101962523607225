type Resolver = (val: void) => void;

export class Semaphore {
  promiseQueue: Array<Resolver> = [];
  maxCount = 0;
  count = 0;

  constructor(maxCount: number) {
    this.maxCount = maxCount;
  }

  async wait(): Promise<void> {
    if (this.count < this.maxCount) {
      ++this.count;
      return Promise.resolve();
    }

    let waitPromise = new Promise<void>((resolve, reject) => {
      this.promiseQueue.push(resolve);
    });
    return waitPromise;
  }

  release() {
    setTimeout(() => {
      --this.count;
      let nextResolver = this.promiseQueue.shift();
      if (nextResolver) {
        ++this.count;
        nextResolver();
      }
    }, 0);
  }
}
