import { Paper, PaperProps } from "@mui/material";
import LightOn from "../assets/icon_light_on.svg";
import LightOff from "../assets/icon_light_off.svg";
import { MouseEvent as ReactMouseEvent } from "react";
import { Device } from "../app/DeviceSlice";
import { Loading } from "./Loading";

type OnClickedCallback = (
  event: ReactMouseEvent<HTMLDivElement, MouseEvent>,
) => void;

export interface LightCardProps extends PaperProps {
  device: Device;
  name?: string;
  brightness: number;
  onLightToggleRequest?: OnClickedCallback;
  onCardClicked?: OnClickedCallback;
}

export const LightCard: React.FC<LightCardProps> = (args) => {
  const {
    device,
    name,
    brightness,
    onLightToggleRequest,
    onCardClicked,
    className,
    ...props
  } = args;

  const finalClassName = [className, "card", "light-card"].join(" ");

  return (
    <Paper
      {...props}
      variant="outlined"
      square={false}
      className={finalClassName}
      onClick={onCardClicked}
    >
      <div className="card-content">
        {device.status !== "ready" && device.status !== "offline" && (
          <Loading className="card-busy" />
        )}

        <div className="card-icon">
          <img
            src={LightOff}
            className="device-icon"
            style={{ width: 55 }}
            alt="Light icon"
          />
          {device.connected !== true && <p className="offline-icon">!</p>}
        </div>

        <div className="card-name">{name}</div>

        <div className="card-control">
          <div
            className="image-action-button"
            style={{
              border: `1px solid ${brightness === 1 ? "#7bbbcd" : "#ccc"}`,
            }}
            onClick={(event) => {
              event.stopPropagation();
              if (onLightToggleRequest) {
                onLightToggleRequest(event);
              }
            }}
          >
            <img
              src={brightness === 0 ? LightOff : LightOn}
              alt={brightness === 0 ? "Light off icon" : "Light on icon"}
            />
          </div>
        </div>
      </div>
    </Paper>
  );
};
