export enum LoginApiErrorId {
  DATA_MISSING = "data_missing",
  MISSING_LOGIN = "missing_login",
  MISSING_PASSWORD = "missing_password",
  INVALID_CREDENTIALS = "invalid_credentials",
  API_TOKEN_MISSING = "api_token_missing",
  API_TOKEN_INVALID = "api_token_invalid",
  CONFIRMATION_CODE_EXPIRED = "confirmation_code_expired",
  CONFIRMATION_INVALID = "confirmation_invalid",
  ACCOUNT_NOT_FOUND = "account_not_found",
  ACCOUNT_ALREADY_CONFIRMED = "account_already_confirmed",
  MALFORMED_REQUEST = "malformed_request",
  LOGIN_REQUIRED = "login_required",
  ACCOUNT_CONFIRMATION_REQUIRED = "account_confirmation_required",
  OTHER_ACCOUNT_CONFIRMATION_REQUIRED = "other_account_confirmation_required",
  EMAIL_ALREADY_IN_USE = "email_already_in_use",
  PASSWORD_NOT_STRONG_ENOUGH = "password_not_strong_enough",
  PASSWORD_ALREADY_SET = "password_already_set",
}
export enum OtherApiErrorId {
  INTERNAL_ERROR = "internal_error",
  DEVICE_UNREACHABLE = "device_unreachable",
  DEVICE_BUSY = "device_busy",
  DEVICE_NOT_BUSY = "device_not_busy",
  DEVICE_ALREADY_IN_STATE = "device_already_in_state",
  DEVICE_NOT_RESPONDING = "device_not_responding",
  BAD_COMMAND_KEY = "bad_command_key",
  BAD_COMMAND_TYPE = "bad_command_type",
  BAD_COMMAND_VALUE = "bad_command_value",
  MULTIPLE_COMMAND_VALUES = "multiple_command_values",
  NO_COMMANDS_FOUND = "no_commands_found",
  INVALID_COMMAND = "invalid_command",
  MALFORMED_REQUEST = "malformed_request",
  INSUFFICIENT_PERMISSION = "insufficient_permission",
  DEVICE_NAME_IN_USE = "device_name_in_use",
  DEVICE_UNSUPPORTED_ACTION = "device_unsupported_action",
  MALFORMED_TRIGGER = "malformed_trigger",
  MALFORMED_ACTION = "malformed_action",
  MALFORMED_SCENARIO = "malformed_scenario",
  RESOURCE_NOT_FOUND = "resource_not_found",
  DEVICE_TOO_MANY_SCENARIOS = "device_too_many_scenarios",
  DEVICE_UPDATE_FAILED = "device_update_failed",
  OPEN_WEATHER_SUBSCRIPTION_NEEDED = "open_weather_subscription_needed",
  WEATHER_SCENARIO_MATCHED = "weather_scenario_matched",
  SIMILAR_WEATHER_SCENARIO_ALREADY_EXISTS = "similar_weather_scenario_already_exists",
  SUBSCRIPTION_ALREADY_EXISTS = "subscription_already_exists",
  ROLE_ALREADY_EXISTS = "role_already_exists",
  ROLE_USERS_LIMIT_EXCEEDED = "role_users_limit_exceeded",
  HOME_ALREADY_EXISTS = "home_already_exists",
  PUBLIC_KEY_ALREADY_EXISTS = "public_key_already_exists",
  DEVICE_LINKS_LIMIT_EXCEEDED = "device_links_limit_exceeded",
  COMMAND_PARAMS_INVALID = "command_params_invalid",
  DEVICE_NOT_EXIST = "device_not_exist",
  DEVICE_NOT_READY = "device_not_ready",
  DEVICE_OFFLINE = "device_offline",
  DEVICE_REQUEST_TIMEOUT = "device_request_timeout",
  DEVICE_SCENARIOS_LIMIT_EXCEEDED = "device_scenarios_limit_exceeded",
  PUBLIC_KEY_INVALID = "public_key_invalid",
}
export type ApiErrorId = OtherApiErrorId | LoginApiErrorId | string;

export interface ApiError {
  error: ApiErrorId;
  error_description: string;
  error_detail?: string;
  key?: string;
  device_id?: string;
}

export function isApiError(error: unknown): error is ApiError {
  if (typeof error !== "object") {
    return false;
  }

  const assumedError = error as ApiError;

  return (
    typeof assumedError.error === "string" &&
    typeof assumedError.error_description === "string" &&
    (typeof assumedError.error_detail === "undefined" ||
      typeof assumedError.error_detail === "string") &&
    (typeof assumedError.key === "undefined" ||
      typeof assumedError.key === "string") &&
    (typeof assumedError.device_id === "undefined" ||
      typeof assumedError.device_id === "string")
  );
}
