import React from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

interface ConfirmDialogProps {
  open: boolean;
  title: string;
  content: React.ReactNode;
  onConfirm: () => void;
  onCancel: () => void;
  confirmButtonText?: string;
  cancelButtonText?: string;
}

const ConfirmDialog: React.FC<ConfirmDialogProps> = (
  props: ConfirmDialogProps,
) => {
  const { t } = useTranslation();

  return (
    <Dialog open={props.open} onClose={props.onCancel}>
      <DialogTitle style={{ textTransform: "uppercase" }}>
        {props.title}
      </DialogTitle>
      <DialogContent>{props.content}</DialogContent>
      <DialogActions>
        <Button onClick={props.onCancel} color="primary" variant="outlined">
          {props.cancelButtonText || t("button_cancel")}
        </Button>
        <Button onClick={props.onConfirm} color="primary" variant="outlined">
          {props.confirmButtonText || t("button_confirm")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ConfirmDialog.defaultProps = {
  okButtonText: undefined,
} as Partial<ConfirmDialogProps>;

export default ConfirmDialog;
