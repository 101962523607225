import React, { useState } from "react";
import Layout from "../components/Layout";
import SettingsItem from "../components/SettingsItemComponent";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Switch,
  TextField,
  Collapse,
} from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import BackIcon from "../assets/icon_back.svg";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface DialogProps {
  open: boolean;
  onClose: () => void;
}

export const DoorSettingsPage = () => {
  return (
    <Layout>
      <DeviceSettings />
    </Layout>
  );
};

function DeviceSettings() {
  const navigate = useHistory();
  const { t } = useTranslation();

  const [isRestartDialogOpen, setIsRestartDialogOpen] = useState(false);
  const [isDeleteDeviceDialogOpen, setIsDeleteDevicetDialogOpen] =
    useState(false);
  const [isRenameDeviceDialogOpen, setIsRenameDeviceDialogOpen] =
    useState(false);

  const openRenameDeviceDialog = () => {
    setIsRenameDeviceDialogOpen(true);
  };
  const closeRenameDeviceDialog = () => {
    setIsRenameDeviceDialogOpen(false);
  };

  const openRestartDialog = () => {
    setIsRestartDialogOpen(true);
  };
  const closeRestartDialog = () => {
    setIsRestartDialogOpen(false);
  };

  const openDeleteDeviceDialog = () => {
    setIsDeleteDevicetDialogOpen(true);
  };
  const closeDeleteDeviceDialog = () => {
    setIsDeleteDevicetDialogOpen(false);
  };

  return (
    <div className="device-list-container">
      <div className="App">
        <header className="App-body">
          <div className="rectangles-container">
            <div className="device-header-grid">
              <div>
                <img
                  src={BackIcon}
                  className="back-icon"
                  onClick={() => navigate.goBack()}
                />
              </div>
              <div>{t("button_settings")}</div>
              <div></div>
            </div>
          </div>

          <div className="scrollable-menu-content">
            <ul className="menu-container">
              <List style={{ width: "80%" }}>
                <ListSubheader
                  color="primary"
                  style={{
                    textAlign: "left",
                    backgroundColor: "transparent",
                    textTransform: "uppercase",
                  }}
                >
                  DOOR
                </ListSubheader>
                <SettingsItem
                  primaryText={t("button_rename")}
                  icon={<ChevronRightIcon />}
                  onClick={openRenameDeviceDialog}
                />

                <Divider />

                <SettingsItem
                  primaryText={t("button_magnetLockUnlockType")}
                  icon={<ChevronRightIcon />}
                  //onClick={openRenameDeviceDialog}
                />

                <Divider />

                <SettingsItem
                  primaryText={t("button_unlockDuration")}
                  icon={<ChevronRightIcon />}
                  //onClick={openRenameDeviceDialog}
                />

                <ListSubheader
                  color="primary"
                  style={{
                    textAlign: "left",
                    backgroundColor: "transparent",
                    textTransform: "uppercase",
                  }}
                >
                  {t("sectionTitle_controller")}
                </ListSubheader>

                <SettingsItem
                  primaryText={t("button_restartDevice")}
                  icon={<ChevronRightIcon />}
                  onClick={openRestartDialog}
                />

                <Divider />
                <SettingsItem
                  primaryText={t("button_deleteDevice")}
                  icon={<ChevronRightIcon />}
                  onClick={openDeleteDeviceDialog}
                />
              </List>
            </ul>
          </div>
        </header>
      </div>

      {isRenameDeviceDialogOpen && (
        <RenameDeviceDialog
          open={isRenameDeviceDialogOpen}
          onClose={closeRenameDeviceDialog}
        />
      )}

      {isRestartDialogOpen && (
        <RestartAlertDialog
          open={isRestartDialogOpen}
          onClose={closeRestartDialog}
        />
      )}

      {isDeleteDeviceDialogOpen && (
        <DeleteDeviceAlertDialog
          open={isDeleteDeviceDialogOpen}
          onClose={closeDeleteDeviceDialog}
        />
      )}
    </div>
  );
}

function RenameDeviceDialog({ open, onClose }: DialogProps) {
  const { t } = useTranslation();
  const [deviceName, setDeviceName] = useState("");
  const [isValidInput, setIsValidInput] = useState(true);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    const isValid = inputValue.length <= 20;
    setDeviceName(inputValue);
    setIsValidInput(isValid);
  };
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle style={{ textTransform: "uppercase" }}>
        {t("title_dialog_rename")}
      </DialogTitle>
      <DialogContent>
        <p>{t("dialog_prompt_chooseDeviceName")}</p>
      </DialogContent>
      <TextField
        autoFocus
        label={t("title_newDeviceName")}
        margin="dense"
        color={isValidInput ? "primary" : "error"}
        value={deviceName}
        onChange={handleInputChange}
        style={{ marginRight: 10, marginLeft: 10 }}
      />
      {!isValidInput && <p className="invalid-input">Invalid input</p>}
      <DialogActions>
        <Button onClick={onClose} color="primary" variant="outlined">
          {t("button_cancel")}
        </Button>
        <Button onClick={onClose} color="primary" variant="outlined">
          {t("button_confirm")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function RestartAlertDialog({ open, onClose }: DialogProps) {
  const { t } = useTranslation();
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle style={{ textTransform: "uppercase" }}>
        {t("title_dialog_confirm")}
      </DialogTitle>
      <DialogContent>
        <p>{t("dialog_confirm_restart")}</p>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" variant="outlined">
          {t("button_no")}
        </Button>
        <Button onClick={onClose} color="primary" variant="outlined">
          {t("button_yes")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function DeleteDeviceAlertDialog({ open, onClose }: DialogProps) {
  const { t } = useTranslation();
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle style={{ textTransform: "uppercase" }}>
        {t("title_dialog_confirm")}
      </DialogTitle>
      <DialogContent>
        <p>{t("dialog_confirm_deleteDevice")}</p>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" variant="outlined">
          {t("button_no")}
        </Button>
        <Button onClick={onClose} color="primary" variant="outlined">
          {t("button_yes")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
